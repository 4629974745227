export const qucikLoanPEV1 = [
  "monthlyPropertyGrossRent",
  "annualHOAFee",
  "annualPropertyTaxes",
  "annualInsuranceTotal",
];

export const detailedLoanPEV1 = [
  "asIsValue",
  "thirdPartyValuationStatus",
  "thirdPartyValuation",
  "propertyAcquisitionPrice",
  "annualPropertyTaxes",
  "annualHazardInsurance",
  "annualFloodInsurance",
  "annualHOAFee",
  "DecliningMarkets"
];
