import { Button } from '@mui/material';
import React from 'react';

const ReRequestWaiverConfirmation = (props: any) => {
    const { onDeleteAndCreate, onClose } = props;
    return (
        <div className="modal-overlay" style={{ padding: "1rem 1.5rem" }}>
            <div className="modal">
                <h2 className="modal-title">Active Price Exception Exists</h2>
                <p className="modal-message">
                    You already have an active price exception. Would you like to delete the existing request and create a new one?
                </p>
                <div className="modal-actions" style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                        className="btn-primary"
                        color="primary"
                        variant="contained"
                        onClick={onDeleteAndCreate}>
                        Delete And Create New
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={onClose}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ReRequestWaiverConfirmation;
