export const LIV1 = [
  "OriginatorLoanID",
  "LoanStructure",
  "LoanPurpose",
  "CashOutFlag",
  "ExitStrategy",
  "pledgeOfEquity",
];

export const quickLoanSizingLIV1= [
  "BorrowerRequested",
  "LoanPurpose",
  "purchasePrice",
];

export const detailedLoanSizingLIV1= [
  "OriginatorLoanID",
  "ExpectedDateOfClosing",
  "BorrowerProceeds",
  "NoOfProperties",
  "CondoEligiBilityV2",
  "LoanTerm",
  "CreditEvent",
  "CrossCollaterlization"
]