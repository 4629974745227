export const qucikLoanPLV1 = [];

export const detailedLoanUIV1 = [
  "currentLeaseTermsInMonths",
  "rentalCharacterization",
  "monthlyMarketRent",
  "currentUsage",
  "tenantOccupancy",
  "operatingHistory",
  "inPlaceLeaseRentMonthly",
  "percentUnitOccupied"
];
