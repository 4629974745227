import Slider, {
  SliderThumb,
  SliderValueLabelProps,
} from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { SizingTypeValue } from "../../../types/enums";

const CustomSlider = styled(Slider)({
  color: "#DBE2FF",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#40B4B4",
    border: "2px solid #40B4B4",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 80,
    height: 32,
    borderRadius: "5px",
    backgroundColor: "#40B4B4",
    transformOrigin: "bottom left",
    "&::before": { display: "none" },
  },
  "& .MuiSlider-mark": {
    height: 6, // Adjust the height of the data points
    width: 6, // Adjust the width of the data points
    borderRadius: "50%", // Make it circular
    backgroundColor: "#40B4B4", // Customize color
  },
  // "& .MuiSlider-markLabel": {
  //   fontSize: "12px", // Adjust font size for labels
  //   color: "#3C3C3C", // Customize label color
  // },
});

export function SliderComponent(props: any) {
  const {
    data,
    field,
    value,
    onChange,
    onBlur,
    loanDetails,
    isApplicationSubmitted,
    sectionName,
    errorMsg,
    currentObjFieldError,
    propertyId,
    unitId,
    dynamicConfig,
    mapping,
    currentObjValue
  } = props;

  const valueLabelFormat = (value: number) => {
    return currentObjValue?.prepayPenaltyType || mapping[value]; 
  };

  return (
    <CustomSlider
      track={false}
      aria-labelledby="non-linear-slider"
      // getAriaValueText={getAriaValueText}
      disabled={
        dynamicConfig?.allowEditAfterSubmit &&
        !(
          field?.isAutoCalculated &&
          field?.isAutoCalculated({
            sizingType: loanDetails?.sizingType,
          })
        )
          ? false
          : isApplicationSubmitted ||
            field?.isAutoCalculated({ sizingType: loanDetails?.sizingType })
      }
      marks={data}
      defaultValue={0}
      max={60}
      step={null}
      valueLabelFormat={valueLabelFormat}
      value={value}
      valueLabelDisplay={loanDetails?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING ? "auto" : "off"}
      sx={{ width: "100%", margin: "auto auto" }}
      onChange={(value: any, newValue: any) =>
        onChange(newValue, "slider", field, sectionName, propertyId, unitId)
      }
    />
  );
}
