import { useState } from "react";
import { t } from "i18next";

import { Accordion, AccordionDetails, Grid, IconButton } from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import { styled } from "@mui/material/styles";

import AccordionDownArrow from "../../../../../assets/AccordionDownArrow.svg";

import UnitInformation from "./UnitInformation";

const UnitAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<img src={AccordionDownArrow} style={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  background: "rgba(234, 222, 248, 0.50)",
  borderRadius: "6px",
  border: "none",
  // border: "1px solid #DBE2FF",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function UnitInformationWrapper(props: any) {
  const [isUnitExpanded, setIsUnitExpanded] = useState<any>({});
  const {
    unitId,
    propertyId,
    config,
    isApplicationSubmitted,
    handleAddUnit,
    handleDeleteUnit,
    unitsLength,
    detailedLoanSizingData
  } = props;

  const propLabel = `Property ${propertyId}`;
  const unitLabel = `Unit ${unitId}`;
  return (
    <div>
      <Accordion
        key={unitId}
        expanded={true} // Controlled state
        sx={{ boxShadow: "none" }}
        onChange={() =>
          setIsUnitExpanded({
            ...isUnitExpanded,
            [propLabel]: {
              ...isUnitExpanded[propLabel],
              [unitLabel]: !isUnitExpanded[propLabel]?.[unitLabel],
            },
          })
        }
      >
        <UnitAccordionSummary>
          <div>
            <h4>{`${t("unit")} ${unitId + 1}`}</h4>
          </div>
          <div>
            {config?.buttons ? (
              <div>
                {config?.buttons.map((button: any, idx: number) => {
                  return (
                    <IconButton
                      key={idx}
                      disabled={isApplicationSubmitted || ( button.action === "delete" && unitsLength <= 1)}
                      onClick={() =>
                        button.action === "add"
                          ? handleAddUnit(propertyId)
                          : handleDeleteUnit(propertyId, unitId)
                      }
                      sx={{
                        borderRadius: "0% !important;",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>
                        {t(button.label)}
                      </span>
                      &ensp;
                      <img src={button.icon} alt="button-icon" />
                      &ensp;
                      {idx === 0 ? "| " : null}
                    </IconButton>
                  );
                })}
              </div>
            ) : null}
          </div>
        </UnitAccordionSummary>
        <AccordionDetails>
          <Grid container sx={{ padding: "16px" }}>
            <UnitInformation {...props} />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
