import { useCallback, useContext, useEffect, useState } from "react";

import { debounce } from "@mui/material";

import { RootContext } from "../context/RootContext";
import { LoansContext } from "../context/LoansContext";

import { getAutocompleteAddress, getZipCodeList } from "../services/network";

import { ZipCodeSuggestion } from "../types/fieldTypes";
import { ObjectType } from "../types/tableTypes";

interface Field {
  id: string;
  getFieldErrors?: (value: any) => string;
  [key: string]: any;
}

interface Unit {
  [key: string]: any;
}

interface Property {
  propertyLocation?: any;
  propertyInformation?: any;
  propertyEconomics?: any;
  unitInformation?: Unit[];
  [key: string]: any;
}

interface DetailedLoanSizingData {
  propertyDetails?: Property[];
  [key: string]: any;
}

// Constants
const PROPERTY_LEVEL_SECTIONS = [
  "propertyLocation",
  "propertyInformation",
  "propertyEconomics",
  "unitInformation",
] as const;

const PROPERTY_LOCATION_FIELDS = ["postalCode", "address"] as const;

const SPECIAL_FIELD_UPDATES = {
  interestOnlyFlag: (value: string) => ({
    ioPeriod: value === "Yes" ? "120" : null,
  }),
  prepaymentPenaltyTypeMonths: () => ({
    prepayPenaltyType: null,
  }),
  borrowerType: () => ({
    firstTimeHomeBuyer: null,
  }),
  numberOfProperties: (value: any) => ({
    crossCollaterlization: value > 1 ? "Y" : "N",
  })
};

export const useLoanSizingHandler = () => {
  const { dynamicConfig } = useContext(RootContext);
  const {
    detailedLoanSizingData,
    setDetailedLoanSizingData,
    setNewDebouncedValue,
    detailedLoanSizingErrors,
    setDetailedLoanSizingErrors,
    addressOptions,
    setAddressOptions,
    setZipCodeOptions,
  } = useContext(LoansContext);

  const [zipCodeInputValue, setZipCodeInputValue] = useState<string>("");
  const [addressInputValue, setAddressInputValue] = useState<string>("");

  const fetchAddress = useCallback(
    debounce(async (request: { input: string }) => {
      try {
        const { data } = await getAutocompleteAddress(
          dynamicConfig?.ViteAppSmartyStreetsApiKey,
          request.input
        );
        setAddressOptions([...addressOptions, ...data.suggestions]);
      } catch (err) {
        console.error(err);
      }
    }, 300),
    [
      dynamicConfig?.ViteAppSmartyStreetsApiKey,
      addressOptions,
      setAddressOptions,
    ]
  );

  const fetchZipCodeSuggestions = useCallback(
    debounce(async (request: { input: string }) => {
      try {
        const { data } = await getZipCodeList(
          dynamicConfig?.ViteAppSmartyStreetsApiKey,
          request.input
        );
        const { city_states, zipcodes } = data[0];
        let newOptions = [] as ZipCodeSuggestion[];
        if (city_states && zipcodes) {
          const zipCode = zipcodes[0].zipcode;
          const temp = city_states.map((ele: ObjectType) => ({
            ...ele,
            zipCode,
          }));
          newOptions = [...newOptions, ...temp];
        }
        setZipCodeOptions(newOptions);
      } catch (err) {
        console.error(err);
      }
    }, 200),
    [dynamicConfig?.ViteAppSmartyStreetsApiKey, setZipCodeOptions]
  );

  const updatePostalOrAddress = useCallback(
    (
      value: any,
      propertyId: number,
      section: string,
      id: string,
      isPostalCode?: boolean
    ) => {
      let tempPropertyDetails = [
        ...(detailedLoanSizingData?.propertyDetails || []),
      ];
      const { street_line, city, state, zipcode } = value || {};
      setAddressOptions(value ? [value, ...addressOptions] : addressOptions);

      let objToReturn: any = {};
      if (isPostalCode) {
        setZipCodeInputValue(value);
        objToReturn = {
          [id]: value?.zipCode || value,
          city: value?.city || "",
          state: value?.state_abbreviation || "",
        };
      } else {
        setAddressInputValue(value);
        objToReturn = {
          address:
            street_line && city && state
              ? `${street_line} ${city} ${state}`
              : value,
          city: city || "",
          state: state || "",
          postalCode: zipcode || "",
        };
      }

      if (propertyId >= 0 && propertyId < tempPropertyDetails.length) {
        tempPropertyDetails = tempPropertyDetails.map(
          (property: any, index: number) => {
            if (index === propertyId) {
              return {
                ...property,
                [section]: {
                  ...property[section],
                  ...objToReturn,
                },
              };
            }
            return property;
          }
        );
      }

      setDetailedLoanSizingData({
        ...detailedLoanSizingData,
        propertyDetails: tempPropertyDetails,
      });
    },
    [
      detailedLoanSizingData,
      addressOptions,
      setAddressOptions,
      setDetailedLoanSizingData,
    ]
  );

  const handleChange = useCallback(
    (
      value: any,
      type: string,
      field: Field,
      section: string,
      propertyId: number,
      unitId: number
    ) => {
      const { id } = field;

      if (PROPERTY_LOCATION_FIELDS.includes(id as any)) {
        updatePostalOrAddress(
          value,
          propertyId,
          section,
          id,
          id === "postalCode"
        );
      } else if (
        PROPERTY_LEVEL_SECTIONS.includes(
          section as (typeof PROPERTY_LEVEL_SECTIONS)[number]
        )
      ) {
        let tempPropertyDetails = [
          ...(detailedLoanSizingData?.propertyDetails || []),
        ];

        if (propertyId >= 0 && propertyId < tempPropertyDetails.length) {
          tempPropertyDetails = tempPropertyDetails.map(
            (property: any, index: number) => {
              if (index === propertyId) {
                if (unitId >= 0 && unitId < property?.unitInformation?.length) {
                  const currentUnitInfo = property[section]?.map(
                    (unit: any, uId: number) =>
                      uId === unitId ? { ...unit, [id]: value } : unit
                  );
                  return {
                    ...property,
                    [section]: currentUnitInfo,
                  };
                }
                return {
                  ...property,
                  [section]: {
                    ...property[section],
                    [id]: value,
                  },
                };
              }
              return property;
            }
          );
        }

        setDetailedLoanSizingData({
          ...detailedLoanSizingData,
          propertyDetails: tempPropertyDetails,
        });
      } else {
        let returnObj = {
          ...detailedLoanSizingData,
          [section]: {
            ...detailedLoanSizingData?.[section],
            [id]: value,
          },
        };

        const specialUpdate =
          SPECIAL_FIELD_UPDATES[id as keyof typeof SPECIAL_FIELD_UPDATES];
        if (specialUpdate) {
          returnObj = {
            ...returnObj,
            [section]: {
              ...returnObj[section],
              ...specialUpdate(value),
            },
          };
        }

        setDetailedLoanSizingData(returnObj);
      }

      if (["dropDown", "autoComplete"].includes(type)) {
        onBlur(value, type, field, section, propertyId, unitId);
      }
      setNewDebouncedValue(value);
    },
    [
      detailedLoanSizingData,
      setDetailedLoanSizingData,
      updatePostalOrAddress,
      setNewDebouncedValue,
    ]
  );

  const onBlur = useCallback(
    (
      value: any,
      type: string,
      field: Field,
      section: string,
      propertyId: number,
      unitId: number
    ) => {
      const errorMessage = field?.getFieldErrors?.(value);
      const { id } = field;

      if (
        PROPERTY_LOCATION_FIELDS.includes(
          id as (typeof PROPERTY_LOCATION_FIELDS)[number]
        )
      ) {
        updatePostalOrAddress(
          value,
          propertyId,
          section,
          id,
          id === "postalCode"
        );
      } else if (
        PROPERTY_LEVEL_SECTIONS.includes(
          section as (typeof PROPERTY_LEVEL_SECTIONS)[number]
        )
      ) {
        let tempPropertyDetails = [
          ...(detailedLoanSizingErrors?.propertyDetails || [{}]),
        ];

        if (propertyId >= 0 && propertyId < tempPropertyDetails.length) {
          tempPropertyDetails = tempPropertyDetails.map(
            (property: any, index: number) => {
              if (index === propertyId) {
                if (unitId >= 0 && unitId < property?.unitInformation?.length) {
                  const currentUnitInfo = property[section]?.map(
                    (unit: any, uId: number) =>
                      uId === unitId ? { ...unit, [id]: errorMessage } : unit
                  );
                  return {
                    ...property,
                    [section]: currentUnitInfo,
                  };
                }
                return {
                  ...property,
                  [section]: {
                    ...property[section],
                    [id]: errorMessage,
                  },
                };
              }
              return property;
            }
          );
        }

        setDetailedLoanSizingErrors({
          ...detailedLoanSizingErrors,
          propertyDetails: tempPropertyDetails,
        });
      } else {
        setDetailedLoanSizingErrors({
          ...detailedLoanSizingErrors,
          [section]: {
            ...detailedLoanSizingErrors?.[section],
            [id]: errorMessage,
          },
        });
      }
    },
    [
      detailedLoanSizingErrors,
      updatePostalOrAddress,
      setDetailedLoanSizingErrors,
    ]
  );

  useEffect(() => {
    if (zipCodeInputValue.length >= 4 && zipCodeInputValue.length <= 7) {
      fetchZipCodeSuggestions({ input: zipCodeInputValue });
    }
  }, [zipCodeInputValue, fetchZipCodeSuggestions]);

  useEffect(() => {
    if (addressInputValue) {
      fetchAddress({ input: addressInputValue });
    }
  }, [addressInputValue, fetchAddress]);

  return {
    handleChange,
    onBlur,
    updatePostalOrAddress,
    addressOrPostalOnInputChange: (
      value: any,
      type: any,
      field: any,
      section: any,
      propertyId: any
    ) => {
      const { id } = field;
      let tempPropertyDetails = [
        ...(detailedLoanSizingData?.propertyDetails || []),
      ];

      tempPropertyDetails = tempPropertyDetails.map(
        (property: any, index: number) => {
          if (index === propertyId) {
            return {
              ...property,
              [section]: {
                ...property[section],
                [id]: value,
              },
            };
          }
          return property;
        }
      );

      setDetailedLoanSizingData({
        ...detailedLoanSizingData,
        propertyDetails: tempPropertyDetails,
      });

      if (field.id === "postalCode") {
        setZipCodeInputValue(value);
      } else {
        setAddressInputValue(value);
      }
    },
  };
};
