import React, { useRef, forwardRef, useEffect } from "react";

import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

import { NumericFormat, NumericFormatProps } from "react-number-format";
import { useTranslation } from "react-i18next";

// interface CustomProps {
//   onChange: (
//     value: string,
//     field: any,
//     isCurrency: any,
//     isPercentage?: boolean
//   ) => void;
//   field: any;
//   isCurrency: any;
//   isPercentage: any;
// }

const NumericFormatCustom = forwardRef<NumericFormatProps, any>(
  function NumericFormatCustom(props, ref) {
    const {
      onChange,
      field,
      isCurrency,
      isPercentage,
      // isMx100,
      ...other
    } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={onChange}
        fixedDecimalScale={isCurrency || isPercentage}
        thousandSeparator={isCurrency}
        valueIsNumericString
        prefix={isCurrency ? "$" : ""}
        suffix={isPercentage ? "%" : ""}
        decimalScale={isCurrency ? 0 : 4}
        decimalSeparator="."
      />
    );
  }
);

export default function TextFieldComponent({
  type,
  field,
  value,
  onChange,
  onBlur,
  isCurrency,
  isPercentage,
  loanDetails,
  isApplicationSubmitted,
  sectionName,
  errorMsg = "",
  currentObjFieldError,
  propertyId,
  unitId,
  dynamicConfig,
}: {
  type: string | undefined;
  field: any;
  // errorMsg: string;
  value: string;
  onChange: any;
  onBlur: any;
  isCurrency: boolean;
  isPercentage: boolean;
  loanDetails: any;
  isApplicationSubmitted: boolean;
  sectionName: string;
  errorMsg: string;
  currentObjFieldError: any;
  propertyId: any;
  unitId: any;
  dynamicConfig: any;
}) {
  const checkIsDisabled = (field: any) => {
    const { isDisabled, isAutoCalculated } = field;
    if (isDisabled) {
      return true;
    }
    if (
      dynamicConfig?.allowEditAfterSubmit &&
      !(
        isAutoCalculated &&
        isAutoCalculated({
          sizingType: loanDetails?.sizingType,
        })
      )
    ) {
      return false;
    }
    if (
      isApplicationSubmitted ||
      (isAutoCalculated &&
        isAutoCalculated({
          sizingType: loanDetails?.sizingType,
        }))
    ) {
      return true;
    }
  };

  const ref = useRef();
  const { t } = useTranslation();
  return (
    <FormControl variant="outlined" sx={{ width: "100%" }}>
      <TextField
        data-testid="custom-input-field"
        className={`app-theme-data-points truncate-words p-4 br-6 ${
          currentObjFieldError && !!errorMsg && "app-theme-data-points-error"
        } `}
        type={type}
        sx={{ width: "90%" }}
        variant="standard"
        size="small"
        value={value}
        onChange={(e: any) =>
          onChange?.(
            e?.target?.value ?? e?.value,
            type,
            field,
            sectionName,
            propertyId,
            unitId
          )
        }
        onBlur={(e: any) =>
          onBlur?.(
            e?.target?.value ?? e?.value,
            type,
            field,
            sectionName,
            propertyId,
            unitId
          )
        }
        disabled={checkIsDisabled(field)}
        name={field.label}
        id={field.label}
        inputRef={ref}
        InputProps={
          isCurrency || isPercentage
            ? {
                inputComponent: NumericFormatCustom as any,
                inputProps: {
                  field,
                  isCurrency,
                  isPercentage,
                  ref,
                },
                disableUnderline: true,
              }
            : { disableUnderline: true }
        }
      />
      {value !== "" && field?.isPopUpMessage && (
        <span className="error-text">{t(field?.popUpMessage)}</span>
      )}
      {currentObjFieldError ? (
        <FormHelperText className="break-word">{t(errorMsg)}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
