import { Button, Grid, Typography } from '@mui/material'

type KDMHeaderPropTypes = {
    label: string
    handleShowKDMBody: (status: boolean) => void
    icon?: any
    reactToPrintFn?: any
    exportIcon?:any
}

const KDMHeader = ({label, handleShowKDMBody, icon, exportIcon, reactToPrintFn}: KDMHeaderPropTypes) => {
  const ExportStartIcon = () => {
    return (
      <>
        <img src={exportIcon} alt="export-start-icon" />
      </>
    );
  };
  return (
  
      <Grid container sx={{ height: "72px", padding: "20px", borderBottom: "1px solid #B4BBD8"}}>
        <Grid item xs={11} sx={{display: "flex", justifyContent: "space-between"}}>
            <Typography sx={{fontSize: "24px", fontWeight: "bold"}}>{label}</Typography>
            {reactToPrintFn ? <Button
                size='large'
                variant='outlined'
                onClick={reactToPrintFn}
                startIcon={<ExportStartIcon />}
            >
                Print
            </Button> : null}
        </Grid>
        <Grid item xs={1} sx={{display: "flex", justifyContent: "end"}}>            
            <img src={icon} style={{cursor: "pointer"}} onClick={() => handleShowKDMBody(false)}/>
        </Grid>

      </Grid>
  )
}

export default KDMHeader
