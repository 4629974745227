import GetFieldUI from "../../GetFieldUI";

export default function LoanEconomics(props: any) {
  const {
    fieldConfig,
    isApplicationSubmitted,
    detailedLoanSizingData,
    detailedLoanSizingErrors,
    client,
    handleChange,
    onBlur,
    loanDetails,
  } = props;

  return (
    <div>
      <h4>Loan Economics</h4>
      <GetFieldUI
        formFields={fieldConfig?.["loanEconomics"]?.fields}
        currentObjValue={
          detailedLoanSizingData?.[
            fieldConfig?.["loanEconomics"]?.header
          ] ?? {}
        }
        handleChange={handleChange}
        onBlur={onBlur}
        loanDetails={loanDetails}
        sectionName={fieldConfig?.["loanEconomics"]?.header}
        zipCodeSuggestions={[]}
        currentObjFieldErrors={
          detailedLoanSizingErrors?.[
            fieldConfig?.["loanEconomics"]?.header
          ] ?? {}
        }
        client={client}
        isApplicationSubmitted={isApplicationSubmitted}
      />
    </div>
  );
}
