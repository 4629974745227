import GetFieldUI from "../../GetFieldUI";

export default function PropertyLocation(props: any) {
  const {
    fieldConfig,
    detailedLoanSizingData,
    isApplicationSubmitted,
    client,
    handleChange,
    onBlur,
    propertyDetails,
    detailedLoanSizingErrors,
    propertyId,
    zipCodeOptions,
    addressOptions,
    addressOrPostalOnInputChange,
  } = props;

  return (
    <div>
      <h4>Property Location</h4>
      <GetFieldUI
        formFields={fieldConfig?.["propertyLocation"]?.fields}
        currentObjValue={
          detailedLoanSizingData?.[fieldConfig?.["propertyLocation"]?.header] ??
          {}
        }
        handleChange={handleChange}
        onBlur={onBlur}
        propertyDetails={propertyDetails}
        sectionName={fieldConfig?.["propertyLocation"]?.header}
        currentObjFieldErrors={
          detailedLoanSizingErrors?.propertyDetails?.[propertyId]?.[
            fieldConfig?.["propertyLocation"]?.header
          ] ?? {}
        }
        client={client}
        propertyId={propertyId}
        zipCodeSuggestions={zipCodeOptions}
        addressOptions={addressOptions}
        addressOrPostalOnInputChange={addressOrPostalOnInputChange}
        isApplicationSubmitted={isApplicationSubmitted}
      />
    </div>
  );
}
