import { useMemo } from "react";

import {
  Paper,
  Radio,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { t } from "i18next";
import { textAlign } from "@mui/system";

const StrippedTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(158, 156, 156, 0.1)",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFCFF",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(158, 156, 156, 0.1)",
    color: theme.palette.common.black,
    borderBottom: "none",
    fontSize: "14px",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

export default function BuyDownOptionsTable(props: any) {
  const {
    tableHeader,
    tableBody,
    label,
    tableClassName,
    classes,
    currentSelectedInterestRate,
    handleInterestChange,
  } = props;

  const renderTableBody = useMemo(() => {
    if (!tableBody) return null;
    let bIdx: number | null = null;
    tableBody?.find((tB: any, idx: number) => {
      if (tB?.interestRate === currentSelectedInterestRate.interestRate)
        bIdx = idx;
    });

    return tableBody.map((body: any, index: number) => {
      return (
        <StrippedTableRow key={`Row-${index}`}>
          {Object.keys(body).map((key, idx) => {
            if (idx === 0) {
              return (
                <StyledTableCell
                  style={{
                    color: "black",
                    borderBottom: "none",
                    marginBottom: "8px",
                    textAlign: "center",
                    fontWeight:
                      currentSelectedInterestRate?.interestRate === body[key]
                        ? 700
                        : 500,
                  }}
                  key={`${key}-${index}`}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Radio
                      checked={
                        currentSelectedInterestRate?.interestRate === body[key]
                      }
                      onChange={() => handleInterestChange(body)}
                    />
                    <span>{body[key]}</span>
                  </div>
                </StyledTableCell>
              );
            }
            return (
              <StyledTableCell
                style={{
                  color: "black",
                  borderBottom: "none",
                  marginBottom: "8px",
                  borderLeft: "1px solid #D5DCED",
                  textAlign: "center",
                  fontWeight: bIdx === index
                    ? 700
                    : 500,
                }}
                key={`${key}-${index}`}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <span>{body[key]}</span>
                </div>
              </StyledTableCell>
            );
          })}
        </StrippedTableRow>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableBody, t, label, currentSelectedInterestRate]);

  return (
    // <Paper className={classes?.paper}>
    <TableContainer
      sx={{
        padding: "0px 0px",
        borderRadius: "8px",
        fontSize: "1rem",
      }}
    >
      <Table className={tableClassName}>
        {tableHeader?.length ? (
          <TableHead
            style={{
              backgroundColor: "#9E9C9C1A",
              fontSize: "14px",
            }}
          >
            <StrippedTableRow style={{ borderRadius: "8px" }}>
              {tableHeader?.map((ele: any, idx: number) => (
                <StyledTableCell
                  className={ele?.headerClassName ?? ""}
                  key={`${ele?.label}-${idx}`}
                >
                  {t(ele?.label ?? ele)}
                </StyledTableCell>
              ))}
            </StrippedTableRow>
          </TableHead>
        ) : null}
        <TableBody>{renderTableBody}</TableBody>
      </Table>
    </TableContainer>
    // </Paper>
  );
}
