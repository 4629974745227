export enum Clients {
    MMTC = "mmtc",
    BPL_SIZER = "bplsizer"
}

export enum SizingType {
    QUICK_LOAN_SIZING = "quickLoanSizing",
    DETAILED_LOAN_SIZING = "detailedLoanSizing"
}

export enum SizingTypeValue {
    QUICK_LOAN_SIZING = "Quick Loan Sizing",
    DETAILED_LOAN_SIZING = "Detailed Loan Sizing"
}

export enum ProductType {
    DSCR = "Investor DSCR",
    BRIDGE = "Bridge",
}

export enum WaiverRoles {
    PRICING_WAIVER_APPROVER = "pricing_waiver_approver"
}