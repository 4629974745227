import { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { debounce, Grid } from "@mui/material";

import { getBorrowerFields } from "../LoanFields/LoanDetails/BorrowerInformation";
import { RootContext } from "../../../context/RootContext";
import { Clients } from "../../../types/enums";
import { LoansContext } from "../../../context/LoansContext";
import GetFieldUI from "./GetFieldUI";
import { getPropertyLocationFields } from "../LoanFields/LoanDetails/PropertyLocationFields";
import { useZipCodeSuggestions } from "../../../hooks/useFetchCityState";
import { getPropertyInformationFields } from "../LoanFields/LoanDetails/PropertyInformationFields";
import { getPropertyEconomicsFields } from "../LoanFields/LoanDetails/PropertyEconomicsFields";
import { getLoanInformationFields } from "../LoanFields/LoanDetails/LoanInformation";
import { getLoanEconomicsFields } from "../LoanFields/LoanDetails/LoanEconomics";
import { getZipCodeList } from "../../../services/network";
import { ZipCodeSuggestion } from "../../../types/fieldTypes";
import { ObjectType } from "../../../types/tableTypes";
import { getLoanOutputFields } from "../LoanFields/LoanDetails/LoanOutputs";

export default function QuickLoanSizing() {
  const { dynamicConfig } = useContext(RootContext);
  const {
    loanDetails,
    selectedSizingType,
    zipCodeOptions,
    setZipCodeOptions,
    quickLoanSizingData,
    setQuickLoanSizingData,
    setNewDebouncedValue,
  } = useContext(LoansContext);
  const { t } = useTranslation();

  const [configs, setConfigs] = useState<any>([]);
  const [quickLoanSizingErrors, setQuickLoanSizingErrors] = useState<any>({});
  const [zipCodeInputValue, setZipCodeInputValue] = useState<string>("");

  const client =
    dynamicConfig.ViteAppKcRealm === "bplsizer"
      ? Clients?.BPL_SIZER
      : Clients?.MMTC;

  const fetchZipCodeSuggestions = useCallback(
    debounce(async (request: { input: string }) => {
      try {
        const { data } = await getZipCodeList(
          dynamicConfig?.ViteAppSmartyStreetsApiKey,
          request.input
        );
        const { city_states, zipcodes } = data[0];
        let newOptions = [] as ZipCodeSuggestion[];
        if (city_states && zipcodes) {
          const zipCode = zipcodes[0].zipcode;
          const temp = city_states.map((ele: ObjectType) => {
            return { ...ele, ...{ zipCode } };
          });
          newOptions = [...newOptions, ...temp];
        }
        setZipCodeOptions(newOptions);
      } catch (err) {
        console.error(err);
      }
    }, 200),
    []
  );

  useEffect(() => {
    const borrowerFields = getBorrowerFields(
      loanDetails?.productType,
      client,
      "borrowerInformation",
      selectedSizingType
    );
    const propertyLocationFields = getPropertyLocationFields(
      loanDetails?.productType,
      client,
      "propertyLocation",
      selectedSizingType
    );
    const propertyInformationFields = getPropertyInformationFields(
      loanDetails?.productType,
      client,
      "propertyInformation",
      selectedSizingType
    );

    const propertyEconomicsFields = getPropertyEconomicsFields(
      loanDetails?.productType,
      client,
      "propertyEconomics",
      selectedSizingType
    );

    const loanInformationFields = getLoanInformationFields(
      loanDetails?.productType,
      client,
      "loanInformation",
      selectedSizingType
    );
    const loanEconomicsFields = getLoanEconomicsFields(
      loanDetails?.productType,
      client,
      "loanEconomics",
      selectedSizingType
    );
    const loanOutputFields = getLoanOutputFields(
      loanDetails?.productType,
      client,
      "loanOutputs",
      selectedSizingType
    );

    setConfigs({
      borrowerInformation: {
        borrowerInformation: {
          fields: borrowerFields,
          header: "borrowerInformation",
        },
      },
      propertyDetails: {
        propertyLocation: {
          fields: propertyLocationFields,
          header: "propertyLocation",
        },
        propertyInformation: {
          fields: propertyInformationFields,
          header: "propertyInformation",
        },
        propertyEconomics: {
          fields: propertyEconomicsFields,
          header: "propertyEconomics",
        },
      },
      loanDetails: {
        loanInformation: {
          fields: loanInformationFields,
          header: "loanInformation",
        },
        loanEconomics: {
          fields: loanEconomicsFields,
          header: "loanEconomics",
        },
        loanOutputs: {
          fields: loanOutputFields,
          header: "loanOutputs",
        },
      },
    });

    // setPropertyLocationConfig(propertyLocationFields);
    // // const propertyInformationFields = getPropertyInformationFields();
    // // const propertyEconomicsFields = getPropertyEconomicsFields();
  }, [dynamicConfig]);

  useEffect(() => {
    let active = true;
    if (zipCodeInputValue.length < 4 || zipCodeInputValue.length > 7) {
      return undefined;
    }
    fetchZipCodeSuggestions({ input: zipCodeInputValue });
    return () => {
      active = false;
    };
  }, [zipCodeInputValue]);

  const handleChange = (e: any, type: string, field: any, key: string) => {
    const { label, id } = field;
    let value = e;
    if (field.id === "postalCode" && typeof value !== "string") {
      setQuickLoanSizingData({
        ...quickLoanSizingData,
        [key]: {
          ...quickLoanSizingData[key],
          [id]: value?.zipCode,
          city: value?.city,
          state: value?.state_abbreviation,
        },
      });
    } else {
      let returnObj = {
        ...quickLoanSizingData,
        [key]: {
          ...quickLoanSizingData?.[key],
          [id]: value,
        },
      };
      if (id === "interestOnlyFlag") { 
        returnObj = {
          ...returnObj,
          [key]: {
            ...returnObj[key],
            ioPeriod: value === "Yes" ? "120" : null,
          },
        };
      }
      setQuickLoanSizingData(returnObj);
    }
    setNewDebouncedValue(value);
  };

  const onBlur = (e: any, type: string, field: any, key: string) => {
    const errorMessage = field?.getFieldErrors(e);

    if (errorMessage) {
      setQuickLoanSizingErrors({
        ...quickLoanSizingErrors,
        [key]: {
          ...quickLoanSizingErrors[key],
          [field.id]: errorMessage,
        },
      });
    }
    // const { fieldName, getFieldErrors } = field;
    // if (getFieldErrors) {
    //   const error = getFieldErrors(e.target.value);
    //   setQuickLoanSizingErrors((prevState: any) => {
    //     return { ...prevState, [fieldName]: error };
    //   });
    // }
  };

  const addressOrPostalOnInputChange = (
    value: any,
    type: any,
    field: any,
    section: any,
    propertyId: any
  ) => {
    const { id } = field;

    let returnObj = { ...quickLoanSizingData[section] };
    if (typeof value === "string") {
      returnObj = {
        ...returnObj,
        [id]: value,
      };
    } else {
      returnObj = {
        ...returnObj,
        [id]: value?.zipCode,
        city: value?.city,
        state: value?.state_abbreviation,
      };
    }
    setQuickLoanSizingData({
      ...quickLoanSizingData,
      [section]: returnObj,
    });
    setZipCodeInputValue(value);
  };

  return (
    <div>
      <div style={{ margin: "0px 16px" }}>
        <div>
          {Object.keys(configs).map((key: string, idx: number) => {
            return (
              <div key={idx}>
                <h4 style={{ marginBottom: "24px" }}>{t(key)}</h4>
                {Object.values(configs[key])?.map((configObj: any) => {
                  return (
                    <GetFieldUI
                      formFields={configObj?.fields}
                      currentObjValue={
                        quickLoanSizingData?.[configObj?.header] ?? ""
                      }
                      handleChange={handleChange}
                      onBlur={onBlur}
                      loanDetails={loanDetails}
                      sectionName={configObj?.header}
                      zipCodeSuggestions={zipCodeOptions}
                      currentObjFieldErrors={
                        quickLoanSizingErrors[configObj?.header]
                      }
                      client={client}
                      addressOrPostalOnInputChange={
                        addressOrPostalOnInputChange
                      }
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
