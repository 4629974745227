import moment from "moment";

import { Button, Grid, TextField, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileIcon from "../../../../../../assets/FileIcon.svg";
import CustomChip from "../../../../../../components/common/CustomChip";
import { formatCurrency } from "../../../../../../utils/v2/common";

import ApprovalFlow from "./ApprovalFlow";
import RejectionFlow from "./RejectionFlow";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RootContext } from "../../../../../../context/RootContext";
import {
  uploadWaiverRequestDoc,
  uploadFileToCloud,
  fetchWaiverRequestDoc,
} from "../../../../../../services/network";
import { LoadingButton } from "@mui/lab";
import WaiverHistory from "./WaiverHistory";
import CloseIcon from "@mui/icons-material/Close";
import { WaiverWrapper } from "./WaiverWrapper";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function WaiverList(props: any) {
  const {
    buyDownOptions,
    fieldErrors,
    currentSelectedInterestRate,
    isRequestPricingWaiver,
    setIsRequestPricingWaiver,
    requestedWaivers,
    comments,
    setComments,
    handleWaiverRequest,
    userAction,
    handleUserAction,
    handleDiscountPointChange,
    updatedDiscountPoints,
    handleDiscountBlur,
    handleApprovalComment,
    approvalComment,
    handleApproveWaiver,
    cancelApproveWaiver,
    rejectionComment,
    handleRejectionComment,
    handleRejectionWaiver,
    t,
    isRequestor,
    handleRequestWaiverClick,
    isLoading,
    setIsLoading,
    waiverHistory,
    resetWaiverData,
    setResetWaiverData,
    leverageAndPricingData,
  } = props;
  const { dynamicConfig } = useContext(RootContext);
  const [uploadedFilePath, setFilePath] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileUploadStatus, setFileUploadStatus] = useState("");

  const { loanId } = useParams<{ loanId: string }>();

  useEffect(() => {
    if (resetWaiverData) {
      setComments("");
      deleteFile();
      setResetWaiverData(false);
      cancelApproveWaiver();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetWaiverData]);

  const handleWaiverCancel = () => {
    setIsRequestPricingWaiver(false);
    setComments("");
    cancelApproveWaiver();
  };

  const uploadDoc = async (event: any) => {
    setIsLoading(true);
    try {
      if (!event.target.files?.length) return;
      setFileUploadStatus("Uploading");
      const file = event.target.files?.[0];
      const filename = file.name;
      const filePath = `sizerloan/${loanId}/waiverDoc/${filename}`;
      const urlData = await uploadWaiverRequestDoc(
        dynamicConfig?.fileUploadUrl ?? "",
        {
          path: filePath,
          originatorId: null,
          originatorLoanId: null,
          internal: false,
        }
      );
      await uploadFileToCloud(file, urlData?.data);
      let strippedFilePath = `${urlData?.data?.split("sizerloan")?.[0]}`;
      strippedFilePath = `${strippedFilePath}${filePath}`;
      setFilePath(strippedFilePath);
      setFileName(filename);
      setFileUploadStatus("Completed");
    } catch (err) {
      console.error("Something went wrong while uploading file. ERROR:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const createWaiver = () => {
    handleWaiverRequest(uploadedFilePath, fileName);
  };

  const downloadFile = async (filePath: string) => {
    setIsLoading(true);
    try {
      // if (!dynamicConfig?.fileDownloadUrl || !filePath) return;
      const urlData = await fetchWaiverRequestDoc(
        dynamicConfig?.fileDownloadUrl,
        {
          path: filePath,
        }
      );
      window.open(urlData.data, "_blank");
    } catch (err) {
      console.error("Something went wrong while downloading file. ERROR:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFile = () => {
    setFilePath("");
    setFileName("");
    setFileUploadStatus("");
  };

  return (
    <Grid container className="buy-down-container">
      {isRequestor &&
      buyDownOptions?.length &&
      Object.keys(leverageAndPricingData ?? {})?.length ? (
        <Grid
          item
          xs={12}
          m={2}
          style={{
            margin: "12px 12px 12px 12px",
          }}
        >
          <Button
            variant="contained"
            className="buy-down-button"
            onClick={handleRequestWaiverClick}
          >
            {t("requestPricingWaiver")}
          </Button>
        </Grid>
      ) : null}
      {isRequestPricingWaiver && isRequestor ? (
        <>
          <Grid
            item
            xs={12}
            m={2}
            style={{
              margin: "12px 12px 12px 12px",
              textAlign: "center",
            }}
          >
            <span className="request-pricing-waiver">
              {t("requestPricingWaiver")}
            </span>
          </Grid>
          <Grid
            container
            style={{
              margin: "0px 12px 12px 12px",
            }}
          >
            {Object.keys(currentSelectedInterestRate ?? {}).map(
              (key, index) => {
                return (
                  <Grid item xs={4}>
                    <span className="request-pricing-waiver-key">{t(key)}</span>
                  </Grid>
                );
              }
            )}
          </Grid>
          <Grid
            container
            style={{
              margin: "0px 12px 12px 12px",
            }}
          >
            {Object.keys(currentSelectedInterestRate ?? {}).map((key, index) => {
              return (
                <Grid item xs={4} sx={{ paddingRight: "12px" }}>
                  <TextField
                    className="request-pricing-waiver-key"
                    variant="outlined"
                    size="small"
                    type="text"
                    value={currentSelectedInterestRate[key]}
                    error={fieldErrors?.[key]}
                    disabled={key === "interestRate"}
                    onChange={(e: any) =>
                      handleDiscountPointChange({
                        value: e.target.value,
                        type:
                          key === "discountPoints" ? "percentage" : "currency",
                        key,
                      })
                    }
                    onBlur={(e: any) =>
                      handleDiscountBlur({
                        value: e.target.value,
                        type:
                          key === "discountPoints" ? "percentage" : "currency",
                        key,
                      })
                    }
                  />
                  {fieldErrors?.[key] ? (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                      {t(fieldErrors?.[key])}
                    </Typography>
                  ) : null}
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            style={{
              margin: "0px 12px 12px 12px",
            }}
          >
            {fileUploadStatus !== "Completed" && (
              <Grid item xs={12}>
                <LoadingButton
                  sx={{ width: "97%" }}
                  component="label"
                  role={undefined}
                  variant="outlined"
                  fullWidth
                  tabIndex={-1}
                  startIcon={<AttachFileIcon />}
                  loading={fileUploadStatus === "Uploading"}
                >
                  <span>{t("attachDocument")}</span>
                  <VisuallyHiddenInput
                    type="file"
                    accept=".pdf, .docx, .jpeg, .jpg"
                    onChange={uploadDoc}
                    multiple
                  />
                </LoadingButton>
              </Grid>
            )}
            {fileUploadStatus === "Completed" && (
              <div className="pdf-view-box">
                <div style={{ alignItems: "center", display: "flex" }}>
                  <img src={FileIcon} alt="file-icon.svg" />
                  <div style={{ marginLeft: "10px" }}>{fileName}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <Button
                    startIcon={<DownloadForOfflineIcon />}
                    onClick={() => downloadFile(uploadedFilePath)}
                    disableRipple={true}
                    size="small"
                    style={{ minWidth: "unset" }}
                  ></Button>
                  <Button
                    startIcon={<CloseIcon />}
                    onClick={() => deleteFile()}
                    disableRipple={true}
                    size="small"
                    style={{ minWidth: "unset" }}
                  ></Button>
                </div>
              </div>
            )}
            <Grid
              item
              xs={12}
              style={{
                margin: "12px 12px 12px 12px",
              }}
            >
              <span className="request-waiver-optional-comment">
                {t("optionalComments")}
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                margin: "0px 12px 12px 12px",
              }}
            >
              <TextField
                className="comments-text-box"
                fullWidth
                multiline
                minRows={4}
                value={comments}
                onChange={(event: any) => setComments(event.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                margin: "0px 12px 12px 12px",
              }}
            >
              <LoadingButton
                variant="contained"
                onClick={createWaiver}
                loading={isLoading}
                disabled={!currentSelectedInterestRate?.discountPoints}
              >
                Request
              </LoadingButton>
              &ensp;
              <Button variant="outlined" onClick={handleWaiverCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
      {requestedWaivers?.length > 0 ? (
        <Grid item xs={12} sx={{ margin: "12px" }}>
          {requestedWaivers?.map((waiver: any, index: number) => {
            return (
              <div>
                <WaiverWrapper
                  waiver={waiver}
                  t={t}
                  downloadFile={downloadFile}
                  isLoading={isLoading}
                />
                {waiver?.data?.waiverStatus === "Requested" &&
                userAction === "APPROVED" ? (
                  <ApprovalFlow
                    t={t}
                    handleDiscountPointChange={handleDiscountPointChange}
                    updatedDiscountPoints={updatedDiscountPoints}
                    handleDiscountBlur={handleDiscountBlur}
                    handleApprovalComment={handleApprovalComment}
                    approvalComment={approvalComment}
                    handleApproveWaiver={handleApproveWaiver}
                    waiver={waiver}
                    cancelApproveWaiver={cancelApproveWaiver}
                    isLoading={isLoading}
                    fieldErrors={fieldErrors}
                  />
                ) : null}
                {waiver?.data?.waiverStatus === "Requested" &&
                userAction === "REJECTED" ? (
                  <RejectionFlow
                    t={t}
                    rejectionComment={rejectionComment}
                    handleRejectionComment={handleRejectionComment}
                    cancelApproveWaiver={cancelApproveWaiver}
                    waiver={waiver}
                    handleRejectionWaiver={handleRejectionWaiver}
                    isLoading={isLoading}
                  />
                ) : null}
                {requestedWaivers[0]?.data?.waiverStatus === "Requested" &&
                !isRequestor &&
                userAction === "" ? (
                  <Grid container sx={{ marginTop: "16px" }}>
                    <LoadingButton
                      variant="contained"
                      className="approve-btn"
                      onClick={() => handleUserAction("APPROVED")}
                      loading={isLoading}
                    >
                      Approve
                    </LoadingButton>
                    &ensp;
                    <Button
                      variant="contained"
                      className="reject-btn"
                      onClick={() => handleUserAction("REJECTED")}
                    >
                      Reject
                    </Button>
                  </Grid>
                ) : null}
              </div>
            );
          })}
        </Grid>
      ) : null}
      {waiverHistory?.length > 0 ? (
        <Grid item xs={12} sx={{ margin: "12px" }}>
          <WaiverHistory
            {...props}
            downloadFile={downloadFile}
            formatCurrency={formatCurrency}
            CustomChip={CustomChip}
            FileIcon={FileIcon}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
