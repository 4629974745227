import React from "react";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";

import {
  LocationSuggestion,
  ZipCodeSuggestion,
} from "../../../types/fieldTypes";
import { t } from "i18next";

const styles = {
  autocomplete: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px",
      background: "#f8f9fd !important;",
    },
  },
};

export default function AutoCompleteComponent(props: any) {
  const {
    addressOptions,
    zipCodeOptions,
    selectedAddressValue,
    selectedZipCodeValue,
    getAddressOptionLabel,
    getPostalOptionLabel,
    addressOnChange,
    postalOnChange,
    addressInputValue,
    zipCodeInputValue,
    addressOrPostalOnInputChange,
    errorMsg,
    isApplicationSubmitted,
    onBlur,
    label,
    field,
    isExpandedView,
    sectionName,
    propertyId,
    unitId,
    loanDetails,
    dynamicConfig,
    currentObjFieldError,
  } = props;

  const checkIsDisabled = (field: any) => {
    const { isDisabled, isAutoCalculated } = field;
    if (isDisabled) {
      return true;
    }
    if (
      dynamicConfig?.allowEditAfterSubmit &&
      !(
        isAutoCalculated &&
        isAutoCalculated({
          sizingType: loanDetails?.sizingType,
        })
      )
    ) {
      return false;
    }
    if (
      isApplicationSubmitted ||
      (isAutoCalculated &&
        isAutoCalculated({
          sizingType: loanDetails?.sizingType,
        }))
    ) {
      return true;
    }
  };

  return (
    <FormControl variant="outlined" sx={{ width: "100%" }}>
      <Autocomplete
        // className="truncate-words margin-0"
        className={`truncate-words margin-0 ${
          (currentObjFieldError && !!errorMsg) ? "app-theme-data-points-error" : ""
        } `}
        style={{ margin: 0 }}
        options={(label === "address" ? addressOptions : zipCodeOptions) ?? []}
        size="small"
        filterOptions={(x: any) => x}
        filterSelectedOptions
        disableClearable
        value={
          (label === "address" ? selectedAddressValue : selectedZipCodeValue) ??
          null
        }
        getOptionLabel={
          label === "address" ? getAddressOptionLabel : getPostalOptionLabel
        }
        disabled={checkIsDisabled(field)}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          newValue: LocationSuggestion | ZipCodeSuggestion | null
        ) => {
          label === "address"
            ? addressOnChange(
                newValue,
                "autoComplete",
                field,
                sectionName,
                propertyId,
                unitId
              )
            : postalOnChange(
                newValue,
                "autoComplete",
                field,
                sectionName,
                propertyId,
                unitId
              );
        }}
        inputValue={label === "address" ? addressInputValue : zipCodeInputValue}
        onInputChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: string
        ) =>
          addressOrPostalOnInputChange(
            value,
            "autoComplete",
            field,
            sectionName,
            propertyId,
            unitId
          )
        }
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            value={
              (label === "address"
                ? selectedAddressValue?.street_line
                : selectedZipCodeValue) || ""
            }
            margin="dense"
            className={`${(currentObjFieldError && !!errorMsg) && "app-theme-data-points-error"}`}
            style={
              isExpandedView
                ? { width: "calc(100% - 2px)", margin: "0px" }
                : {
                    width: "100%",
                    margin: "0px",
                  }
            }
            variant="outlined"
            disabled={checkIsDisabled(field)}
            sx={
              (styles.autocomplete,
              { borderRadius: "6px", background: "#f8f9fd !important;" })
            }
          />
        )}
        selectOnFocus
      />
      {currentObjFieldError ? (
        <FormHelperText className="break-word">{t(errorMsg)}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
