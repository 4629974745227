import GetFieldUI from "../../GetFieldUI";

export default function PropertyInformation(props: any) {
  const {
    fieldConfig,
    detailedLoanSizingData,
    isApplicationSubmitted,
    client,
    handleChange,
    onBlur,
    propertyDetails,
    detailedLoanSizingErrors,
    propertyId
  } = props;

  return (
    <div>
      <h4>Property Information</h4>
      <GetFieldUI
        formFields={fieldConfig?.["propertyInformation"]?.fields}
        currentObjValue={
          detailedLoanSizingData?.[fieldConfig?.["propertyInformation"]?.header] ??
          {}
        }
        handleChange={handleChange}
        onBlur={onBlur}
        propertyDetails={propertyDetails}
        sectionName={fieldConfig?.["propertyInformation"]?.header}
        zipCodeSuggestions={[]}
        currentObjFieldErrors={
          detailedLoanSizingErrors?.propertyDetails?.[propertyId]?.[
            fieldConfig?.["propertyInformation"]?.header
          ] ?? {}
        }
        client={client}
        propertyId={propertyId}
        isApplicationSubmitted={isApplicationSubmitted}
      />
    </div>
  )
}