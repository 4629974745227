import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Button, Typography } from "@mui/material";

export default function ApprovalFlow(props: any) {
  const {
    t,
    handleDiscountPointChange,
    updatedDiscountPoints,
    handleDiscountBlur,
    handleApprovalComment,
    approvalComment,
    handleApproveWaiver,
    waiver,
    cancelApproveWaiver,
    isLoading,
    fieldErrors,
  } = props;

  return (
    <div className="buy-down-container">
      <Grid
        container
        style={{
          borderTop: "1px solid #B4BBD8",
          display: "flex",
          padding: "12px",
        }}
      >
        <Grid item xs={5}>
          <label className="label-text">
            {t("requestedDiscountPoints")}
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <TextField
            size="small"
            className="approval-comment-box"
            id="requestedDiscountPoints"
            value={
              updatedDiscountPoints?.discountPoints ??
              waiver?.data.requestedData.discountPoints
            }
            onBlur={(e: any) =>
              handleDiscountBlur({
                value: e.target.value,
                type: "percentage",
                key: "discountPoints",
              })
            }
            onChange={(e: any) =>
              handleDiscountPointChange({
                value: e.target.value,
                type: "percentage",
                key: "discountPoints",
              })
            }
            error={fieldErrors["discountPoints"]}
          />
          {fieldErrors?.["discountPoints"] ? (
            <Typography style={{ color: "red", fontSize: "12px" }}>
              {t(fieldErrors?.["discountPoints"])}
            </Typography>
          ) : null}
        </Grid>
        &ensp;
        <Grid item xs={6}>
          <label className="label-text">
            {t("requestedDiscountPointsDollar")}
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <TextField
            size="small"
            className="approval-comment-box"
            id="requestedDiscountPointsDollar"
            value={
              updatedDiscountPoints?.discountPointsWithDollar ??
              waiver?.data.requestedData.discountPointsAmount
            }
            onBlur={(e: any) =>
              handleDiscountBlur({
                value: e.target.value,
                type: "currency",
                key: "discountPointsWithDollar",
              })
            }
            onChange={(e: any) =>
              handleDiscountPointChange({
                value: e.target.value,
                type: "currency",
                key: "discountPointsWithDollar",
              })
            }
            error={fieldErrors["discountPointsWithDollar"]}
          />
          {fieldErrors?.["discountPointsWithDollar"] ? (
            <Typography style={{ color: "red", fontSize: "12px" }}>
              {t(fieldErrors?.["discountPointsWithDollar"])}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          padding: "12px",
        }}
      >
        <span>{t("commentApproval")}</span>
        <TextField
          multiline
          fullWidth
          minRows={2}
          className="approval-comment-box"
          value={approvalComment}
          onChange={(e) => handleApprovalComment(e)}
        />
      </Grid>
      <div style={{ margin: "12px" }}>
        <LoadingButton
          className="approve-btn"
          onClick={() => handleApproveWaiver(waiver)}
          loading={isLoading}
        >
          Approve
        </LoadingButton>{" "}
        &ensp;
        <Button variant="outlined" onClick={() => cancelApproveWaiver()}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
