import React, { useState, Suspense, useEffect } from "react";
import { useClientConfig } from "./hooks/useClientConfig";
import CustomRoutes from "./routes/Routes";
import { RootProvider } from "./context/RootContext";
import { LoansProvider } from "./context/LoansContext";
import "./App.css";
import { setCookie } from "./utils/Common";
import { AuthLayer } from "./context/AuthLayer";
import { Backdrop, Link } from "@mui/material";
import "./components/common/Loader.css";
import { useAxios, useTemplateAxios } from "./hooks/useAxios";
import axios from "axios";
import StickyFooter from "./components/termSheet/StickyFooter";
import SnackBar from "./components/snackBar/snackBar";

export let axiosClient = axios.create({
  headers: {
    Accept: "application/json",
  },
});
export let templateAxiosClient = axios.create({
  headers: {
    Accept: "application/json",
  },
});
const config = JSON.parse(localStorage.getItem("client_config") || "null");

function App() {
  const [dynamicConfig, setDynamicConfig] = useState<any>(config);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  useClientConfig(setDynamicConfig);
  axiosClient = useAxios(dynamicConfig);
  templateAxiosClient = useTemplateAxios(dynamicConfig);

  useEffect(() => {
    const searchUrl = new URLSearchParams(window.location.href);
    const urlCode = searchUrl.get("code") ?? "";
    setCookie("code", urlCode);
  }, []);

  const handleCloseSnackBar = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarMessage("");
  };

  if (!dynamicConfig)
    return (
      <Backdrop open className="backDrop">
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      </Backdrop>
    );

  return (
    <>
      <RootProvider
        dynamicConfig={dynamicConfig}
        setUserLoggedIn={setUserLoggedIn}
        userLoggedIn={userLoggedIn}
        snackBarMessage={snackBarMessage}
        setSnackBarMessage={setSnackBarMessage}
      >
        <AuthLayer>
          <LoansProvider>
            <div>
              <Suspense fallback="loading...!">
                <CustomRoutes />
                    {dynamicConfig?.ViteAppKcRealm === "bplsizer" ? <div style={{
                      position: "fixed", bottom: "0", right: "0", background: "#67dfdf",
                      borderRadius: "5px 0", padding: "5px", fontSize: "14px"
                    }}>
                      <span>Link to Webinar:</span> <Link target="_blank" rel="noopener" href="https://register.gotowebinar.com/recording/209617932257718699">Getting to Know the Online Sizer </Link>
                    </div> : null
                    }
                <StickyFooter />
                {snackBarMessage ? <SnackBar message={snackBarMessage} handleClose={handleCloseSnackBar} /> : null}
              </Suspense>
            </div>
          </LoansProvider>
        </AuthLayer>
      </RootProvider >
    </>
  );
}

export default App;
