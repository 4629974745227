/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import moment from "moment";

import {
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  Button,
} from "@mui/material";

import { filterMasterView } from "./TableFormatter";
import { useColumnTypeComponents } from "./TableHooks";
import { t } from "i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { filterLoanTypes } from "../../../utils/Common";

interface ListOptions {
  id?: string;
  key: string;
  viewText?: string;
}
interface ColumnTypeFilterProps {
  columnName: string;
  isLoading?: boolean;
  handleFilterToggle?: () => void;
  handleCheckBoxChange?: () => void;
  checked?: string[];
  options?: ListOptions[];
  overRideStyles?: any;
  filterTitle?: any;
  showSelectAll?: boolean;
  getRowData?: any;
  handleCheckboxOnchange?: any;
  selectedLoanType?: any;
  uncheckAll?: any;
  checkboxRef?: any;
  handleRateAsOfFilter?: any;
  dateSelected?: any;
  setDateSelected?: any;
  filterData: { [key: string]: any };
}

const ColumnTypeFilter = (props: ColumnTypeFilterProps) => {
  const {
    columnName,
    isLoading,
    checked = [],
    options = [],
    filterTitle,
    handleFilterToggle = () => {
      /* default value */
    },
    handleCheckBoxChange = () => {
      /* default value */
    },
    overRideStyles,
    showSelectAll,
    getRowData,
    handleCheckboxOnchange,
    selectedLoanType,
    uncheckAll,
    checkboxRef,
    handleRateAsOfFilter,
    dateSelected,
    setDateSelected,
    filterData,
  } = props;
  const columnLoanTypeFilter = false;
  const {
    sortedOptions,
    handleListKeyDown,
    handleToggle,
    handleClose,
    handleCheckBoxToggle,
    open,
    setOpen,
    anchorRef,
    useStyles,
  } = useColumnTypeComponents({
    handleFilterToggle,
    columnName,
    loanTypeOptions: options,
    columnLoanTypeFilter,
    handleCheckBoxChange,
    checked,
  });

  const classes: any = useStyles();

  const getsortedOptions = useMemo((): any[] => {
    if (!showSelectAll) {
      return sortedOptions;
    }

    // If the sorted options array is not empty, add a "Select All" option at the beginning
    if (sortedOptions.length) {
      sortedOptions.unshift({ key: "Select All", doc_count: Math.random() });
    }

    return sortedOptions;
  }, [sortedOptions]);

  const handleClosePopper = () => {
    setOpen(false);
  };

  const handleApplyButtonClick = (event: any) => {
    event.preventDefault();
    getRowData(1);
  };

  return (
    <>
      {filterMasterView({
        open,
        handleToggle,
        anchorRef,
        isfilterApplied: checked.length > 0,
        classes,
      })}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
        style={{
          zIndex: 1,
          position: "absolute",
          top: "50px",
          left: "100px",
          borderRadius: "8px",
          boxShadow:
            "0px 4px 4px 0px rgba(0, 0, 0, 0.10), 0px 2px 12px 0px rgba(0, 0, 0, 0.12)",
          background: "#FFF",
        }}
      >
        <Paper
          classes={{
            root: classes.menuRoot,
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Typography
              classes={{
                root: classes.zeroState,
              }}
            >
              {filterTitle === "Loan Type" ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                        fontWeight: 500,
                        textTransform: "uppercase",
                      }}
                    >
                      {t("loanType")}
                    </span>
                    <Button onClick={uncheckAll} data-testid="clear-button">
                      {t("clear")}
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "12px 0",
                    }}
                  >
                    {filterData?.loanType ? (
                      Object.keys(filterData.loanType).map(
                        (loanType: string, inx: number) => {
                          return (
                            <div key={inx}>
                              <input
                                type="checkbox"
                                name={loanType}
                                checked={selectedLoanType.includes(loanType)}
                                ref={(element: any) => {
                                  checkboxRef.current.push(element);
                                }}
                                onChange={(event: any) =>
                                  handleCheckboxOnchange(
                                    loanType,
                                    event.target.checked
                                  )
                                }
                                className="mr-24"
                              />
                              {filterLoanTypes[loanType].label}
                              <small
                                style={{ float: "right", marginRight: "15px" }}
                              >
                                {filterData.loanType[loanType]?.count}
                              </small>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <hr style={{ background: "#D5D5D5" }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "12px 0 10px 0",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ fontSize: "14px" }}
                      onClick={handleClosePopper}
                      data-testid="close-button"
                    >
                      {t("close")}
                    </Button>
                    <Button
                      variant="contained"
                      style={{ fontSize: "14px" }}
                      onClick={handleApplyButtonClick}
                      data-testid="apply-button"
                    >
                      {t("apply")}
                    </Button>
                  </div>
                </div>
              ) : ["Created On", "Rate as of"].includes(filterTitle) ? (
                <div className="mt-4">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="MM/DD/YYYY"
                      value={dateSelected ? moment(dateSelected) : null}
                      onChange={(event: any) => handleRateAsOfFilter(event)}
                    />
                  </LocalizationProvider>
                  <hr />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "12px 0 10px 0",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ fontSize: "14px" }}
                      onClick={() => setDateSelected("")}
                      data-testid="clear-button"
                    >
                      {t("clear")}
                    </Button>
                    <Button
                      variant="contained"
                      style={{ fontSize: "14px" }}
                      onClick={handleApplyButtonClick}
                      data-testid="apply-button"
                    >
                      {t("apply")}
                    </Button>
                  </div>
                </div>
              ): <></>}
            </Typography>
          </ClickAwayListener>

          {!isLoading && (
            <ClickAwayListener onClickAway={handleClose}>
              <List
                onKeyDown={handleListKeyDown}
                className={`${classes.menuList} ${overRideStyles?.menuList}`}
                id={"filterOptions"}
              >
                {getsortedOptions.map((value: ListOptions) => {
                  const viewText: any = value?.viewText ?? value?.key;
                  return (
                    <ListItem
                      key={value.key}
                      onClick={(e) => handleCheckBoxToggle(e, filterTitle)}
                      disableGutters
                      dense
                      button
                      id={value.key}
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.listIconRoot,
                        }}
                      >
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={checked?.includes(value.key)}
                          tabIndex={-1}
                          disableRipple
                        />
                        {/* <input
                          type="checkbox"
                          checked={checked.indexOf(value.key) !== -1}
                        ></input> */}
                      </ListItemIcon>
                      {/* <ListItemText
                        primary={valueFormatterForFilter(viewText)}
                      /> */}
                    </ListItem>
                  );
                })}
              </List>
            </ClickAwayListener>
          )}
        </Paper>
      </Popper>
    </>
  );
};

export default ColumnTypeFilter;
