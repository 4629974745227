import { LoadingButton } from "@mui/lab";
import { Button, Grid, TextField } from "@mui/material";

export default function RejectionFlow(props: any) {
  const {
    t,
    rejectionComment,
    handleRejectionComment,
    handleRejectionWaiver,
    waiver,
    cancelApproveWaiver,
    isLoading,
  } = props;

  return (
    <Grid
      container
      style={{
        display: "flex",
        padding: "12px",
      }}
    >
      <span>
        {t("commentRejection")}
        <sup style={{ color: "red" }}>*</sup>
      </span>
      <TextField
        multiline
        fullWidth
        minRows={2}
        className="approval-comment-box"
        value={rejectionComment}
        onChange={(e) => handleRejectionComment(e)}
      />
      <div style={{ margin: "12px" }}>
        <LoadingButton
          className="reject-btn"
          onClick={() => handleRejectionWaiver(waiver)}
          loading={isLoading}
        >
          Reject
        </LoadingButton>
        &ensp;
        <LoadingButton
          variant="outlined"
          onClick={() => cancelApproveWaiver()}
          loading={isLoading}
        >
          Cancel
        </LoadingButton>
      </div>
    </Grid>
  );
}
