import getVersionedFields from "..";
import { isFalsyButNotZero } from "../../../../utils/Validations";

const ToorakFICObucket = {
  id: "toorakFICObucket",
  label: "toorakFICObucket",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  fieldInfo: "",
};

const toorakLoanType = {
  id: "toorakLoanType",
  label: "toorakLoanType",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  fieldInfo: "",
};

const FinalLoanCharacterization = {
  id: "finalLoanCharacterisation",
  label: "finalLoanCharacterisation",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  fieldInfo: "",
};

export const LoanCharacterisationFields: any = {
  ToorakFICObucket,
  toorakLoanType,
  FinalLoanCharacterization
};


export const getLoanCharacterizationFields = (
  productType: string,
  client: string,
  section: string,
  sizingType?: string
) => {
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  };

  configToBeReturned?.map((field: string) => {
    if (LoanCharacterisationFields[field]) {
      finalConfigToBeReturned.push(LoanCharacterisationFields[field]);
    }
  });

  return finalConfigToBeReturned;
};