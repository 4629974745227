import AccordionNewUI from "../../../../components/common/v2/AccordionNewUI";
import LoanEconomics from "./LoanEconomics";
import LoanInformation from "./LoanInformation/index";

export function LoanDetails(props: any) {
  const {
    fieldConfig,
    detailedLoanSizingData,
    isApplicationSubmitted,
    client,
    handleChange,
    onBlur,
    loanDetails,
    detailedLoanSizingErrors,
    setCondoEligibility
  } = props;

  const renderLoanDetailsUI = () => {
    return (
      <div>
        <LoanInformation
          fieldConfig={fieldConfig["loanDetails"]}
          detailedLoanSizingData={detailedLoanSizingData}
          isApplicationSubmitted={isApplicationSubmitted}
          client={client}
          handleChange={handleChange}
          onBlur={onBlur}
          loanDetails={loanDetails}
          detailedLoanSizingErrors={detailedLoanSizingErrors}
          setCondoEligibility={setCondoEligibility}
        />
        <LoanEconomics
          fieldConfig={fieldConfig["loanDetails"]}
          detailedLoanSizingData={detailedLoanSizingData}
          isApplicationSubmitted={isApplicationSubmitted}
          client={client}
          handleChange={handleChange}
          onBlur={onBlur}
          loanDetails={loanDetails}
          detailedLoanSizingErrors={detailedLoanSizingErrors}
        />
      </div>
    );
  };

  return (
    <div>
      <AccordionNewUI
        label={"loanDetails"}
        isApplicationSubmitted={isApplicationSubmitted}
        renderUI={renderLoanDetailsUI}
      />
    </div>
  );
}
