import { isFalsyButNotZero, isLetters } from "../../../../utils/Validations";
import getVersionedFields from "..";
import { prepaymentPenaltyMonths, prepaymentPenaltyTypeDropDownValues, rateTypeDropDownValues } from "../../v1/LoanFieldsData";

const InterestOnlyFlag = {
  // TODO need to check with Moukthik on field mapping
  id: "interestOnlyFlag",
  label: "interestOnlyFlag",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select 'Yes' if the loan will have an interest-only period, where the borrower pays only the interest for a specified duration.",
};

const ioPeriod = {
  id: "ioPeriod",
  label: "ioPeriod",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: (data: any) => data?.interestOnlyFlag === "Yes",
  isExpandedView: false,
  isDisabled: true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

const PrePaymentPenaltyTypeMonths = {
  id: "prepaymentPenaltyTypeMonths",
  label: "prepaymentPenaltyMonths",
  required: true,
  type: (loanType: string, dataView: string) => "slider",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: prepaymentPenaltyMonths,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select the prepayment penalty months. This indicates the penalty schedule for early repayment.",
};

const BorrowerRequested = {
  id: "borrowerRequested",
  label: "loanAmount",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
  fieldInfo: "Enter the total amount being borrowed for the loan."
};

const PrePaymentPenaltyType = {
  id: "prepayPenaltyType",
  label: "prepayPenaltyType",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: prepaymentPenaltyTypeDropDownValues,
  minimumDataView: true,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
  fieldInfo: "Select the prepayment penalty structure for the loan",
};

const rateType = {
  id: "rateType",
  label: "rateType",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  data: rateTypeDropDownValues,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Choose the type of interest rate for the loan, such as fixed or adjustable.",
};

const loanEconomicsFields: any = {
  InterestOnlyFlag,
  ioPeriod,
  PrePaymentPenaltyTypeMonths,
  BorrowerRequested,
  PrePaymentPenaltyType,
  rateType,
};


export const getLoanEconomicsFields = (
  productType: string,
  client: string,
  section: string,
  sizingType?: string
) => {
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  };

  configToBeReturned?.map((field: string) => {
    if (loanEconomicsFields[field]) {
      finalConfigToBeReturned.push(loanEconomicsFields[field]);
    }
  });

  return finalConfigToBeReturned;
};
