import { useState, useContext, useEffect } from "react";
import { RootContext } from "../../../context/RootContext";
import QuickLoanSizing from "./QuickLoanSizing";
import { LoansContext } from "../../../context/LoansContext";
import { Clients, SizingTypeValue } from "../../../types/enums";
import { DetailedLoanSizing } from "./DetailedLoanSizing";

export default function LoanFieldsUIWrapper() {
  const { dynamicConfig } = useContext(RootContext);
  const { loanDetails } = useContext(LoansContext);

  // Derive initial client value
  const initialClient =
    dynamicConfig.ViteAppKcRealm === Clients.BPL_SIZER
      ? Clients.BPL_SIZER
      : Clients.MMTC;
  const [client] = useState<string>(initialClient);

  const [sizingTypeValue, setSizingTypeValue] = useState<any>("");

  useEffect(() => {
    setSizingTypeValue(loanDetails?.sizingType);
  }, [loanDetails]);

  // Map of UI components based on client and sizing type
  const loanUIComponents: Record<string, Record<string, JSX.Element | null>> = {
    [Clients.MMTC]: {
      [SizingTypeValue?.QUICK_LOAN_SIZING]: <QuickLoanSizing />,
      [SizingTypeValue?.DETAILED_LOAN_SIZING]: <DetailedLoanSizing />,
    },
    [Clients.BPL_SIZER]: {},
  };

  function getLoanUI() {
    return loanUIComponents[client]?.[sizingTypeValue] || null;
  }

  return <div>{getLoanUI()}</div>;
}
