import { isFalsyButNotZero, isLetters, validateCreditScore } from "../../../../utils/Validations";
import getVersionedFields from "..";
import { condoEligibilityV2DropDownValues, loanPurposeDropDownValues, loanStructureDropDown } from "../../v1/LoanFieldsData";

const BorrowerRequested = {
  id: "borrowerRequested",
  label: "borrowerRequested",
  fieldLabel: "loanAmount",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isExpandedView: false,
  fieldInfo: "Enter the total amount being borrowed for the loan.",
};

const LoanPurpose = {
  id: "loanPurpose",
  label: "loanPurpose",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: loanPurposeDropDownValues,
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select the purpose of the loan (e.g., purchase, refinance, cash-out).",
};

const purchasePrice = {
  id: "purchasePrice",
  label: "purchasePrice",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:(data: any) => ["Purchase", "Delayed Purchase"].includes(data?.loanPurpose),
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Enter the agreed purchase price of the property. This is typically found in the sales contract.",
};

const OriginatorLoanID = {
  id: "originatorLoanID",
  label: "originatorLoanID",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

const ExpectedDateOfClosing = {
  id: "expectedClosingDate",
  label: "expectedClosingDate",
  required: true,
  type: (loanType: string, dataView: string) => "date",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select the estimated date when the loan transaction will close.",
};

const BorrowerProceeds: any = {
  id: "borrowerProceeds",
  label: "cashOutRefinance",
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  required: true,
  data: [
    { label: "yes", value: "Y" },
    { label: "no", value: "N" },
  ],
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select 'Yes' if the loan includes cash-out refinancing, where the borrower withdraws a portion of the property's equity as cash. Select 'No' if no cash-out refinancing is involved in this transaction.",
};

const NoOfProperties = {
  id: "numberOfProperties",
  label: "numberOfProperties",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Enter the total number of properties being included as collateral for this loan.",
};

const CondoEligiBilityV2 = {
  id: "condoEligibility",
  label: "condoEligibility",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: condoEligibilityV2DropDownValues,
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  checkEligibility: true,
  fieldInfo: "Select the eligibility status of the property if it is a condominium.",
};

const LoanTerm = {
  id: "loanTerm",
  label: "loanTerm",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Specify the total loan term in months (e.g., 360 for 30 years).",
};

const CreditEvent = {
  id: "creditEvent",
  label: "creditEvent",
  required: false,
  title: 'creditEventTitle',
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select the foreclosure, short sale, deed-in-lieu, or bankruptcy status within the last 36 to 47 months, if applicable.",
};

const CrossCollaterlization = {
  id: "crossCollaterlization",
  label: "crossCollaterlization",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Y" },
    { label: "no", value: "N" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: (data: any) => data?.numberOfProperties > 1,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

const loanInformationFields: any = {
  BorrowerRequested,
  LoanPurpose,
  purchasePrice,
  OriginatorLoanID,
  ExpectedDateOfClosing,
  BorrowerProceeds,
  NoOfProperties,
  CondoEligiBilityV2,
  LoanTerm,
  CreditEvent,
  CrossCollaterlization
};


export const getLoanInformationFields = (
  productType: string,
  client: string,
  section: string,
  sizingType?: string
) => {
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  };

  configToBeReturned?.map((field: string) => {
    if (loanInformationFields[field]) {
      finalConfigToBeReturned.push(loanInformationFields[field]);
    }
  });

  return finalConfigToBeReturned;
};
