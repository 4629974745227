import getVersionedFields from "..";
import { isFalsyButNotZero } from "../../../../utils/Validations";
import { predominantPropType, propertyTypeDropDownValues, thirdPartyValuationStatusDropDownValues } from "../../v1/LoanFieldsData";

const monthlyPropertyGrossRent = {
  id: "monthlyPropertyGrossRent",
  label: "grossRent",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isCurrency: true,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Enter the total monthly rent income generated by the property, if applicable.",
};

const annualHOAFee = {
  id: "annualHOAFee",
  label: "annualHOAFee",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Provide the annual fees paid to the Homeowners Association, if applicable.",
};

const annualPropertyTaxes = {
  id: "annualPropertyTaxes",
  label: "annualPropertyTaxes",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Enter the estimated annual property taxes for the property.",
};

const annualInsuranceTotal = {
  id: "annualInsurance",
  label: "annualInsurance",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Provide the total annual cost of insurance policies for the property, such as hazard insurance or flood insurance.",
};

const thirdPartyValuationStatus = {
  id: "thirdPartyValuationStatus",
  label: "thirdPartyValuationStatus",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: thirdPartyValuationStatusDropDownValues,
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select the current status of any third-party valuation.",
};

const asIsValue = {
  id: "asIsValue",
  label: "asIsValue",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Enter the current appraised or market value of the property in its present condition.",
};

const thirdPartyValuation = {
  id: "thirdPartyValuation",
  label: "thirdPartyValuation",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Provide the appraised property value determined by a third-party valuation.",
};

const propertyAcquisitionPrice = {
  id: "purchasePrice",
  label: "propertyAcquisitionPrice",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Enter the purchase price of the property when it was acquired.",
};


const annualHazardInsurance = {
  id: "annualHazardInsurance",
  label: "annualHazardInsurance",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Provide the annual cost for hazard insurance, typically required for properties in high-risk areas.",
};

const annualFloodInsurance = {
  id: "annualFloodInsurance",
  label: "annualFloodInsurance",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Input the annual premium for flood insurance, if applicable.",
};

const DecliningMarkets = {
  id: "decliningMarkets",
  label: "decliningMarkets",
  required: false,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Indicate if the property is in a declining market as per market conditions or valuation reports.",
};

export const propertyEconomicsFields: any = {
  monthlyPropertyGrossRent,
  annualHOAFee,
  annualPropertyTaxes,
  annualInsuranceTotal,
  thirdPartyValuationStatus,
  asIsValue,
  thirdPartyValuation,
  propertyAcquisitionPrice,
  annualHazardInsurance,
  annualFloodInsurance,
  DecliningMarkets
}

export const getPropertyEconomicsFields = (
  productType: string,
  client: string,
  section: string,
  sizingType?: string
) => {
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  };

  configToBeReturned?.map((field: string) => {
    if (propertyEconomicsFields[field]) {
      finalConfigToBeReturned.push(propertyEconomicsFields[field]);
    }
  });

  return finalConfigToBeReturned;
};