import { useContext, useState } from "react";
import { t } from "i18next";

import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

import CollapseIcon from "../../../assets/CollapseIcon.svg";
import ExpandIcon from "../../../assets/ExpandIcon.svg";

import {
  checkIconButtonDisabled,
  handleIconButtonClick,
} from "../../../pages/Loans/v1/Common";
import { ObjectType } from "../../../types/tableTypes";

import { LoansContext } from "../../../context/LoansContext";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "6px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function AccordionNewUI(props: ObjectType) {
  const {
    label,
    isApplicationSubmitted,
    data,
    renderUI,
    handleAddProperty,
    handleDeleteProperty,
    accordionConfig,
    activePropertyId,
    propertiesLength
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const loanContextData = useContext(LoansContext);

  const { expandLeverageAndTests } = loanContextData;
  const transLabel = t(label);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      data-testid="accordion-main"
    >
      {!expandLeverageAndTests && (
        <AccordionSummary
          expandIcon={<img src={isExpanded ? CollapseIcon : ExpandIcon} />}
        >
          <div>
            <h3>{transLabel}</h3>
          </div>
          <div>
            {accordionConfig?.showActionButtons ? (
              <div
                style={{
                  display: "flex",
                }}
              >
                {accordionConfig?.buttons?.map(
                  (ele: ObjectType, index: number) => {
                    return (
                      <IconButton
                        data-testid="accordion-button"
                        key={index}
                        disabled={isApplicationSubmitted || (ele.action === "delete" && propertiesLength <= 1)}
                        onClick={() =>
                          ele.action === "add" ? handleAddProperty() : handleDeleteProperty(activePropertyId)
                        }
                        sx={{
                          borderRadius: "0% !important;",
                        }}
                      >
                        <span style={{ fontSize: "12px" }}>{t(ele.label)}</span>
                        &ensp;
                        <img src={ele.icon} alt="button-icon" />
                        &ensp;
                        {index === 0 ? "| " : null}
                      </IconButton>
                    );
                  }
                )}
              </div>
            ) : null}
          </div>
        </AccordionSummary>
      )}
      {isExpanded ? (
        <AccordionDetails>
          <div className="mt-48 ml-12">{renderUI?.()}</div>
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
}
