import { Theme, createStyles } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

export const newStyles = makeStyles((theme: Theme) => {
  return createStyles({
    paper: {
      width: "100%",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      borderRadius: 10,
    },
    table: {
    minWidth: 750,
      [theme.breakpoints.down('sm')]: {
          minWidth: '100%',
      },
    },
    statusBtn: {
      borderRadius: "5px",
      backgroundColor: "#172b4d",
      border: "0px transparent",
      color: "#ffffff",
      fontweight: "bold",
      padding: "4px",
    },
    sectionWrapper: {
      padding: "8px 24px",
    },
    ageingIcon: {
      display: "block",
      margin: "auto",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    searchContainer: {
      alignSelf: "flex-end",
      flex: 1,
      display: "flex",
      alignItems: "center",
      borderRadius: "20px",
      backgroundColor: "#f7fafc !important",
      padding: "0px 16px 0 4px !important",
      maxWidth: "290px !important",
      height: "36px !important",
      border: "solid 1px #E9ECEF !important",
    },
    statusContainer: {
      fontFamily: `"Open Sans", sans-serif !important`,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flexDirection: "column",
    },
    statusText: {
      color: "#ffffff",
      fontSize: 10,
      // margin: "auto",
      backgroundColor: "#172b4d",
      borderRadius: 6,
      padding: 5,
      fontWeight: "bold",
      lineHeight: "initial",
      margin: 0,
    },
    bundleStatusText: {
      fontSize: "10px",
      borderRadius: "5px",
      padding: "2px 4px",
      background: "#d2e3ef",
      maxWidth: "153px",
      lineHeight: "initial",
      whiteSpace: "break-spaces",
      fontWeight: "bold",
      color: "#32325d",
      textAlign: "center",
      marginTop: "5px",
      textTransform: "uppercase",
    },
    ageingContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      left: 14,
    },
    headingImage: {
      width: 22,
      height: 22,
      objectFit: "contain",
      cursor: "pointer",
      color: "black",
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        right: "-8px",
        top: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "10px",
        marginBottom: "5px",
        right: "auto",
        position: "absolute",
        top: "38px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "5px",
        marginBottom: "5px",
        right: "auto",
        position: "relative",
        top: "5px",
      },
    },
    selectEmpty: {
      marginTop: 18,
      padding: 3,
      notchedOutline: {},
      color: "#323536",
      fontSize: 14,
      "&:before": {
        borderColor: "#cad1d7",
      },
      "&:after": {
        borderColor: "#cad1d7",
      },
    },

    loanIdText: {
      color: "#5566d6",
      fontSize: 12,
      fontWeight: "bold",
      userSelect: "text",
      "&:hover": {
        cursor: "pointer",
      },
    },

    searchBox: {
      height: "35px",
      borderRadius: "5px",
      color: "#5566d6",
      fontSize: 12,
      fontWeight: "bold",
      top: 0,
      left: 0,
      border: "2px solid #2080ef",
      "&:hover": {
        cursor: "pointer",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: "red",
        opacity: "0.1" /* Firefox */,
      },
    },
    searchIcon: {
      width: "16px",
      height: "16px",
      color: "#32325D",
      position: "relative",
      top: "1px",
      left: "3px",
      marginRight: "5px",
    },
    defaulttext: {
      color: "#32325d",
      fontSize: 12,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    defaulttextFullWord: {
      color: "#32325d",
      fontSize: 12,
      whiteSpace: "break-spaces",
      overflow: "hidden",
      wordBreak: "break-word",
    },
    defaulttextAmount: {
      color: "#32325d",
      fontSize: 12,
      float: "right",
    },
    defaulttextWithMargin: {
      color: "#32325d",
      fontSize: 12,
      marginLeft: 10,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    defaulttextWithMarginFullWord: {
      color: "#32325d",
      fontSize: 12,
      marginLeft: 10,
      whiteSpace: "break-spaces",
      overflow: "hidden",
      wordBreak: "break-word",
    },
    defaulttextDiv: {
      textAlign: "center",
    },
    defaultTextProperty: {
      color: "#424242",
      fontSize: 12,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    rdgFilter: {
      width: "100%",
      border: "1px solid #4581b18c",
      borderRadius: "3px",
      color: "#47476e",
      fontWeight: "bold",
      padding: "2px 2px",
    },
    multiFilter3: {
      padding: "0 !important",
      display: "flex",
      zIndex: 4,
    },
    multiFilter4: {
      padding: "0 !important",
      display: "flex",
      margin: "0 !important",
      opacity: 0,
      backgroundColor: "transparent",
    },
    icon: {
      top: "1px !important",
      width: "auto !important",
    },
    toggleFilterButton: {
      position: "fixed",
      width: "50px",
      height: "50px",
      border: "1px solid #4581b18c",
      borderRadius: "50%",
      color: "#47476e",
      fontWeight: "bold",
      padding: "2px 2px",
      bottom: "8%",
      right: "6%",
      background: "green",
    },
    multiFilter: {
      width: "100%",
      border: "1px solid #4581b18c",
      borderRadius: "3px",
      color: "#47476e",
      fontWeight: "bold",
      padding: "2px 2px",
      height: "22px",
      overflow: "hidden",
    },
    inputFocus: {
      border: "none !important",
      "& input": {
        "&:focus": {
          border: "none !important",
        },
      },
    },
    multiFilter2: {
      width: "10%",
      border: "1px solid #4581b18c",
      borderRadius: "3px",
      color: "#47476e",
      fontWeight: "bold",
      padding: "2px 2px",
      height: "22px",
      overflow: "hidden",
      position: "absolute",
      right: "10px",
    },
    filterId: {
      cursor: "pointer",
      width: "85px !important",
      height: "25px !important",
    },

    sortIcon: {
      height: "16px",
      width: "16px",
      marginBottom: "0",
      cursor: "pointer",
      paddingTop: "2px",
    },
    sortImage: {
      display: "block",
      height: "15px",
      width: "15px",
    },
    selected: {
      color: "white",
      fontWeight: 600,
    },
    loanIdWrap: {
      lineHeight: 1,
      height: "100%",
    },
    loanIdSection: {
      display: "flex",
      justifyContent: "space-between",
    },
    selectAllTooltip: {
      padding: "1rem",
      fontSize: "12px",
    },
    selectAllWrapper: {
      width: "50%",
      height: "100%",
      position: "initial",
    },
    detailsBtn: {
      minWidth: "auto",
      justifyContent: "start",
      padding: 0,
    },
    detailsLabel: {
      width: "70px",
      position: "relative",
    },
    detailsEndIcon: {
      position: "absolute",
      right: 0,
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      // minWidth: "185px",
      maxWidth: "400px",
      // minHeight: "40px",
      padding: "5px",
    },
    arrow: {
      color: "white",
    },
    inputCommentField: {},
    input: {
      fontSize: "12px",
      color: "#424242",
      fontWeight: 100,
    },

    subHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #e2e4f1",
    },
    searchInput: {
      backgroundColor: "#f7fafc",
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      border: "1px solid #e9ecef",
      borderRadius: 30,
      width: 290,
    },
    totalCountWrap: {
      display: "flex",
      marginTop: 8,
    },
    divider: {
      height: 16,
      margin: "0 6px",
      width: 2,
      backgroundColor: "#979797",
    },
    clearIcon: {
      backgroundColor: "transparent",
      border: "none",
    },
    outterBoxStyles: {
      backgroundColor: "#f6f9fc",
      paddingTop: "16px",
    },
    boxTableContainer: {
      // margin: "16px 0",
      maxHeight: "calc(100vh - 110px)",
      boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
      border: "1px solid #ddd",
    },
    rowHover: {
      "&:hover": {
        backgroundColor: "#ecf6ff !important",
      },
    },
    tableRowGrid: {
      display: "grid !important;",
      "& .MuiTableCell-root.MuiTableCell-head": {
        display: "flex",
        alignItems: "center",
        padding: "0px 12px",
        color: "#32325d",
        lineHeight: 1.2,
        backgroundColor: "inherit",
        border: "1px solid #e9ecef",
        whiteSpace: "unset",
        overflow: "visible",
      },
      "& .MuiTableCell-root": {
        padding: "16px 12px",
        border: "1px solid #e9ecef",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box"
      },
    },
    tableBody: {
      minHeight: "250px",
      display: "block",
      [theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
      },
    },
    resolvedCheckboxColor: {
      color: "#2ece89 !important",
    },
    checkboxBase: {
      padding: 0,
      paddingLeft: 5,
      paddingBottom: 2,
      "& .MuiSvgIcon-root": {
        fontSize: "1.2rem",
      },
    },
    resolveButton: {
      marginRight: "1rem",
      background: "#5566d6",
      color: "white",
    },
    headClass: {
      justifyContent: "center",
    },
    rowClass: {
      textAlign: "center",
    },
    stickyTopZero: {
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    headerRowBackground: {
      backgroundColor: "#f6f9fc !important",
      height: "50px !important",
    },
    stickyHeader: {
      top: 0,
      left: 0,
      position: "sticky",
      backgroundColor: "inherit",
      zIndex: 1,
    },
    stickyRow: {
      top: 0,
      left: 0,
      position: "sticky",
      backgroundColor: "inherit",
      zIndex: 1,
    },
    arrowLeft1: {
      position: "absolute",
      marginTop: "10px",
      height: "32px",
      cursor: "pointer",
      right: "-14px",
      "&:hover": {
        cursor: "pointer !important",
      },
    },
    arrowLeft: {
      height: "29px",
      cursor: "pointer",
      right: "0px",
      transform: "translate(50%, 50%)",
      bottom: "0px",
      "&:hover": {
        cursor: "pointer !important",
      },
    },
    arrowLeft2: {
      position: "absolute",
      height: "29px",
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer !important",
      },
    },
    root: {
      "& .MuiSelect-select.MuiSelect-select": {
        fontSize: "12px",
        fontWeight: "100",
        padding: "3px",
      },
    },
    subStatusText: {
      color: "#32325d",
      padding: "2px 4px",
      fontSize: "8px",
      background: "#d2e3ef",
      marginTop: 3,
      whiteSpace: "break-spaces",
      borderRadius: 5,
    },
    statusClass: {
      padding: "5px 12px !important",
    },
    modalContainer: {
      flexDirection: "column",
      width: "500px",
      height: "250px",
    },
    checkLineWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "0px 20px",
    },
    textArea: {
      padding: 0,
    },
    commentsField: {
      border: "1px solid #cad1d7",
      borderRadius: "3px",
      position: "relative",
      top: -5,
      height: 30,
      paddingLeft: 10,
      paddingTop: 3,
      cursor: "pointer",
    },
    addText: {
      padding: "5px 0px 5px 17px",
      position: "absolute",
      bottom: 0,
      margin: 0,
    },
    sectionPadding: {
      paddingTop: "35px",
      paddingBottom: "10px",
    },
    modalCancelButton: {
      padding: "6px 16px",
      borderRadius: 4,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      marginRight: 15,
      borderWidth: 1,
      borderColor: "#ececec",
    },
    fundingTag: {
      backgroundColor: "#3d55df",
      borderRadius: "1px",
      color: "white",
      padding: "0 0.2rem",
      fontSize: "12px",
      lineHeight: "1.2",
      marginRight: "0.5rem",
    },
    expressTag: {
      backgroundColor: "#1071ee",
      borderRadius: "1px",
      color: "white",
      padding: "0 0.2rem",
      fontSize: "12px",
      lineHeight: "1.2",
    },
    premierTag: {
      backgroundColor: "#b87300",
      borderRadius: "1px",
      color: "white",
      padding: "0 0.2rem",
      fontSize: "12px",
      lineHeight: "1.2",
    },
    badgeFilter: {
      height: "8px !important",
      width: "8px !important",
      zIndex: 0,
      margin: "-2px",
    },
    customScrollbar: {
      '&::-webkit-scrollbar': {
        height: '8px',
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(206, 210, 229, 0.5)',
        borderRadius: '13px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#362866",
        backgroundClip: 'padding-box',
        border: '2px solid transparent', // Transparent to match your commented-out rule
        borderRadius: '13px',
        WebkitBorderRadius: '13px',
        boxShadow: 'inset 0px 4px 0px 0px rgba(0, 0, 0, 0)',
      },
    },
  });
});

export const overRideStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuList: {
      padding: "8px 0px",
    },
    textAreaStyles: {
      "& .MuiInputBase-root": {
        padding: "0px",
      },
    },
  })
);
