import { CustomTable } from '@toorak/tc-common-fe-sdk';
import CustomChip from '../../../../../../components/common/CustomChip';
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FileIcon from "../../../../../../assets/FileIcon.svg";
import { LoadingButton } from '@mui/lab';
import moment from "moment";
import { Theme } from '@emotion/react';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableRowGrid: {
            "& .MuiTableCell-root.MuiTableCell-head": {
                padding: "16px"
            },
            "& .MuiTableCell-root": {
                padding: "16px 16px",
                borderLeft: "1px solid #E4ECFF",
                borderBottom: "0px",
                fontSize: "12px",
                textAlign:"center"
            },
            "& .MuiTableCell-root:first-child": {
                borderLeft: "none",
                textAlign:"left"
            }
        },
        headerRowBackground: {
            background: "rgba(158, 156, 156, 0.1)",
        },
        table: {
            marginTop: "5px",
            borderRadius: "5px",
            overflow: "hidden"
        }
    })
);

const getColumns = (waiverStatus: string) => {
    const baseColumns = [
        { id: "label", title: "" },
        { id: "actualValue", title: "Actual Values" }
    ];
    if (waiverStatus === "Approved") {
        return [
            ...baseColumns,
            { id: "requestedValue", title: "Requested Values" },
            {
                id: "approvedValue", title: "Approved Values", rowClass: "approved-data"
            },
        ]
    } else if (waiverStatus === "Rejected") {
        return [
            ...baseColumns,
            { id: "requestedValue", title: "Requested Values", rowClass: "values-rejected-preview-text" }
        ]
    } else {
        return [
            ...baseColumns,
            { id: "requestedValue", title: "Requested Values" }
        ]
    }
}

const getRowsData = (waiverData: any) => {
    const rowsData = [
        {
            label: "Interest Rate %",
            key: "interestRate",
        },
        {
            label: "Discount Points",
            key: "discountPoints"
        },
        {
            label: "Discount Points ($)",
            key: "discountPointsAmount",
        }
    ];
    rowsData.forEach((row: any) => {
        row.actualValue = waiverData?.actualData?.[row.key];
        row.requestedValue = waiverData?.requestedData?.[row.key];
        row.approvedValue = waiverData?.finalData?.[row.key];
    });

    return rowsData;
}

export const WaiverWrapper = (props: any) => {
    const { waiver, downloadFile, isLoading, t } = props;
    const columns = getColumns(waiver?.data?.waiverStatus);
    const rowsData = getRowsData(waiver?.data);
    const classes = useStyles();
    return (
        <div>
            <div
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <span className="request-header">{`${t("request")} - ${waiver?.originalIndex + 1
                    }`}</span>
                <CustomChip
                    size="small"
                    label={`Status: ${waiver?.data?.waiverStatus}`}
                    variant="outlined"
                    className={
                        waiver?.data?.waiverStatus === "Approved"
                            ? "approval-chip"
                            : waiver?.data?.waiverStatus === "Rejected"
                                ? "rejection-chip"
                                : "chip-class"
                    }
                />
            </div>

            <CustomTable
                rowsData={rowsData}
                columnsData={columns}
                classes={classes}
                selectedLoans={["discountPoints"]}
                idKey={"key"}
                onSelectedColor={"rgba(158, 156, 156, 0.1)"}
            />

            {waiver?.data?.fileName ? (
                <div className="pdf-view-box">
                    <div style={{ alignItems: "center", display: "flex" }}>
                        <img src={FileIcon} alt="file-icon.svg" />
                        <div style={{ marginLeft: "10px" }}>
                            {waiver?.data?.fileName ?? ""}
                        </div>
                    </div>
                    <div>
                        <LoadingButton
                            startIcon={<DownloadForOfflineIcon />}
                            onClick={() => downloadFile(waiver?.data?.fileLocation)}
                            loading={isLoading}
                        ></LoadingButton>
                    </div>
                </div>
            ) : null}

            {waiver?.data?.requestedData?.comments ? (
                <div className="view-comment-box">
                    <span className="request-comment-header">
                        {t("requestComment")}
                    </span>
                    <span className="comment-content">
                        {waiver?.data?.requestedData?.comments ?? ""}
                    </span>
                    <div>
                        <span className="comment-footer">
                            {waiver?.data?.requestedData?.userName} |{" "}
                            {moment(waiver?.data?.requestedData?.createdOn).format(
                                "MMM DD YYYY"
                            )}{" "}
                            |{" "}
                            {moment(waiver?.data?.requestedData?.createdOn).format(
                                "hh:mm A"
                            )}
                        </span>
                    </div>
                </div>
            ) : null}
            {waiver?.data?.waiverStatus === "Approved" &&
                waiver?.data?.finalData?.comments ? (
                <div className="approval-comment-view-box">
                    <div>
                        <span className="request-comment-header">
                            {t("approvalComment")}
                        </span>
                    </div>
                    <div>
                        <span className="comment-content">
                            {waiver?.data?.finalData?.comments}
                        </span>
                    </div>
                    <div>
                        <span className="comment-footer">
                            {waiver?.data?.finalData?.userName} |{" "}
                            {moment(waiver?.data?.finalData?.createdOn).format(
                                "MMM DD YYYY"
                            )}{" "}
                            |{" "}
                            {moment(waiver?.data?.finalData?.createdOn).format(
                                "hh:mm A"
                            )}
                        </span>
                    </div>
                </div>
            ) : null}
            {waiver?.data?.waiverStatus === "Rejected" ? (
                <div className="approval-comment-view-box">
                    <div>
                        <span className="request-comment-header">
                            {t("rejectionComment")}
                        </span>
                    </div>
                    <div>
                        <span className="comment-content">
                            {waiver?.data?.finalData?.comments}
                        </span>
                    </div>
                    <div>
                        <span className="comment-footer">
                            {waiver?.data?.finalData?.userName} |{" "}
                            {moment(waiver?.data?.finalData?.createdOn).format(
                                "MMM DD YYYY"
                            )}{" "}
                            |{" "}
                            {moment(waiver?.data?.finalData?.createdOn).format(
                                "hh:mm A"
                            )}
                        </span>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
