import getVersionedFields from "..";
import { isFalsyButNotZero, isLetters } from "../../../../utils/Validations";
import { currentUsageDropDownValues, rentalCharacterizationDropDownValues } from "../../v1/LoanFieldsData";

const currentLeaseTermsInMonths = {
  id: "currentLeaseTermsInMonths",
  label: "currentLeaseTermsInMonths",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Specify the remaining duration of the current lease for this unit in months.",
};

const rentalCharacterization = {
  id: "rentalCharacterization",
  label: "rentalCharacterization",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: rentalCharacterizationDropDownValues,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: (props: any) => props?.currentLeaseTermsInMonths >= 0,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select the type of rental agreement for this unit (e.g., long-term, short-term, lease-to-own).",
};

const monthlyMarketRent = {
  id: "monthlyMarketRent",
  label: "monthlyMarketRent",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: (props: any) => props?.currentLeaseTermsInMonths >= 0,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Provide the estimated market rent for this unit, based on comparable properties.",
};

const tenantOccupancy = {
  id: "tenantOccupancy",
  label: "tenantOccupancy",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  minimumDataView: false,
  columnSplit: 6,
  isVisible: (props: any) => props?.currentLeaseTermsInMonths >= 1 && props?.currentLeaseTermsInMonths <= 5,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select 'Yes' if the current tenant has occupied the unit for four months or longer.",
};

const operatingHistory = {
  id: "operatingHistory",
  label: "operatingHistory",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  minimumDataView: false,
  columnSplit: 6,
  isVisible: (props: any) => props?.currentLeaseTermsInMonths >= 1 && props?.currentLeaseTermsInMonths <= 5,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Indicate whether the property has at least one year of documented operational history and tenant occupancy.",
};

const inPlaceLeaseRentMonthly = {
  id: "inPlaceLeaseRentMonthly",
  label: "inPlaceLeaseRentMonthly",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Provide the rent currently being charged for this unit or the net monthly income generated.",
};

const currentUsage = {
  id: "currentUnitUsage",
  label: "currentUnitUsage",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: currentUsageDropDownValues,
  minimumDataView: false,
  columnSplit: 6,
  isVisible: (props: any) => props?.currentLeaseTermsInMonths == 0,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

const percentUnitOccupied = {
  id: "percentUnitOccupied",
  label: "percentUnitOccupied",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: () => false,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
        ? "percentageValueError"
        : "",
  isAutoCalculated: (loanType: string, dataView?: string) =>
    dataView === "fullDataView",
  fieldInfo: "",
};

const unitInformationFields: any = {
  currentLeaseTermsInMonths,
  rentalCharacterization,
  monthlyMarketRent,
  currentUsage,
  tenantOccupancy,
  operatingHistory,
  inPlaceLeaseRentMonthly,
  percentUnitOccupied,
};

export const getUnitInformationFields = (
  productType: string,
  client: string,
  section: string,
  sizingType?: string
) => {
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  };

  configToBeReturned?.map((field: string) => {
    if (unitInformationFields[field]) {
      finalConfigToBeReturned.push(unitInformationFields[field]);
    }
  });

  return finalConfigToBeReturned;
};