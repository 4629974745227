import { BIV1 } from "./borrowerInformationVersions";
import { LEV1 } from "./loanEconomicsVersions";
import { LIV1 } from "./loanInformationVersions";

const bridgeV1 = {
  id: "v1-bridge-09-12-2024",
  bplsizer: {
    inputFields: {
      borrowerInformation: BIV1,
      loanInformation: LIV1,
      loanEconomics: LEV1,
      propertyLocation: ["address", "postalCode", "city", "state"],
      propertyInformation: [
        "propertyType",
        "ExpansionOrChangeOfUseCase",
        "ValuationSource",
        "PercentOccupied",
      ],
      propertyEconomics: [
        "orgAppraisalValue",
        "purchasePrice",
        "ClosingCost",
        "AsRepairedValueARV",
        "EstimatedRentPerYearUponCompletion",
        "costBasis",
        "AssignmentFee",
        "annualPropertyTaxes",
        "annualInsurance",
        "annualHOAFee",
      ],
      loanFeatures: ["OriginalAsIsLtv", "originalAsRepairedLTV", "OriginalLtc"],
      loanCharacterization: [
        "CustomerTypeExperiencedNew",
        "PurchaseOrRefinance",
        "rehabType",
        "StabilizedFlag",
        "loanCharacterization",
      ],
      propertyLoanCharacterization: [
        "cbsa",
        "MedianValue",
        "LtmHpa",
        "DaysOnMarket",
        "ESRIPopulationDensity",
      ],
    },
    outputFields: {},
  },
  mmtc: {
    inputFields: {
      multifamily: {
        borrowerInformation: [
          "foreignNational",
          "creditScore",
          "borrowerType",
          "inState",
        ],
        loanInformation: [],
        loanEconomics: [],
      },
      groundUp: {
        borrowerInformation: [],
        loanInformation: [],
        loanEconomics: [],
      },
      groundUpMF: { borrowerInformation: [] },
      rehab: {
        borrowerInformation: [],
        loanInformation: [],
        loanEconomics: [],
      },
    },
    outputFields: {},
  },
};

export default bridgeV1;
