import getVersionedFields from "..";
import { isFalsyButNotZero } from "../../../../utils/Validations";

const address = {
  id: "address",
  label: "address",
  required: true,
  type: (loanType: string, dataView: string) => "autoComplete",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validAddress" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Enter the full address of the property. This includes street number, name, and unit, if applicable.",
};

const postalCode = {
  id: "postalCode",
  label: "postalCode",
  required: true,
  type: (loanType: string, dataView: string) => "autoComplete",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: () => false,
  fieldInfo: "Enter the postal code of the property being financed. This helps determine property taxes and insurance rates.",
};

const city = {
  id: "city",
  label: "city",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isDisabled: true,
  isAutoCalculated: () => false,
  fieldInfo: "",
};

const state = {
  id: "state",
  label: "state",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isDisabled: true,
  isAutoCalculated: () => false,
  fieldInfo: "",
};

const propertyLocationFields: any = {
  postalCode,
  city,
  state,
  address
}

export const getPropertyLocationFields = (
  productType: string,
  client: string,
  section: string,
  sizingType?: string
) => {
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  };

  configToBeReturned?.map((field: string) => {
    if (propertyLocationFields[field]) {
      finalConfigToBeReturned.push(propertyLocationFields[field]);
    }
  });

  return finalConfigToBeReturned;
};
