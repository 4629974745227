import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {  SizingTypeValue } from "../../../types/enums";

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    minHeight: "38px",
    height: "38px",
    maxHeight: "38px",
    fontSize: "14px",
    width: "100%",
  },
};

export default function DropDown(props: any) {
  const {
    data,
    errorMsg,
    handleChange,
    field,
    sectionName,
    value,
    currentObjFieldError,
    propertyId,
    unitId,
    isApplicationSubmitted,
    loanDetails,
    dynamicConfig,
    currentObjValue
  } = props;
  const { t } = useTranslation();

  return (
    <div>
      <FormControl
        variant="outlined"
        sx={{
          width: "100%",
          height: "38px",
          "@media (max-width: 600px)": { width: "100%" },
        }}
      >
        <Select
          className={`app-theme-data-points truncate-words p-4 br-6 ${
            currentObjFieldError && !!errorMsg && "app-theme-data-points-error"
          } `}
          displayEmpty
          input={<OutlinedInput />}
          autoWidth
          value={value ?? "none"}
          onChange={(ele) =>
            handleChange(
              ele.target.value,
              "dropDown",
              field,
              sectionName,
              propertyId,
              unitId
            )
          }
          sx={styles.select}
          placeholder="Select"
          disabled={
            dynamicConfig?.allowEditAfterSubmit &&
            !(
              field?.isAutoCalculated &&
              field?.isAutoCalculated({
                sizingType: loanDetails?.sizingType,
              })
            )
              ? false
              : isApplicationSubmitted ||
                field?.isAutoCalculated({ sizingType: loanDetails?.sizingType })
          }
        >
          <MenuItem value="none" disabled sx={{ fontSize: "14px" }}>
            Select
          </MenuItem>
          {data?.filter((ele: any) => ele?.isHidden ? ele?.isHidden?.({ isSizingTypeValid: loanDetails?.sizingType === SizingTypeValue?.QUICK_LOAN_SIZING, prepayMonths: currentObjValue?.prepaymentPenaltyTypeMonths }) : true)?.map((ele: any, idx: number) => {
            return (
              <MenuItem key={idx} value={ele.value} sx={{ fontSize: "12px" }}>
                {t(ele.label)}
              </MenuItem>
            );
          })}
        </Select>
        {currentObjFieldError ? (
          <FormHelperText className="break-word">{t(errorMsg)}</FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}
