import bridgeV1 from "./v1-bridge-09-12-2024";
import dscrV1 from "./v1-dscr-09-12-2024";

type versionListType = {
  bridge: any,
  dscr: any
}

export const latestVersions: any  = { "bridge": "v1-bridge-09-12-2024", "dscr": "v1-dscr-09-12-2024" }

export default {
  bridgeV1,
  dscrV1,
};
