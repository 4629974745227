import React, { useRef, forwardRef } from "react";

import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

import { NumericFormat, NumericFormatProps } from "react-number-format";
import { ObjectType } from "../../types/tableTypes";
import { useTranslation } from "react-i18next";

interface CustomProps {
  onChange: (
    value: string,
    field: any,
    subComponentLabel: string,
    isCurrency: any,
    isPercentage?: boolean
  ) => void;
  field: any;
  subComponentLabel: any;
  isCurrency: any;
  isPercentage: any;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const {
      onChange,
      field,
      subComponentLabel,
      isCurrency,
      isPercentage,
      // isMx100,
      ...other
    } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange(
            values.value,
            field,
            subComponentLabel,
            isCurrency,
            isPercentage
          );
        }}
        fixedDecimalScale={isCurrency || isPercentage}
        thousandSeparator={isCurrency}
        valueIsNumericString
        prefix={isCurrency ? "$" : ""}
        suffix={isPercentage ? "%" : ""}
        decimalScale={isCurrency ? 0 : 4}
        decimalSeparator="."
      />
    );
  }
);

export default function CustomInput({
  type,
  field,
  value,
  onChange,
  subComponentLabel,
  theme,
  component,
  translation,
  loanContextData,
  childIdx,
  unitIdx,
  errorMsg,
  isCurrency,
  isPercentage,
  defaultSelectedDataView,
  loanType,
  isApplicationSubmitted,
}: {
  type: string | undefined;
  field: any;
  value: string;
  onChange: any;
  subComponentLabel: any;
  theme: any;
  component: ObjectType | undefined;
  translation: any;
  loanContextData: ObjectType;
  childIdx?: number | undefined;
  unitIdx?: number;
  errorMsg?: string;
  isCurrency?: boolean;
  isPercentage?: boolean;
  defaultSelectedDataView?: string;
  loanType?: string;
  isApplicationSubmitted?: boolean;
}) {
  const ref = useRef();
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" sx={{ width: "100%" }}>
      <TextField
        data-testid="custom-input-field"
        sx={{ width: "80%" }}
        className={`app-theme-data-points mr-2 truncate-words p-4 br-6 ${!!errorMsg && "app-theme-data-points-error"
          } `}
        type={type}
        variant="standard"
        size="small"
        value={value}
        onChange={(e) => {
          onChange(
            e?.target?.value ?? e,
            field,
            subComponentLabel,
            translation,
            loanContextData,
            component,
            childIdx,
            unitIdx,
            isCurrency,
            isPercentage
          );
        }}
        disabled={
          isApplicationSubmitted ||
          (field?.isAutoCalculated &&
            (field?.isAutoCalculated(loanType, defaultSelectedDataView) ||
              (field?.isDisabled ? true : false)))
        }
        name={field.label}
        id={field.label}
        inputRef={ref}
        InputProps={
          isCurrency || isPercentage
            ? {
              inputComponent: NumericFormatCustom as any,
              inputProps: {
                field,
                subComponentLabel,
                isCurrency,
                isPercentage,
                ref,
              },
              disableUnderline: true,
            }
            : { disableUnderline: true }
        }
      />
      {(value !== "" && 
        (field?.isPopUpMessage || (field?.showPopUpMessage?.(loanContextData?.loanDetails))) 
        ) && <span className="error-text">{t(field?.popUpMessage)}</span>}
      <FormHelperText className="break-word">{errorMsg}</FormHelperText>
    </FormControl>
  );
}
