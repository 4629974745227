import { useState, useEffect, useContext } from "react";
import moment from "moment";
import { t } from "i18next";

import { Button, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PreviewIcon from "@mui/icons-material/Preview";
import { WaiverRoles } from "../../../../../../types/enums";

import CustomChip from "../../../../../../components/common/CustomChip";
import { formatCurrency } from "../../../../../../utils/v2/common";

import FileIcon from "../../../../../../assets/FileIcon.svg";
import ApprovalFlow from "./ApprovalFlow";
import { getCookie } from "../../../../../../utils/Common";
import RejectionFlow from "./RejectionFlow";
import { fetchWaiverRequestDoc, removeRequestWaiver, uploadFileToCloud, uploadWaiverRequestDoc } from "../../../../../../services/network";
import { useParams } from "react-router-dom";
import CustomModal from "../../../../../../components/common/CustomModal";
import ReRequestWaiverConfirmation from "./ReRequestWaiverConfirmation";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { RootContext } from "../../../../../../context/RootContext";
import WaiverList from "./WaiversList";
// import PreviewIcon from "../../../../../../assets/PreviewIcon.svg";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function RequestWaiver(props: any) {
  const {
    setIsRequestPricingWaiver,
    requestedWaivers,
    currentRole,
    fetchWaivers,
    isLoading,
    setIsLoading
  } = props;
  const [isRequestor, setIsRequestor] = useState(false);
  const [showConfirmationPopup, setshowConfirmationPopup] = useState(false);
  const [resetWaiverData, setResetWaiverData] = useState(false);

  useEffect(() => {
    let roles = getCookie("userPermissions") || "[]";
    roles = JSON.parse(roles);
    setIsRequestor(!roles.includes(WaiverRoles?.PRICING_WAIVER_APPROVER));
  }, [currentRole]);

  const handleRequestWaiverClick = () => {
    const isWaiverAlreadyRequested = requestedWaivers.some((waiver: any) => waiver?.data?.waiverStatus === "Requested");
    if (!isWaiverAlreadyRequested) {
      setIsRequestPricingWaiver(true);
    } else {
      setshowConfirmationPopup(true);
    }
  };

  const onConfirmationPopupClose = () => {
    setshowConfirmationPopup(false);
  };

  const confirmReRequestWaiver = async () => {
    setIsLoading(true);
    const waiverIds = requestedWaivers.map((waiver: any) => waiver._id);
    await removeRequestWaiver(waiverIds);
    await fetchWaivers();
    setIsRequestPricingWaiver(true);
    setshowConfirmationPopup(false);
    setIsLoading(false);
    setResetWaiverData(true);
  };


  return (
    <>
      {showConfirmationPopup && (
        <CustomModal
          open={showConfirmationPopup}
          handleClose={onConfirmationPopupClose}
          className={"saveApplication"}
        >
          <ReRequestWaiverConfirmation
            onDeleteAndCreate={confirmReRequestWaiver}
            onClose={onConfirmationPopupClose}
            isLoading={isLoading}
          />
        </CustomModal>
      )}
      <WaiverList
        {...props}
        t={t}
        isRequestor={isRequestor}
        handleRequestWaiverClick={handleRequestWaiverClick}
        resetWaiverData={resetWaiverData}
        setResetWaiverData={setResetWaiverData}
      />
      <></>
    </>
  );
}
