/* eslint-disable react-hooks/exhaustive-deps */
// StickyFooter.tsx
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";

import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { RootContext } from "../../context/RootContext";
import { LoansContext } from "../../context/LoansContext";

import { getTermSheetBasedOnVersion } from "../../services/termSheet";

// Styled container for the footer
const FooterContainer = styled(Box)(({ theme }: { theme: any }) => ({
  position: "sticky",
  bottom: 0,
  width: "auto",
  backgroundColor: theme.palette.background.paper,
  padding: "8px",
  boxShadow: theme.shadows[4],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

const StickyFooter: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const RootContextData = useContext(RootContext);
  const LoansData = useContext(LoansContext);

  const {
    termSheetVersions,
    dynamicConfig,
    setTermSheetAction,
    setCurrentTermSheetVersion,
    termSheetAction,
    currentTermSheetVersion,
    isOpenTermSheet,
    currentPath
  } = RootContextData;
  const { loansApiResponse } = LoansData;

  async function getTermSheetData() {
    try {
      const currentVersion = termSheetVersions[0];
      const isLoanPage = checkLoanPage()
      if(!isLoanPage){
        setIsVisible(false)
        return ;
      } 
      const realmHeaders = {
        "kc-realm": dynamicConfig?.ViteAppKcRealm,
      };
      if (currentVersion && Object.keys(currentVersion) && currentVersion?.termSheetVersion && !isOpenTermSheet && termSheetAction === "") {
        const termSheetData = await getTermSheetBasedOnVersion(realmHeaders, currentVersion?.loanId, +currentVersion?.termSheetVersion);
        const data = termSheetData?.data ?? {}
        const emailSentOn = data?.data?.emailSentOn;
        
        if (emailSentOn) {
          const emailSentDate = new Date(emailSentOn);
          const updatedDate = new Date(loansApiResponse?.updatedAt);
          const isEmailSentBeforeUpdate = emailSentDate < updatedDate;
          const diff = isEmailSentBeforeUpdate && !LoansData.isApplicationSubmitted && isLoanPage;
          setIsVisible(diff);
        }
        setCurrentTermSheetVersion(currentVersion?.termSheetVersion);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const checkLoanPage = () => {
    const pathArray = currentPath.split("/")
    return pathArray.includes("loan")
  }

  const changeTermSheetAction = () => {
    setTermSheetAction("regenerate");
    setIsVisible(false)
  }
  useEffect(() => {
    if (RootContextData?.showTermSheet) {
      getTermSheetData();
    }
  }, [RootContextData?.termSheetVersions, LoansData?.loansApiResponse, RootContextData?.showTermSheet, currentPath]);

  return (
    <FooterContainer sx={isVisible ? { display: "flex" } : { display: "none" }}>
      <Typography variant="body1">
        Loan details updated. To reflect the latest changes in the Term Sheet,
        click “Regenerate” now!
      </Typography>
      <Box>
        <Button variant="contained" color="primary" sx={{ marginRight: 2 }} onClick={() => changeTermSheetAction()}>
          Regenerate
        </Button>
        <Button variant="outlined" color="secondary" onClick={() => setIsVisible(false)}>
          Do it later
        </Button>
      </Box>
    </FooterContainer>
  );
};

export default StickyFooter;
