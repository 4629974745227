import { useState, useEffect, useContext } from "react";

import { RootContext } from "../../../../context/RootContext";

import AccordionNewUI from "../../../../components/common/v2/AccordionNewUI";

import BorrowerInformation from "./BorrowerInformation";

export default function BorrowerDetails(props: any) {
  const {
    fieldConfig,
    isApplicationSubmitted,
    detailedLoanSizingData,
    client,
    handleChange,
    onBlur,
    loanDetails,
    detailedLoanSizingErrors
  } = props;

  function renderBorrowerUI() {
    return (
      <BorrowerInformation
        fieldConfig={fieldConfig["borrowerDetails"]}
        detailedLoanSizingData={detailedLoanSizingData}
        isApplicationSubmitted={isApplicationSubmitted}
        client={client}
        handleChange={handleChange}
        onBlur={onBlur}
        loanDetails={loanDetails}
        detailedLoanSizingErrors={detailedLoanSizingErrors}
      />
    );
  }

  return (
    <div>
      <AccordionNewUI
        label={"borrowerDetails"}
        isApplicationSubmitted={isApplicationSubmitted}
        renderUI={renderBorrowerUI}
      />
    </div>
  );
}
