import GetFieldUI from "../GetFieldUI";

export default function BorrowerInformation(props: any) {
  const {
    fieldConfig,
    isApplicationSubmitted,
    detailedLoanSizingData,
    detailedLoanSizingErrors,
    client,
    handleChange,
    onBlur,
    loanDetails,
  } = props;

  return (
    <div>
      <h4>Borrower Information</h4>
      <GetFieldUI
        formFields={fieldConfig?.["borrowerInformation"]?.fields}
        currentObjValue={
          detailedLoanSizingData?.[
            fieldConfig?.["borrowerInformation"]?.header
          ] ?? {}
        }
        handleChange={handleChange}
        onBlur={onBlur}
        loanDetails={loanDetails}
        sectionName={fieldConfig?.["borrowerInformation"]?.header}
        zipCodeSuggestions={[]}
        currentObjFieldErrors={
          detailedLoanSizingErrors?.[
            fieldConfig?.["borrowerInformation"]?.header
          ] ?? {}
        }
        client={client}
        isApplicationSubmitted={isApplicationSubmitted}
      />
    </div>
  );
}
