export const qucikLoanPIV1 = ["propertyType", "asIsValue", "noOfUnits"];

export const detailedLoanPIV1 = [
  "propertySqftFootage",
  "propertyType",
  "OriginalDateOfAcquisition",
  "recentPropValuationDate",
  "PropertyWithDebt",
  "noOfUnits",
];
