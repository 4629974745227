import {
  isFalsyButNotZero,
  isLetters,
  validateCreditScore,
} from "../../../../utils/Validations";
import getVersionedFields from "..";
import {
  borrowerTypeDropDownValues,
  immigrationStatusDSCRDropDownValues,
} from "../../v1/LoanFieldsData";

const propertiesCompletedWithinLast36Months = {
  id: "propertiesCompletedWithinLast36Months",
  label: "propertiesCompletedWithinLast36Months",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
      ? "enterAnInteger"
      : !value.trim().length || value.includes(".")
      ? "validValue"
      : value < 0
      ? "cannotBeNegative"
      : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: () => true,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

const foreignNational = {
  id: "borrowerGuarantor",
  label: "foreignNational",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "selectValidValue" : "",
  data: [
    { label: "yes", value: "No" },
    { label: "no", value: "Yes" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

const ImmigrationStatus = {
  id: "immigrationStatus",
  label: "immigrationStatus",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "selectValidValue" : "",
  data: immigrationStatusDSCRDropDownValues,
  columnSplit: 6,
  minimumDataView: false,
  isVisible: (data: any) => data?.borrowerGuarantor === "Yes",
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

const originalCreditScore = {
  id: "originalCreditScore",
  label: "originalCreditScore",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
      ? "validValue"
      : value < 0
      ? "cannotBeNegative"
      : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Enter the borrower's credit score.",
  isExpandedView: false,
  isMedian: true,
};

const InState = {
  id: "inState",
  label: "inState",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "Yes", value: "Y" },
    { label: "No", value: "N" },
  ],
  columnSplit: 6,
  minimumDataView: true,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
  info: "inStateInfo",
};

const citizenshipStatus = {
  id: "citizenshipStatus",
  label: "citizenshipStatus",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: immigrationStatusDSCRDropDownValues,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select the borrower's citizenship status (e.g., U.S. citizen, if not U.S. Citizen then Foreign National or permanent resident, non-resident alien)",
};

const creditScoreActual = {
  id: "creditScoreActual",
  label: "creditScoreActual",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) => validateCreditScore(value),
  columnSplit: 6,
  isVisible: () => true,
  isExpandedView: false,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Enter the borrower's actual credit score.",
};

const borrowerType = {
  id: "borrowerType",
  label: "borrowerType",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: borrowerTypeDropDownValues,
  columnSplit: 6,
  minimumDataView: true,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

// isUSCitizen

const isUSCitizen = {
  id: "borrowerGuarantor",
  label: "isUSCitizen",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "selectValidValue" : "",
  data: [
    { label: "yes", value: "No" },
    { label: "no", value: "Yes" },
  ],
  minimumDataView: true,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

const FirstTimeHomeBuyer = {
  id: "firstTimeHomeBuyer",
  label: "firstTimeHomeBuyer",
  required: true,
  type: (loanType: string, dataView: string) => "buttonGroup",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
  columnSplit: 6,
  minimumDataView: true,
  isVisible: (data: any) => data?.borrowerType === "Individual",
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: ""
};

const borrowerFields: any = {
  borrowerType,
  propertiesCompletedWithinLast36Months,
  // foreignNational,
  // ImmigrationStatus,
  originalCreditScore,
  InState,
  citizenshipStatus,
  creditScoreActual,
  isUSCitizen,
  FirstTimeHomeBuyer
};

export const getBorrowerFields = (
  productType: string,
  client: string,
  section: string,
  sizingType?: string
) => {
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  }

  configToBeReturned?.map((field: string) => {
    if (borrowerFields[field]) {
      finalConfigToBeReturned.push(borrowerFields[field]);
    }
  });

  return finalConfigToBeReturned;
};
