import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import KDMCard from './KDMCard'

type KDMSectionPropTypes= {
    sectionData: any
    loanData: any
    config: any
    icons: any
}

const KDMSection = (props: KDMSectionPropTypes) => {
    const  { sectionData, loanData, config, icons } = props
    const { sectionLabel, sectionId, fields } = sectionData;

  return (
    <Box key={sectionId}>
        <Grid container xs={12} style={{marginTop: "20px", height: "42px", background: "#F5F2FC"}}>
            <Typography sx={{fontSize: "18px", fontWeight: "bold", padding: "8px 12px"}}>{sectionLabel}</Typography>
        </Grid>
        <Grid container xs={12} sx={{width: "100%", overflow: "hidden", margin: "12px 0px"}}>
            {fields.map((item: any) => {
                const {
                    fieldId,
                    fieldLabel,
                    getValue,
                    fieldType,
                    popOverData,
                    redirectionType
                  } = item;
                  const value = getValue(loanData);
                  if((fieldId === "evaluationId" && loanData?.loanDetails?.tcLoanId) 
                        || (fieldId === "loanId" && value === '--')) {
                            return ;
                  } else {
                    return (<Grid item xs={3} style={{padding: "6px 12px"}}>
                            <KDMCard 
                                label={fieldLabel}
                                redirectionType={redirectionType}
                                value={config?.formatValueByType(value, fieldType)}
                                key={`${fieldId}-field`}
                                popOverData={popOverData ? popOverData(loanData) : null}
                                icons={icons}
                                openGoogleMap={config?.openGoogleMap}
                            />
                        </Grid>)
                  }
            })}
        </Grid>
    </Box>
  )
}

export default KDMSection
