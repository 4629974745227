import { useTranslation } from "react-i18next";

import { Stack } from "@mui/system";

import CustomChip from "../../../../components/common/CustomChip";
import Disclaimer from "../../../../components/common/Disclaimer";

import WarningIcon from "../../../../assets/WarningIcon.svg";

export default function Banners(props: any) {
  const {
    expandAvailableProgramsView,
    expandLeverageAndTests,
    showLoanDetails,
    applicationName,
    evaluationID,
    chipsData,
    noAssignedPartner,
    disclaimer,
    showBanners,
  } = props;

  const { t } = useTranslation();

  return (
    <div>
      <div style={{ padding: "16px" }}>
        {noAssignedPartner && !expandAvailableProgramsView && showBanners ? (
          <Disclaimer
            boldText={t("")}
            children={
              <span className="warning-div">
                <img src={WarningIcon} alt="warning,svg" />
                &ensp;
                {t(noAssignedPartner)}
              </span>
            }
            customClass="warningMessage"
            showClose={false}
          />
        ) : null}
        {!(expandLeverageAndTests && expandAvailableProgramsView) &&
          showBanners && (
            <Disclaimer
              boldText={t("disclaimer")}
              children={disclaimer}
              customClass="disclaimerBox"
              showClose={true}
            />
          )}
        {!(expandLeverageAndTests && expandAvailableProgramsView) && (
          <>
            {showLoanDetails ? (
              <div className="div-space-between">
                <div>
                  <span>
                    <b>{t("applicationName")}: </b>
                    {applicationName}
                  </span>
                </div>
                <div>
                  <span>
                    <b>
                      {t("evaluationID")}: {evaluationID}
                    </b>
                  </span>
                </div>
              </div>
            ) : null}
          </>
        )}
        <div>
          <Stack
            direction="row"
            className="mt-12"
            style={{ flexWrap: expandLeverageAndTests ? "wrap" : "nowrap" }}
          >
            {chipsData?.map((chip: string, idx: number) => {
              return (
                <CustomChip
                  key={idx}
                  size="small"
                  label={chip}
                  variant="outlined"
                  className={
                    chip?.includes("Price Exception: Requested")
                      ? "requested-chip"
                      : chip?.includes("Price Exception: Rejected")
                      ? "rejected-chip"
                      : "chipData"
                  }
                />
              );
            })}
          </Stack>
        </div>
      </div>
      <div
        data-testid="divider"
        style={{ borderBottom: "1px solid #D3D3D3", marginTop: "4px" }}
      ></div>
    </div>
  );
}
