import { Grid, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { loanDataBasedOnLoanId } from "../../services/network";
import { RootContext } from "../../context/RootContext";
import { LoansContext } from "../../context/LoansContext";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetterOfEachWord, getCookie, sanitizeCurrency } from "../../utils/Common";
import { formatDateForPDFName, getSuggestedLoanTerms, getTermSheetData } from "./Common";
import { downloadTermsheetPdf, getFieldConfigBasedOnOriginator, getTermSheetBasedOnVersion } from "../../services/termSheet";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { TermSheetV2 } from "@toorak/op-web-common-service";

import CloseIcon from "../../assets/CloseIcon.svg";
import { useStyles } from "./termSheet.style";

const TermSheetCell = ({ label, rowData }: { label: any, rowData: any }) => {
  const { loanType, loanId, termSheetVersion } = rowData;
  const { t } = useTranslation();
  const accessToken = getCookie("sizer_access_token") || "";
  const decodedData: any = jwt_decode(accessToken);
  let originatorData: any =
    getCookie("originatorData") ||
    localStorage?.getItem("originatorData") ||
    "{}";
  originatorData = JSON.parse(originatorData);
  const classes = useStyles();
  const [emailData, setEmailData] = useState<any>(null);
  const [defaultFeeDetails, setDefaultFeeDetails] = useState<any>([]);
  const [signatureData, setSignatureData] = useState<any>(null);

  const permissionsToRenderInUI = {
    showLoanDetails: true,
    showFeeDetails: true,
    showEmailSection: true,
    showEmailBody: true,
    isFullView: true,
    showMerchantsMortgage: false,
    showRequirements: true
  };

  const loanContextData = useContext(LoansContext);
  const {
    dynamicConfig,
    termSheetData,
    setTermSheetData,
    termSheetConfig,
    setTermSheetConfig,
    termSheetAction,
    setTermSheetAction,
    currentTermSheetVersion,
    setCurrentTermSheetVersion,
    isOpenTermSheet,
    setIsOpenTermSheet
  } = useContext(RootContext);
  const { setLoanDetails, setPropertyDetails, setFetchData } = loanContextData;

  const handleView = async () => {
    const headers = {
      "kc-realm": dynamicConfig?.ViteAppKcRealm,
    };
    const loanData = await fetchLoanDataBasedOnLoanId();
    setCurrentTermSheetVersion(termSheetVersion);
    const orgId = getCookie("orgId");
    const { data } = await getFieldConfigBasedOnOriginator(orgId, headers);
    const originatorLogo = (data?.originatorLogo);
    if (termSheetVersion) {
      const response = await fetchGetTermSheetBasedOnVersion(
        termSheetVersion
      );
      const data = getTermSheetData(
        loanType,
        loanData,
        t,
        "view-from-list",
        response,
        loanContextData?.defaultSelectedDataView,
        rowData?.evaluationId,
        loanId,
        {}
      );
      const newSuggestedLoanTerms = getSuggestedLoanTerms(
        loanType,
        loanData,
        t,
        response
      );
      const newRequirements = response?.requirements;
      const newFeeDetails =
        response?.feeDetails ||
        JSON.parse(JSON.stringify(termSheetData?.feeDetails));
      const ccEmails = response?.email?.cc;
      const toEmails = response?.email?.to;
      const emailSentOn = response?.emailSentOn;
      const date = response?.email?.date ? moment(new Date(response?.email?.date)).format("MM/DD/YYYY") 
      : response.updatedAt ? moment(new Date(response.updatedAt)).format("MM/DD/YYYY") : ""
      setSignatureData(response?.signatureInfo ?? { name: decodedData?.name, email: decodedData?.email, accountName: originatorData?.accountName });
      setTermSheetData({
        emailSentOn,
        loanData: data,
        suggestedLoanTerms: newSuggestedLoanTerms,
        originatorLogo,
        requirements: newRequirements,
        feeDetails: newFeeDetails,
        emails: { 
          fromEmail: [decodedData?.email], 
          toEmails, 
          ccEmails,
          subject: response?.email?.subject,
        },
        date,
        emailBody : response?.email?.content,
        currentTermSheetVersion: termSheetVersion,
        evaluationID: rowData?.evaluationId,
        signatureData: { name: decodedData?.name, email: decodedData?.email, accountName: originatorData?.accountName },
        propertyAddressDetails : response?.propertyAddressDetails  && Object.keys(response?.propertyAddressDetails)?.map((key:any) => {
          return {
            label: key,
            value: response?.propertyAddressDetails[key]
          }
        })
      });
      updateTermSheetConfig(response?.signatureInfo, originatorLogo);
    }
  };

  const fetchLoanDataBasedOnLoanId = async () => {
    const body = [loanId];
    const loanDataResponse = await loanDataBasedOnLoanId(body);
    const loanData = loanDataResponse?.data?.data[0]?.data;
    setFetchData(loanData);
    setPropertyDetails(loanData?.propertyDetails);
    setLoanDetails(loanData);
    return {
      loanDetails: loanData,
      propertyDetails: loanData?.propertyDetails,
      fetchData: loanData
    };
  };

  // get term sheet data based on version
  const fetchGetTermSheetBasedOnVersion = async (
    termSheetVersion = currentTermSheetVersion
  ) => {
    try {
      const realmHeaders = {
        "kc-realm": dynamicConfig?.ViteAppKcRealm
      };
      const response = await getTermSheetBasedOnVersion(
        realmHeaders,
        loanId,
        +termSheetVersion
      );
      return response?.data?.data;
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    setTermSheetData(null);
    setIsOpenTermSheet(false);
    setTermSheetAction("");
  };

  const handleEmailChange = (toEmails: any, ccEmails: any, tempTermSheetData: any) => {
    const emails = {
      ...tempTermSheetData.emails,
      toEmails: [...toEmails],
      ccEmails: [...ccEmails]
    };
    setEmailData(emails);
    setTermSheetData({
      ...tempTermSheetData,
      emails: { ...tempTermSheetData?.emails, toEmails, ccEmails }
    });
  };

  const handleFeeChange = (e: any, key: any, feeDetailsData: any) => {
    const tempFeeDetails = {
      ...JSON.parse(JSON.stringify(feeDetailsData)),
      [key]: `${Number(e.target.value)}`
    };
    setTermSheetData({ ...termSheetData, feeDetails: tempFeeDetails });
  };

  const handleFeeBlur = (e: any, key: any, feeDetailsData: any) => {
    const tempFeeDetails = {
      ...JSON.parse(JSON.stringify(feeDetailsData)),
      [key]: `${Number(e).toFixed(2)}`
    };
    setTermSheetData({ ...termSheetData, feeDetails: tempFeeDetails });
  };

  // add new custom fee
  const handleAddNewFeeConfig = (addedFees: any, newFees: any, feeDetailsData: any) => {
    const tempFeeDetails = { ...JSON.parse(JSON.stringify(feeDetailsData)) };
    setDefaultFeeDetails([...addedFees]);
    setTermSheetData({
      ...termSheetData,
      feeDetails: { ...newFees, ...tempFeeDetails }
    });
  };

  // delete fee
  const handleDeleteFee = (key: any, feeDetailsData: any) => {
    const newFeeDetails = { ...JSON.parse(JSON.stringify(feeDetailsData)) };
    delete newFeeDetails[key];
    setTermSheetData({ ...termSheetData, feeDetails: newFeeDetails });
  };

  const cleanedAdressString = () => {
    const propertyLocation =
      loanContextData?.propertyDetails[0]?.propertyLocation;
    if (!propertyLocation) {
      return "";
    }
    const pattern = new RegExp(
      `\\b(${propertyLocation.postalCode}|${propertyLocation.city}|${propertyLocation.state})\\b`,
      "g"
    );

    // Remove matches from the address
    const cleanedAddress = propertyLocation?.address?.replace(pattern, "")
      .trim()
      .replace(/\s+/g, " ");
    const address = propertyLocation?.address
      ? `${cleanedAddress}, ${propertyLocation?.city}, ${propertyLocation?.state} ${propertyLocation?.postalCode}`
      : propertyLocation?.postalCode ? `${propertyLocation?.city}, ${propertyLocation?.state} ${propertyLocation?.postalCode}` :"";
    return address;
  };

  const getEmailPayload = (tempTermSheetData: any) => {
    const loanDataFields: any = [];
      const suggestedDataFields: any = [];
      tempTermSheetData?.loanData?.map((loanInfo: any) => {
        Object.keys(loanInfo?.[0] ?? {})?.map((key: any) => {
          loanDataFields.push({
            label: key,
            value: loanInfo?.[0]?.[key],
          });
        });
      });
  
      tempTermSheetData?.suggestedLoanTerms?.map((loanInfo: any) => {
        Object.keys(loanInfo?.[0] ?? {})?.map((key: any) => {
          suggestedDataFields.push({
            label: key,
            value: loanInfo?.[0]?.[key],
          });
        });
      });

      const payload: any = {
        templateData: {
          subject: tempTermSheetData?.emails?.subject ?? termSheetConfig?.email?.subject,
          originatorLogo: tempTermSheetData?.originatorLogo,
          emailBody: tempTermSheetData?.emailBody,
          loanType: ["InvestorDSCR", "Investor DSCR"]?.includes(loanType) ? "dscr" : "bridge",
          totalPoints: loanContextData?.loanDetails?.loanEconomics?.totalOrgAndDiscountPoints ?? null,
          loanDetails: {
            dynamicFields: loanDataFields
          },
          suggestedLoanTerms: {
            dynamicFields: suggestedDataFields
          },
          propertyAddressDetails : tempTermSheetData?.propertyAddressDetails,
          feeDetails: {
            dynamicFields: Object.keys(tempTermSheetData?.feeDetails)?.map((fee: any) => {
              return {
                label: fee,
                value: sanitizeCurrency(tempTermSheetData?.feeDetails?.[fee])
              }
            })
          },
          requirements: tempTermSheetData?.requirements?.filter((r: string) => r !== "") || [],
          viewLoan: window.location.href,
          originator: {
            fullName: tempTermSheetData?.signatureData?.name || "",
            email: tempTermSheetData?.signatureData?.email || "",
            originatorName: tempTermSheetData?.signatureData?.accountName || "",
            phone: tempTermSheetData?.signatureData?.phone || "",
          }
        },
        sendTo: tempTermSheetData?.emails?.toEmails?.length ? tempTermSheetData?.emails?.toEmails : [],
        sendCc: tempTermSheetData?.emails?.ccEmails?.length ? tempTermSheetData?.emails?.ccEmails : [],
        sender: termSheetConfig?.email?.fromEmail
      }
      return {...payload}
  }

  const handleDownloadPDF = async ( tempTermsheetData: any) => {
    const headers = {
      "kc-realm": dynamicConfig?.ViteAppKcRealm,
    };
    const payload = await getEmailPayload(tempTermsheetData)
    payload.templateData.templateName = "los_generic_term_sheet.ftl";
    payload.templateData.fileName = `TermSheet_${rowData?.evaluationId}_${formatDateForPDFName(new Date())}`
    payload.templateData.originatorLogoBgColor = ""
    payload.templateData.originatorLogo = tempTermsheetData?.originatorLogo
    const pdf = await downloadTermsheetPdf({...payload?.templateData}, headers)
    
      const link = document.createElement('a');
      link.href = pdf.data;
      link.target="_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  };

  const updateTermSheetConfig = (signatureInfo: any, originatorLogo: any) => {
    const accessToken = getCookie("sizer_access_token") || "";
    const decodedData: any = jwt_decode(accessToken);
    const date = moment(new Date()).format("MM/DD/YYYY");
    const address = cleanedAdressString();
    const termSheetName = rowData?.termSheetName ? rowData?.termSheetName : `${t("termSheetKeyForHeader")} v ${termSheetVersion ?? ""}`

    const newData = {
      isViewMode: !["regenerate", "generate"].includes(termSheetAction),
      termSheetAction: 'view-from-list',
      originatorLogo,
      header: {
        showOriginatorInfo: true,
        originatorInfo: `${originatorData?.accountName} - ID: ${rowData?.evaluationId} (${termSheetName})`, // "ABC Capital - ID: 26356 (TermSheet v0.1)",
        className: classes?.termSheetHeader,
        closeIcon: CloseIcon
      },
      email: {
        subject: `Loan Proposal for ${address}`,
        date,
        fromEmail: decodedData?.email,
        handleEmailChange
      },
      feeDetails: {
        config: defaultFeeDetails,
        handleFeeChange,
        handleFeeBlur,
        handleDeleteFee,
        handleAddNewFeeConfig
      },
      footer: {
        onCloseModal: setIsOpenTermSheet,
        onSendEmail: () => { },
        name: decodedData?.name,
        handleDownloadPDF
      },
      signatureInfo: signatureData ?? signatureInfo,
      capitalizeFirstLetterOfEachWord
    };
    setTermSheetConfig(newData);
    setIsOpenTermSheet(true);
    setTermSheetAction("view-from-list");
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={10} style={{ overflow: "hidden" }}>
          <span
            className="text-truncate"
            title={label}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              textWrap: "wrap"
            }}
          >
            {rowData?.termSheetName ? rowData?.termSheetName : label}
          </span>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            aria-label="redirect"
            sx={{ color: "#362866" }}
            onClick={handleView}
          >
            <OpenInNewIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </Grid>
      </Grid>
      {isOpenTermSheet ? (
        <TermSheetV2
          isOpenTermSheet={isOpenTermSheet}
          termSheetData={termSheetData}
          setTermSheetData={setTermSheetData}
          isUserConfig={false}
          permissionsToRenderInUI={permissionsToRenderInUI}
          // setIsOpenTermSheet={setIsOpenTermSheet}
          onClose={handleClose}
          termSheetConfig={termSheetConfig}
          // termSheetAction={termSheetAction}
          // isViewMode={true}
          sanitizeCurrency={sanitizeCurrency}
          isTermsheetAlreadySaved={false}
          emailBodyChange={() => {}}
        />
      ) : null}
    </>
  );
};

export default TermSheetCell;
