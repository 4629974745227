import getVersionedFields from "..";
import { isFalsyButNotZero, isLetters } from "../../../../utils/Validations";

const OriginalAsIsLtv = {
  id: "originalAsIsLtv",
  label: "originalAsIsLtv",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: () => true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
      ? "percentageValueError"
      : "",
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  fieldInfo: "",
};

const OriginalLtcLtp = {
  id: "originalLtcLtp",
  label: "originalLtcLtp",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  minimumDataView: true,
  columnSplit: 6,
  isVisible: () => true,
  isPercentage: true,
  getFieldErrors: (value: string) =>
    !isFalsyButNotZero(value)
      ? isLetters(value)
        ? "validValue"
        : "thisFieldIsRequired"
      : +value < 0 || +value > 100
      ? "percentageValueError"
      : "",
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  fieldInfo: "",
};

const ESRIPopulationDensity = {
  id: "eSRIPopulationDensity",
  label: "eSRIPopulationDensity",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
      ? "validValue"
      : value < 0
      ? "cannotBeNegative"
      : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  fieldInfo: "",
};

const ZillowCBSA = {
  id: "zillowCBSA",
  label: "zillowCBSA",
  required: false,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  fieldInfo: "",
};

const AdjustedAsIsValue = {
  id: "adjustedAsIsValue",
  label: "adjustedAsIsValue",
  required: false,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : typeof value === "string" &&
        (!value?.trim().length || value?.includes("."))
      ? "validValue"
      : value < 0
      ? "cannotBeNegative"
      : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => true,
  fieldInfo: "",
};

export const PropertyLoanFeaturesFields: any = {
  ESRIPopulationDensity,
  ZillowCBSA,
  AdjustedAsIsValue,
};

export const LoanFeatureFields: any = {
  OriginalAsIsLtv,
  OriginalLtcLtp,
};

export const getLoanFeaturesFields = (
  productType: string,
  client: string,
  section: string,
  sizingType?: string,
  isPropLevel?: boolean
) => {
  const fieldsToRender = isPropLevel
    ? PropertyLoanFeaturesFields
    : LoanFeatureFields;
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  }

  configToBeReturned?.map((field: string) => {
    if (fieldsToRender[field]) {
      finalConfigToBeReturned.push(fieldsToRender[field]);
    }
  });

  return finalConfigToBeReturned;
};
