import moment from "moment";
import {
  evaluatePartnerGuidelines,
  getTemplateResponse,
  getTemplateResponseDSCR,
} from "../../services/network";
import { ProductType, SizingTypeValue } from "../../types/enums";
import {
  evaluation,
  getCookie,
  getFormattedDate,
  normalizeEvaluationResult,
  // getTemplateResponseDSCR,
  // getTemplateResponse,
} from "../Common"; // Example utilities
import { sampleCondoEligibility } from "../../pages/Loans/v1/Common";
import { intialDetailedLoanSizingState } from "../../context/LoansContext";

function hasOnlySpaces(input: string) {
  return /^\s*$/.test(input);
}

const getFlastRequestBodyForGuidelines = (props: any) => {};

const getRequestBodyForGuidelines = (
  loanDetails: any,
  quickLoanSizingData: any,
  detailedLoanSizingData: any,
  loanId: any,
  dynamicConfig: any,
  sizingType: string,
  isFlat?: boolean,
  isInitialLoad?: boolean
) => {
  let loanObj = {};
  let propObj: any = [];
  let experience: any = null;
  if (isFlat) {
    const data = getFlastRequestBodyForGuidelines({
      loanDetails,
      quickLoanSizingData,
      detailedLoanSizingData,
    });
    return data;
  }
  let foreignNational =
    detailedLoanSizingData?.borrowerInformation?.immigrationStatus;

  switch (loanDetails?.sizingType || sizingType) {
    case SizingTypeValue.QUICK_LOAN_SIZING:
      experience =
        quickLoanSizingData?.borrowerInformation?.experience ||
        quickLoanSizingData?.borrowerInformation
          ?.propertiesCompletedWithinLast36Months ||
        quickLoanSizingData?.borrowerInformation?.borrowerGUCExperience;
      if (
        hasOnlySpaces(experience) ||
        experience?.includes(".") ||
        !experience
      ) {
        experience = null;
      }

      if (
        quickLoanSizingData?.borrowerInformation?.citizenshipStatus?.includes(
          "Foriegn"
        ) ||
        quickLoanSizingData?.borrowerInformation?.citizenshipStatus === ""
      ) {
        foreignNational = "Yes";
      } else {
        foreignNational =
          quickLoanSizingData?.borrowerInformation?.citizenshipStatus;
      }
      // } else if (
      //   quickLoanSizingData?.borrowerInformation?.borrowerGuarantor === "No"
      // ) {
      //   foreignNational = "No";
      // }

      loanObj = {
        loanInfo: {
          primaryLoanId: quickLoanSizingData?.loanInformation?.originatorLoanID,
          // loanPurpose: quickLoanSizingData?.loanInformation?.loanPurpose ?? "",
          originalMaturityDate:
            quickLoanSizingData?.loanInformation?.originalMaturityDate || null,
          crossCollaterlization:
            quickLoanSizingData?.loanInformation?.crossCollaterlization || "N",
          toorakProduct:
            quickLoanSizingData?.loanInformation?.toorakProduct || "Rehab",
          loanType:
            loanDetails?.productType === ProductType?.DSCR
              ? "30Year"
              : "Bridge",
          product: quickLoanSizingData?.loanInformation?.product || "RENTAL",
          loanStage: "FES",
          loanId,
          condoEligibility:
            quickLoanSizingData?.loanInformation?.condoEligibility || "N/A",
          cashOutFlag: quickLoanSizingData?.loanInformation?.cashOutFlag || "",
          borrowerProceeds:
            quickLoanSizingData?.loanInformation?.borrowerProceeds ||
            quickLoanSizingData?.loanInformation?.cashOutRefinance ||
            "Y",
          predominantPropertyType:
            quickLoanSizingData?.loanInformation?.predominantPropertyType || "",
          predominantState:
            quickLoanSizingData?.loanInformation?.predominantState || "",
          exitStrategy:
            quickLoanSizingData?.loanInformation?.exitStrategy || "",
          loanTerm:
            quickLoanSizingData?.loanInformation?.loanTerm?.toString() || "360",
          expectedClosingDate:
            quickLoanSizingData?.loanInformation?.expectedClosingDate || "",
          creditEvent:
            quickLoanSizingData?.loanInformation?.creditEvent || "No",
        },
        loanEcon: {
          originalLoanAmount:
            quickLoanSizingData?.loanInformation?.borrowerRequested ||
            quickLoanSizingData?.loanInformation?.loanAmount ||
            quickLoanSizingData?.loanInformation?.borrowerRequested ||
            "",
          interestRate: null,
          totalOriginationAndDiscountPoints:
            quickLoanSizingData?.loanInformation
              ?.totalOriginationAndDiscountPoints || 0.0,
          rateType: quickLoanSizingData?.loanInformation?.rateType || "Fixed",
          prePaymentPenaltyMonths:
            quickLoanSizingData?.loanEconomics?.prepaymentPenaltyTypeMonths ||
            null,
          prepayPenaltyType:
            prePayTypeToMonthsMap?.[
              quickLoanSizingData?.loanEconomics?.prepaymentPenaltyTypeMonths ??
                0
            ] || null,
          interestOnlyPeriod: quickLoanSizingData?.loanEconomics?.ioPeriod ?? 0,
          armIndex: quickLoanSizingData?.loanInformation?.armIndex ?? "0",
          grossMarginForArm:
            quickLoanSizingData?.loanInformation?.grossMarginForArm ?? 0.0,
          includeOutOfPocketBudgetInARLTV:
            quickLoanSizingData?.loanInformation
              ?.includeOutOfPocketBudgetInARLTV ?? true,
          // monthlyPropertyGrossRent:
          //   quickLoanSizingData?.propertyEconomics?.monthlyPropertyGrossRent,
        },
        loanDetail: {
          originatorPartyId:
            loanDetails?.orgId || dynamicConfig?.clientId || getCookie("orgId"),
        },
        loanUserMap: [
          {
            isPrimary: true,
            loanUserSequence: "1",
            loanUserType: "Borrower",
            originalCreditScoreMedian:
              quickLoanSizingData?.borrowerInformation?.creditScoreActual ||
              null,
            experience,
            customer: {
              foreignNationalString:
                quickLoanSizingData?.borrowerInformation?.citizenshipStatus,
              partyType:
                quickLoanSizingData?.borrowerInformation?.borrowerType ===
                "Entity"
                  ? "account"
                  : "person",
            },
            heavyRehabExperience: null,
            borrowerGUCExperience: null,
          },
        ],
      };
      propObj = {
        propertyLocation: {
          ...quickLoanSizingData?.propertyLocation,
          city: quickLoanSizingData?.propertyLocation?.city || "",
          state: quickLoanSizingData?.propertyLocation?.state || "",
          postalCode: quickLoanSizingData?.propertyLocation?.postalCode || "",
        },
        propertyInfo: {
          propertyType: quickLoanSizingData?.propertyInformation?.propertyType,
          numberOfUnits:
            quickLoanSizingData?.propertyInformation?.numberOfUnits ?? 1,
        },
        propertyEcon: {
          // ...quickLoanSizingData?.propertyEconomics,
          originalAsIsAppraisalValue:
            quickLoanSizingData?.propertyInformation?.asIsValue,
          purchasePrice: quickLoanSizingData?.loanInformation?.purchasePrice,
          acquisitionPrice: quickLoanSizingData?.loanInformation?.purchasePrice,
          annualPropertyTaxes:
            quickLoanSizingData?.propertyEconomics?.annualPropertyTaxes,
          annualFloodInsurance:
            quickLoanSizingData?.propertyEconomics?.annualFloodInsurance,
          annualHOAFee: quickLoanSizingData?.propertyEconomics?.annualHOAFee,
          insurance: quickLoanSizingData?.propertyEconomics?.annualInsurance,
        },
        propertyId: 1,
        propertySummary: {
          monthlyTaxAmount: null,
          monthlyInsuranceAmount: null,
          escrowTaxes: null,
          escrowInsurance: null,
        },
      };
      break;
    case SizingTypeValue.DETAILED_LOAN_SIZING:
      const detaileLoanDataToBePicked = isInitialLoad
        ? loanDetails
        : detailedLoanSizingData;

      experience =
        detaileLoanDataToBePicked?.borrowerInformation?.experience ||
        detaileLoanDataToBePicked?.borrowerInformation
          ?.propertiesCompletedWithinLast36Months ||
        detaileLoanDataToBePicked?.borrowerInformation?.borrowerGUCExperience;
      if (
        hasOnlySpaces(experience) ||
        experience?.includes(".") ||
        !experience
      ) {
        experience = null;
      }

      foreignNational =
        detaileLoanDataToBePicked?.borrowerInformation?.citizenshipStatus;

      if (
        detaileLoanDataToBePicked?.borrowerInformation?.citizenshipStatus?.includes(
          "Foriegn"
        ) ||
        detaileLoanDataToBePicked?.borrowerInformation?.citizenshipStatus === ""
      ) {
        foreignNational = "Yes";
      } else {
        foreignNational =
          detaileLoanDataToBePicked?.borrowerInformation?.citizenshipStatus;
      }

      loanObj = {
        loanInfo: {
          primaryLoanId:
            detaileLoanDataToBePicked?.loanInformation?.originatorLoanID,
          // loanPurpose:
          //   detaileLoanDataToBePicked?.loanInformation?.loanPurpose ?? "",
          originalMaturityDate:
            getFormattedDate(
              detaileLoanDataToBePicked?.loanInformation?.originalMaturityDate
            ) || null,
          crossCollaterlization:
            detaileLoanDataToBePicked?.loanInformation?.crossCollaterlization ||
            "N",
          toorakProduct:
            detaileLoanDataToBePicked?.loanInformation?.toorakProduct ||
            "Rehab",
          loanType:
            loanDetails?.productType === ProductType?.DSCR
              ? "30YEAR"
              : "Bridge",
          product:
            detaileLoanDataToBePicked?.loanInformation?.product || "RENTAL",
          loanStage: "FES",
          loanId,
          condoEligibility:
            detaileLoanDataToBePicked?.loanInformation?.condoEligibility ||
            "N/A",
          // cashOutFlag:
          //   detaileLoanDataToBePicked?.loanInformation?.cashOutFlag || "",
          borrowerProceeds:
            detaileLoanDataToBePicked?.loanInformation?.borrowerProceeds ||
            detaileLoanDataToBePicked?.loanInformation?.cashOutRefinance ||
            "Y",
          predominantPropertyType:
            detaileLoanDataToBePicked?.loanInformation
              ?.predominantPropertyType || null,
          predominantState:
            detaileLoanDataToBePicked?.loanInformation?.predominantState || "",
          // exitStrategy:
          //   detaileLoanDataToBePicked?.loanInformation?.exitStrategy || "",
          loanTerm:
            detaileLoanDataToBePicked?.loanInformation?.loanTerm?.toString() ||
            "360",
          expectedClosingDate:
            getFormattedDate(
              detaileLoanDataToBePicked?.loanInformation?.expectedClosingDate
            ) || "",
          creditEvent:
            detaileLoanDataToBePicked?.loanInformation?.creditEvent || "No",
        },
        loanEcon: {
          originalLoanAmount:
            detaileLoanDataToBePicked?.loanEconomics?.borrowerRequested ||
            detaileLoanDataToBePicked?.loanEconomics?.loanAmount ||
            "",
          interestRate: null,
          // totalOriginationAndDiscountPoints:
          //   detaileLoanDataToBePicked?.loanEconomics
          //     ?.totalOriginationAndDiscountPoints || 0.0,
          rateType:
            detaileLoanDataToBePicked?.loanEconomics?.rateType || "Fixed",
          prePaymentPenaltyMonths:
            detaileLoanDataToBePicked?.loanEconomics
              ?.prepaymentPenaltyTypeMonths || null,
          prepayPenaltyType:
            detaileLoanDataToBePicked?.loanEconomics?.prepayPenaltyType || null,
          interestOnlyPeriod:
            detaileLoanDataToBePicked?.loanEconomics?.ioPeriod ?? 0,
          // armIndex: detaileLoanDataToBePicked?.loanEconomics?.armIndex ?? "0",
          // grossMarginForArm:
          //   detaileLoanDataToBePicked?.loanEconomics?.grossMarginForArm ?? 0.0,
          // includeOutOfPocketBudgetInARLTV:
          //   detaileLoanDataToBePicked?.loanEconomics
          //     ?.includeOutOfPocketBudgetInARLTV ?? true,
        },
        loanDetail: {
          originatorPartyId:
            loanDetails?.orgId || dynamicConfig?.clientId || getCookie("orgId"),
        },
        loanUserMap: [
          {
            isPrimary: true,
            loanUserSequence: "1",
            loanUserType: "Borrower",
            originalCreditScoreMedian:
              detaileLoanDataToBePicked?.borrowerInformation
                ?.creditScoreActual || null,
            experience,
            customer: {
              foreignNationalString: foreignNational,
              partyType:
                detaileLoanDataToBePicked?.borrowerInformation?.borrowerType ===
                "Entity"
                  ? "account"
                  : "person",
            },
            firstTimeHomeBuyer: detaileLoanDataToBePicked?.borrowerInformation?.firstTimeHomeBuyer ?? null
            // heavyRehabExperience: null,
            // borrowerGUCExperience: null,
          },
        ],
      };
      detaileLoanDataToBePicked?.propertyDetails?.map(
        (property: any, pId: number) => {
          const {
            propertyInformation,
            propertyLocation,
            unitInformation,
            propertyEconomics,
          } = property;
          let closingCost = "0";
          if (propertyEconomics?.closingCosts) {
            closingCost = (
              +propertyEconomics?.closingCosts?.split("%")[0] / 100
            ).toFixed(4);
          }

          const returnObj = {
            propertyId: pId + 1,
            propertySummary: {},
            propertyInfo: {
              propertyType: propertyInformation?.propertyType || "",
              numberOfUnits: propertyInformation?.numberOfUnits
                ? hasOnlySpaces(propertyInformation?.numberOfUnits) ||
                  propertyInformation?.numberOfUnits?.includes(".")
                  ? 0
                  : propertyInformation?.numberOfUnits
                  ? +propertyInformation?.numberOfUnits
                  : 1
                : 1,
              mostRecentPropertyValuationDate:
                getFormattedDate(
                  propertyInformation?.mostRecentPropertyValuationDate
                ) || null,
              propertyAcquisitionDate:
                getFormattedDate(
                  propertyInformation?.originalDateOfAcquisition
                ) || null,
              squareFootage:
                propertyInformation?.propertySqftFootage?.replaceAll(",", ""),
              preRehabSquareFootage:
                propertyInformation?.propertySqftFootage?.replaceAll(",", ""),
              postRehabSquareFootage: 0.0,
              isPurchasedWithDebt: propertyInformation?.propertyWithDebt,
            },
            propertyEcon: {
              originalAsIsAppraisalValue: propertyEconomics?.asIsValue || null,
              purchasePrice:
                propertyEconomics?.propertyAcquisitionPrice ||
                propertyEconomics?.purchasePrice ||
                null,
              acquisitionPrice:
                propertyEconomics?.propertyAcquisitionPrice ||
                propertyEconomics?.purchasePrice ||
                null,
              costBasisAfterCostIncurred:
                (+closingCost + 1) * propertyEconomics?.purchasePrice,
              annualPropertyTaxes:
                propertyEconomics?.annualPropertyTaxes ?? null,
              annualHazardInsurance:
                propertyEconomics?.annualHazardInsurance ?? null,

              // insurance: propertyEconomics?.annualInsurance ?? null,
              // marketRentPerMonth:
              //   propertyEconomics?.grossPotentialRentMonthly ?? null,
              annualHOAFee: propertyEconomics?.annualHOAFee ?? null,
              annualFloodInsurance:
                propertyEconomics?.annualFloodInsurance ?? null,

              thirdPartyValuation:
                propertyEconomics?.thirdPartyValuation ?? null,
              thirdPartyValuationStatus:
                propertyEconomics?.thirdPartyValuationStatus ?? null,
              closingCost,
              additionalEligibleCost: null,
              decliningMarkets: propertyEconomics?.decliningMarkets ?? null,
            },
            propertyLocation: {
              city: propertyLocation?.city || "",
              state: propertyLocation?.state || "",
              postalCode: propertyLocation?.postalCode || "",
            },
            units: unitInformation?.map((unit: any, uId: number) => {
              return {
                propertyUnitId: uId + 1,
                currentLeaseTermMonths: +unit?.currentLeaseTermsInMonths || "",
                leaseStatus: unit?.rentalCharacterization || "",
                unitOccupancy: unit?.rentalCharacterization?.includes(
                  "Occupied"
                )
                  ? 1
                  : 0,
                marketRentMonthly: unit?.monthlyMarketRent ?? null,
                inPlaceLeaseRentMonthly: unit?.inPlaceLeaseRentMonthly,
              };
            }),
          };
          propObj.push(returnObj);
        }
      );
      break;
    default:
      break;
  }

  const resultObj: any = {
    loanResult: {
      [loanId]: {},
    },
    propertiesResults: {},
  };

  const requestBody: any = {
    rulesRequest: {
      ruleAttributs: {
        ruleField: [],
        ruleGroup: [],
        ruleResult: [],
      },
      loanFact: {
        loan: loanObj,
        properties: propObj,
      },
      resultObject: {
        loanResult: {
          [loanId]: {},
        },
      },
    },
    attributes: ["criticalTestLoanEligibility"],
  };
  if (
    quickLoanSizingData?.loanInformation?.loanPurpose &&
    (loanDetails?.sizingType || sizingType) ===
      SizingTypeValue.QUICK_LOAN_SIZING
  ) {
    resultObj.loanResult[loanId] = {
      ...resultObj.loanResult[loanId],
      loanCharacterization: {
        finalLoanCharacterization:
          quickLoanSizingData?.loanInformation?.loanPurpose ?? null,
      },
    };
  }
  if (
    quickLoanSizingData?.propertyEconomics?.monthlyPropertyGrossRent &&
    loanDetails?.sizingType === SizingTypeValue.QUICK_LOAN_SIZING
  ) {
    resultObj.propertiesResults = {
      ...resultObj.propertiesResults,
      "1": {
        loanEconomics: {
          monthlyPropertyGrossRent:
            quickLoanSizingData?.propertyEconomics?.monthlyPropertyGrossRent,
        },
      },
    };
  }
  if (Object.keys(resultObj.loanResult[loanId]).length) {
    requestBody.rulesRequest.resultObject = {
      ...requestBody.rulesRequest.resultObject,
      ...resultObj,
    };
  }
  return requestBody;
};

export const fetchGuidelinesAndBuyDownOptions = async (props: any) => {
  const {
    loanDetails,
    quickLoanSizingData,
    detailedLoanSizingData,
    loanId,
    dynamicConfig,
    selectedSizingType,
    isInitialLoad,
  } = props;
  try {
    const newReqBodyForGuidelines = getRequestBodyForGuidelines(
      loanDetails,
      quickLoanSizingData,
      detailedLoanSizingData,
      loanId,
      dynamicConfig,
      selectedSizingType,
      false,
      isInitialLoad
    );
    const returnData = await evaluatePartnerGuidelines(newReqBodyForGuidelines);
    return returnData?.data ?? {};
  } catch (error) {
    console.error(
      "Something went wrong while fetching guidelines and buy down options",
      error
    );
  }
};

type ObjectType = Record<string, any>;

export const getTestTabResults = async (props: any) => {
  try {
    const {
      tempCriticalTests,
      RootContextData,
      loanContextData,
      templateResponseData,
      isDSCR,
      loanData,
      tempLoanResult,
      loanTypeStr,
      partnerId,
      result,
      loanId,
    } = props;
    const { DSCROrgPartnerMapping, dscrPartner, dynamicConfig } =
      RootContextData;
    const {
      setLeverageAndPricingData,
      setTestTabData,
      setFailedFinalRate,
      loanDetails,
      chipsData,
      setChipsData,
      setTemplateResponseData,
      setQuickLoanSizingData,
    } = loanContextData;

    const testKey = ["logicTests", "finalCalculation", "maxMinParameters"];
    const criticalTestData = { ...(tempCriticalTests ?? {}) };
    let templateResponse = criticalTestData || {};

    try {
      const failTests: ObjectType[] = [];
      const passTests: ObjectType[] = [];
      let failedCountTests: any = 0;

      if (isDSCR) {
        await handleDSCRTests();
      } else {
        await handleNonDSCRTests();
      }

      const normalizedResult: any = normalizeEvaluationResult(
        tempLoanResult,
        loanTypeStr,
        loanData,
        {},
        // fetchData,
        failedCountTests
      );

      if (
        !templateResponseData ||
        Object.keys(templateResponseData).length === 0
      ) {
        setTemplateResponseData(templateResponse);
      }

      setLeverageAndPricingData(normalizedResult || {});
      setTestTabData(failTests);
      setFailedFinalRate(
        !(
          normalizedResult?.leverageCards?.finalRate ||
          normalizedResult?.leverageCards?.finalPrice
        )
      );

      return failTests;

      // Helper Functions
      async function handleDSCRTests() {
        const tempChipData: string[] = [...chipsData];
        const takeOutPartnerId = partnerId || loanDetails?.takeOutPartner || "";

        updateChipsData(tempChipData, takeOutPartnerId);

        for (const partner of dscrPartner || []) {
          if (result[partner.id]) {
            await processPartnerData(partner.id);
          }
        }

        if (partnerId) {
          failedCountTests += countFailedTests(result[partnerId]?.loanResult);
        }
      }

      async function handleNonDSCRTests() {
        const loanKey = Object.keys(result?.loanResult)[0];
        const tests = {
          ...result?.loanResult?.[loanKey]?.guidelineTest,
          ...result?.loanResult?.[loanKey]?.loanEconomics,
        };

        const templateKeys = Object.keys(tests).map(
          (testName) => `testNames.${testName}`
        );
        const reqBody = { templateKeys };
        const partyId = getCookie("partyId") || dynamicConfig?.partyId;

        const publicAPI2Headers = {
          accept: "application/json",
          "kc-realm": dynamicConfig?.ViteAppKcRealm,
        };

        if (!templateResponse || Object.keys(templateResponse).length === 0) {
          templateResponse = await getTemplateResponse(
            partyId,
            reqBody,
            publicAPI2Headers
          );
        }

        processTests(tests, templateResponse?.data);
      }

      function updateChipsData(
        tempChipData: string[],
        takeOutPartnerId: string
      ) {
        const partnerValues = Object.values(DSCROrgPartnerMapping);

        if (
          DSCROrgPartnerMapping[takeOutPartnerId] &&
          !tempChipData.includes(DSCROrgPartnerMapping[takeOutPartnerId])
        ) {
          const existingChip =
            tempChipData.find((chip) => partnerValues.includes(chip)) || "";
          const chipIndex = tempChipData.indexOf(existingChip);

          if (chipIndex >= 0) {
            tempChipData.splice(
              chipIndex,
              1,
              DSCROrgPartnerMapping[takeOutPartnerId]
            );
          } else {
            tempChipData.push(DSCROrgPartnerMapping[takeOutPartnerId]);
          }

          setChipsData(tempChipData);
        }
      }

      async function processPartnerData(partnerIdString: string) {
        const tests = result[partnerIdString]?.loanResult?.[loanId];
        const templateKeys: any = [];
        const testObj: any = {};

        Object.keys(tests).filter((testName) => {
          if (testKey.includes(testName)) {
            testObj[testName] = tests[testName];
            templateKeys.push(
              ...Object.keys(tests[testName])?.map((key) => `testNames.${key}`)
            );
          }
        });

        const reqBody = {
          templateKeys,
        };
        const orgId = getCookie("clientId") || dynamicConfig?.clientId;

        if (
          !templateResponse[partnerIdString] ||
          Object.keys(templateResponse[partnerIdString]).length === 0
        ) {
          templateResponse[partnerIdString] = await getTemplateResponseDSCR(
            partnerIdString,
            orgId,
            reqBody
          );
        }

        processTests(testObj, templateResponse[partnerIdString]?.data);
      }

      function processTests(tests: ObjectType, templateData: ObjectType) {
        Object.entries(tests).forEach(([testName, testStatus]) => {
          if (testStatus === "FAIL") {
            failedCountTests += 1;
            failTests.push({
              title: evaluation(templateData[`testNames.${testName}`], {}, ""),
              status: "Failed",
              subText: evaluation(
                templateData[`loanResult.${testName}.message.FAIL`],
                {},
                ""
              ),
            });
          } else if (testStatus === "PASS") {
            passTests.push({
              title: testName,
              status: "Passed",
            });
          }
        });
      }

      function countFailedTests(loanResult: any) {
        return Object.values(loanResult || {}).reduce((count: any, tests) => {
          return (
            count +
            Object.values(tests || {}).filter((status) => status === "FAIL")
              .length
          );
        }, 0);
      }
    } catch (error) {
      console.error("Error in getTestTabResultData:", error);
    }
  } catch (err) {
    console.error(err);
  }
};

export const convertQuickToDetailedLoanData = (loanData: any) => {
  return {
    borrowerInformation: {
      ...loanData?.borrowerInformation,
    },
    loanInformation: {},
    loanEconomics: {
      ...(loanData?.loanEconomics ?? {}),
      borrowerRequested: loanData?.loanInformation?.borrowerRequested ?? null,
      purchasePrice: loanData?.loanInformation?.purchasePrice ?? null,
      prepayPenaltyType:
        prePayTypeToMonthsMap?.[
          loanData?.loanEconomics?.prepaymentPenaltyTypeMonths ?? 0
        ] ?? null,
    },
    propertyDetails: [
      {
        propertyLocation: loanData?.propertyLocation ?? {},
        propertyInformation: loanData?.propertyInformation ?? {},
        propertyEconomics: {
          ...(loanData?.propertyEconomics ?? {}),
          asIsValue: loanData?.propertyInformation?.asIsValue,
          purchasePrice: loanData?.loanInformation?.purchasePrice,
        },
        unitInformation: [{}],
      },
    ],
  };
};

export const formatCurrency = (
  value: number | string,
  noOfDigits?: number
): string => {
  const numberValue = typeof value === "string" ? value : `${value}`;
  if (isNaN(Number(numberValue))) {
    return numberValue;
  }
  const data = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: noOfDigits ?? 4,
  }).format(Number(numberValue));

  return data;
};

export const prePayTypeToMonthsMap: any = {
  60: "5/5/5/5/5",
  48: "4/3/2/1",
  36: "5/4/3",
  24: "2/1",
  12: "2",
  0: "0",
};

export function clearAllData(loansContext: any) {
  const {
    setChipsData,
    setLoanFormData,
    setLoanDetailsConfig,
    setPropertyDetailsConfig,
    setBorrowerInformationConfig,
    setLoanDetails,
    setPropertyDetails,
    setDefaultSelectedDataView,
    setExpandLeverageAndTests,
    setIsApplicationSubmitted,
    setShowSnackBar,
    setLeverageTestsConfig,
    setLoanFormErrors,
    setPropFormErrors,
    setLeverageAndPricingData,
    setPropertyDetailsTemplate,
    setPropertyDetailsConfigTemplate,
    setUnitInfoConfigTemplate,
    setUnitInfoTemplate,
    setTestTabData,
    setAutoCalculatedLoanResults,
    setAutoCalculatedPropResults,
    setCondoEligibilityConfig,
    setCondoEligibility,
    setCondoEligibilityFormData,
    setAddressInputValue,
    setAddressOptions,
    setZipCodeInputValue,
    setZipCodeOptions,
    setFailedFinalRate,
    setFetchData,
    setRatesAsOfDate,
    setDebouncedValue,
    setNoAssignedPartner,
    setAssignedPartner,
    setIsUnitExpanded,
    setAutoCalculatedFields,
    setExpandAvailableProgramsView,
    setEligiblePartnerResults,
    setPartnersResults,
    setLoansApiResponse,
    setRulesResponse,
    setCriticalTests,
    setTemplateResponseData,
    setView,
    setSizingType,
    setQuickLoanSizingData,
    setDetailedLoanSizingData,
    setFieldConfig,
    setDetailedLoanSizingErrors,
    setNewDebouncedValue,
    setCurrentSelectedBuyDownOption,
    setBuyDownOptions,
    setCurrentSelectedInterestRate,
    setLatestTermSheetVersion,
  } = loansContext;

  setLoanFormData({});
  setAutoCalculatedFields({});
  setLoanDetailsConfig({});
  setPropertyDetailsConfig([]);
  setBorrowerInformationConfig({});
  setLoanDetails({});
  setPropertyDetails([]);
  setDefaultSelectedDataView("");
  setExpandLeverageAndTests(false);
  setExpandAvailableProgramsView(false);
  setIsApplicationSubmitted(false);
  setShowSnackBar(false);
  setLeverageTestsConfig([]);
  setLoanFormErrors({});
  setPropFormErrors([]);
  setLeverageAndPricingData({});
  setPropertyDetailsTemplate({});
  setPropertyDetailsConfigTemplate({});
  setUnitInfoConfigTemplate({});
  setUnitInfoTemplate([]);
  setTestTabData([]);
  setAutoCalculatedLoanResults({});
  setAutoCalculatedPropResults([]);
  setCondoEligibilityConfig({});
  setCondoEligibility(false);
  setCondoEligibilityFormData(sampleCondoEligibility);
  setAddressInputValue("");
  setAddressOptions([]);
  setZipCodeInputValue("");
  setZipCodeOptions([]);
  setFailedFinalRate(false);
  setFetchData({});
  setRatesAsOfDate("");
  setDebouncedValue(null);
  setNoAssignedPartner("");
  setAssignedPartner("");
  setIsUnitExpanded({});
  setChipsData([]);
  setEligiblePartnerResults({});
  setPartnersResults({});
  setLoansApiResponse({});
  setRulesResponse({});
  setCriticalTests({});
  setTemplateResponseData([]);
  setSizingType("");
  setQuickLoanSizingData({});
  setDetailedLoanSizingData(intialDetailedLoanSizingState);
  setView("");
  setFieldConfig({});
  setDetailedLoanSizingErrors({});
  setBuyDownOptions([]);
  setCurrentSelectedBuyDownOption({});
  setNewDebouncedValue(null);
  setCurrentSelectedInterestRate({});
  setLatestTermSheetVersion("");
}

export function validateNumberField(input: any) {
  // Check if the input contains alphabetic characters
  const hasAlphabets = /[a-zA-Z]/.test(input);

  if (hasAlphabets) {
    return true;
  }

  // Check if input is a valid number
  if (isNaN(+input) || input.trim() === "" || +input < 0) {
    return true;
  }

  return false;
}
