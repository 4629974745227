export const LEV1 = [
    "InitialLoanAmount",
    "OrgInterestRate",
    "TotalRehabBudget",
    "financedBudget",
    "TotalOrgAndDiscountPoints",
  ];

  export const quickLoanSizingLEV1 = ["InterestOnlyFlag", "ioPeriod", "PrePaymentPenaltyTypeMonths", ""];

  export const detailedLoanSizingLEV1 = ["BorrowerRequested", "PrePaymentPenaltyTypeMonths", "PrePaymentPenaltyType", "rateType", "InterestOnlyFlag", "ioPeriod"];

