import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { RootContext } from "../../../context/RootContext";
import { LoansContext } from "../../../context/LoansContext";

import { Clients } from "../../../types/enums";

import { useLoanSizingHandler } from "../../../hooks/useLoanSizingHandler";

import { getBorrowerFields } from "../LoanFields/LoanDetails/BorrowerInformation";
import { getPropertyLocationFields } from "../LoanFields/LoanDetails/PropertyLocationFields";
import { getPropertyInformationFields } from "../LoanFields/LoanDetails/PropertyInformationFields";
import { getPropertyEconomicsFields } from "../LoanFields/LoanDetails/PropertyEconomicsFields";
import { getLoanInformationFields } from "../LoanFields/LoanDetails/LoanInformation";
import { getLoanEconomicsFields } from "../LoanFields/LoanDetails/LoanEconomics";
import { getLoanCharacterizationFields } from "../LoanFields/LoanResults/LoanCharacterization";
import { getLoanFeaturesFields } from "../LoanFields/LoanResults/LoanFeatures";
import { getUnitInformationFields } from "../LoanFields/LoanDetails/UnitInformation";

import BorrowerDetails from "./BorrowerDetails";
import LoanDetails from "./LoanDetails";
import PropertyDetails from "./PropertyDetails";
import AutoCalculatedResults from "./AutoCalculatedResults";

const unitInformation: any = [{}];
const propertyDetails = [
  {
    propertyLocation: {},
    propertyInformation: {},
    propertyEconomics: {},
    unitInformation,
  },
];

export function DetailedLoanSizing() {
  const { t } = useTranslation();
  const [client, setClient] = useState<string>("");

  const { dynamicConfig } = useContext(RootContext);
  const {
    handleChange,
    onBlur,
    addressOrPostalOnInputChange
  } = useLoanSizingHandler();

  const {
    loanDetails,
    selectedSizingType,
    zipCodeOptions,
    addressOptions,
    detailedLoanSizingData,
    setDetailedLoanSizingData,
    fieldConfig,
    setFieldConfig,
    detailedLoanSizingErrors,
    isApplicationSubmitted,
    autoCalculatedLoanResults,
    autoCalculatedPropResults,
    setCondoEligibility,
  } = useContext(LoansContext);

  const handleAddProperty = () => {
    setDetailedLoanSizingData({
      ...detailedLoanSizingData,
      propertyDetails: [
        ...detailedLoanSizingData?.propertyDetails,
        ...propertyDetails,
      ],
    });
  };

  const handleAddUnit = (propertyId: number) => {
    let tempPropertyDetails = [
      ...(detailedLoanSizingData?.propertyDetails || []),
    ];
    if (propertyId >= 0 && propertyId < tempPropertyDetails.length) {
      tempPropertyDetails = tempPropertyDetails?.map(
        (property: any, index: number) => {
          if (index === propertyId) {
            return {
              ...property,
              unitInformation: [
                ...(property?.unitInformation || []),
                ...unitInformation,
              ],
            };
          }
          return property;
        }
      );
    }
    setDetailedLoanSizingData({
      ...detailedLoanSizingData,
      propertyDetails: tempPropertyDetails,
    });
  };

  const handleDeleteProperty = (propertyId: number) => {
    let tempPropertyDetails = [
      ...(detailedLoanSizingData?.propertyDetails || []),
    ];
    if (propertyId >= 0 && propertyId < tempPropertyDetails.length) {
      tempPropertyDetails = tempPropertyDetails?.filter(
        (property: any, index: number) => index !== propertyId
      );
    }
    setDetailedLoanSizingData({
      ...detailedLoanSizingData,
      propertyDetails: tempPropertyDetails,
    });
  };

  const handleDeleteUnit = (propertyId: number, unitId: number) => {
    let tempPropertyDetails = [
      ...(detailedLoanSizingData?.propertyDetails || []),
    ];
    if (propertyId >= 0 && propertyId < tempPropertyDetails.length) {
      tempPropertyDetails = tempPropertyDetails?.map(
        (property: any, index: number) => {
          if (index === propertyId) {
            return {
              ...property,
              unitInformation: property?.unitInformation?.filter(
                (unit: any, idx: number) => idx !== unitId
              ),
            };
          }
          return property;
        }
      );
    }
    setDetailedLoanSizingData({
      ...detailedLoanSizingData,
      propertyDetails: tempPropertyDetails,
    });
  };

  // Initialize field config
  useEffect(() => {
    const client =
      dynamicConfig.ViteAppKcRealm === "bplsizer"
        ? Clients?.BPL_SIZER
        : Clients?.MMTC;

    const borrowerFields = getBorrowerFields(
            loanDetails?.productType,
            client,
            "borrowerInformation",
            selectedSizingType
    );

    setClient(client);
    setFieldConfig({
      borrowerDetails: {
        borrowerInformation: {
          fields: borrowerFields,
          header: "borrowerInformation",
        },
      },
      propertyDetails: {
        propertyLocation: {
          fields: getPropertyLocationFields(
            loanDetails?.productType,
            client,
            "propertyLocation",
            selectedSizingType
          ),
          header: "propertyLocation",
        },
        propertyInformation: {
          fields: getPropertyInformationFields(
            loanDetails?.productType,
            client,
            "propertyInformation",
            selectedSizingType
          ),
          header: "propertyInformation",
        },
        unitInformation: {
          fields: getUnitInformationFields(
            loanDetails?.productType,
            client,
            "unitInformation",
            selectedSizingType
          ),
          header: "unitInformation",
        },
        propertyEconomics: {
          fields: getPropertyEconomicsFields(
            loanDetails?.productType,
            client,
            "propertyEconomics",
            selectedSizingType
          ),
          header: "propertyEconomics",
        },
      },
      loanDetails: {
        loanInformation: {
          fields: getLoanInformationFields(
            loanDetails?.productType,
            client,
            "loanInformation",
            selectedSizingType
          ),
          header: "loanInformation",
        },
        loanEconomics: {
          fields: getLoanEconomicsFields(
            loanDetails?.productType,
            client,
            "loanEconomics",
            selectedSizingType
          ),
          header: "loanEconomics",
        },
      },
      autoCalculatedResults: {
        loanCharacterisation: {
          fields: getLoanCharacterizationFields(
            loanDetails?.productType,
            client,
            "loanCharacterisation",
            selectedSizingType
          ),
          header: "loanCharacterisation",
        },
        loanFeatures: {
          fields: getLoanFeaturesFields(
            loanDetails?.productType,
            client,
            "loanFeatures",
            selectedSizingType,
            false
          ),
          header: "loanFeatures",
        },
        propLoanFeatures: {
          fields: getLoanFeaturesFields(
            loanDetails?.productType,
            client,
            "propLoanFeatures",
            selectedSizingType,
            true
          ),
          header: "propLoanFeatures",
        },
      },
    });
  }, [
    dynamicConfig,
    loanDetails?.productType,
    selectedSizingType,
    setFieldConfig,
  ]);

  return (
    <div style={{ margin: "0px 16px" }}>
      <BorrowerDetails
        fieldConfig={fieldConfig}
        isApplicationSubmitted={isApplicationSubmitted}
        detailedLoanSizingData={detailedLoanSizingData}
        client={client}
        handleChange={handleChange}
        onBlur={onBlur}
        detailedLoanSizingErrors={detailedLoanSizingErrors}
        loanDetails={loanDetails}
      />
      <PropertyDetails
        fieldConfig={fieldConfig}
        isApplicationSubmitted={isApplicationSubmitted}
        detailedLoanSizingData={detailedLoanSizingData}
        detailedLoanSizingErrors={detailedLoanSizingErrors}
        client={client}
        handleChange={handleChange}
        onBlur={onBlur}
        loanDetails={loanDetails}
        handleAddUnit={handleAddUnit}
        handleAddProperty={handleAddProperty}
        handleDeleteUnit={handleDeleteUnit}
        handleDeleteProperty={handleDeleteProperty}
        zipCodeOptions={zipCodeOptions}
        addressOptions={addressOptions}
        addressOrPostalOnInputChange={addressOrPostalOnInputChange}
        allowEditAfterSubmit={dynamicConfig?.allowEditAfterSubmit}
      />
      <LoanDetails
        fieldConfig={fieldConfig}
        isApplicationSubmitted={isApplicationSubmitted}
        detailedLoanSizingData={detailedLoanSizingData}
        client={client}
        detailedLoanSizingErrors={detailedLoanSizingErrors}
        handleChange={handleChange}
        onBlur={onBlur}
        loanDetails={loanDetails}
        setCondoEligibility={setCondoEligibility}
      />
      <AutoCalculatedResults
        fieldConfig={fieldConfig}
        isApplicationSubmitted={isApplicationSubmitted}
        data={{ autoCalculatedLoanResults, autoCalculatedPropResults }}
        client={client}
        loanDetails={loanDetails}
        t={t}
        handleChange={handleChange}
        onBlur={onBlur}
      />
    </div>
  );
}
