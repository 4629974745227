import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import SignIn from "../pages/SignIn/SignIn";
import EvaluateLoan from "../pages/EvaluateLoans/EvaluateLoan";
import ListView from "../pages/ListView/ListView";
import LoanPage from "../pages/Loans/v1/LoanPage";
import LoansPageV2 from "../pages/Loans/v2/LoansPagev2";
import Redirect from "../pages/Redirect";

import { RootContext } from "../context/RootContext";

import { getCookie } from "../utils/Common";

import { ObjectType } from "../types/tableTypes";

import RouteGuard from "./RouteGuard";
import { Clients } from "../types/enums";

export default function CustomRoutes() {
  const { setUserPermissions, dynamicConfig } = useContext(RootContext);
  const [routesMap, setRoutesMap] = React.useState<ObjectType[]>([]);

  useEffect(() => {
    const roles = getCookie("userPermissions") || "[]";
    if (roles) {
      const userPermissions = JSON.parse(roles);
      setUserPermissions(userPermissions);
    }
    const tempRoutesMap = [
      // {
      //   path: "/",
      //   element: <SignIn />
      // },
      {
        path: "/evaluate-loan",
        element: <EvaluateLoan />
      }, {
        path: "/list-view",
        element: <ListView />
      }, {
        path: "/loan/:loanType/:propType?/:loanId",
        element: dynamicConfig.ViteAppKcRealm === Clients.BPL_SIZER ? <LoanPage /> : <LoansPageV2 />
      }, {
        path: "*",
        element: <Redirect />
      }
    ];
    setRoutesMap(tempRoutesMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicConfig]);

  return (
    <Router>
      <Routes>
        {routesMap.map((routeEle: ObjectType, idx: number) => {
          return <Route
            key={idx}
            path={routeEle.path}
            element={
              <RouteGuard>
                {routeEle.element}
              </RouteGuard>
            }
          />
        })}
      </Routes>
    </Router>
  );
}
