import { t } from "i18next";

import TrashIcon from "../../../../assets/TrashIcon.svg";
import PlusIcon from "../../../../assets/PlusIcon.svg";

import AccordionNewUI from "../../../../components/common/v2/AccordionNewUI";

import PropertyEconomics from "./PropertyEconomics/index";
import PropertyInformation from "./PropertyInformation/index";
import PropertyLocation from "./PropertyLocation/index";
import UnitInformationWrapper from "./UnitInformation/UnitInformationWrapper";

export function PropertyDetails(props: any) {
  const {
    fieldConfig,
    isApplicationSubmitted,
    detailedLoanSizingData,
    client,
    handleChange,
    onBlur,
    propertyDetails,
    detailedLoanSizingErrors,
    handleAddProperty,
    handleAddUnit,
    handleDeleteProperty,
    handleDeleteUnit,
    zipCodeOptions,
    addressOptions,
    addressOrPostalOnInputChange,
    allowEditAfterSubmit
  } = props;

  const renderPropertyDetailsUI = (propertyData: any, idx: number) => {
    return (
      <div key={idx}>
        <PropertyLocation
          fieldConfig={fieldConfig["propertyDetails"]}
          detailedLoanSizingData={propertyData}
          isApplicationSubmitted={isApplicationSubmitted}
          client={client}
          handleChange={handleChange}
          onBlur={onBlur}
          propertyDetails={propertyDetails}
          propertyId={idx}
          detailedLoanSizingErrors={detailedLoanSizingErrors}
          zipCodeOptions={zipCodeOptions}
          addressOptions={addressOptions}
          addressOrPostalOnInputChange={addressOrPostalOnInputChange}
        />
        <PropertyInformation
          fieldConfig={fieldConfig["propertyDetails"]}
          detailedLoanSizingData={propertyData}
          isApplicationSubmitted={isApplicationSubmitted}
          client={client}
          handleChange={handleChange}
          onBlur={onBlur}
          propertyDetails={propertyDetails}
          propertyId={idx}
          detailedLoanSizingErrors={detailedLoanSizingErrors}
        />
        <div>
          <h4>Unit Information</h4>
          {propertyData?.unitInformation?.map(
            (unitData: any, unitId: number) => {
              return (
                <div key={unitId}>
                  <UnitInformationWrapper
                    fieldConfig={fieldConfig["propertyDetails"]}
                    detailedLoanSizingData={unitData}
                    isApplicationSubmitted={isApplicationSubmitted && !allowEditAfterSubmit}
                    client={client}
                    handleChange={handleChange}
                    onBlur={onBlur}
                    propertyDetails={propertyDetails}
                    propertyId={idx}
                    detailedLoanSizingErrors={detailedLoanSizingErrors}
                    unitId={unitId}
                    config={{
                      buttons: [
                        { label: "delete", icon: TrashIcon, action: "delete" },
                        { label: "addMore", icon: PlusIcon, action: "add" },
                      ],
                    }}
                    handleAddUnit={handleAddUnit}
                    handleDeleteUnit={handleDeleteUnit}
                    unitsLength={propertyData?.unitInformation?.length}
                  />
                </div>
              );
            }
          )}
        </div>
        <PropertyEconomics
          fieldConfig={fieldConfig["propertyDetails"]}
          detailedLoanSizingData={propertyData}
          isApplicationSubmitted={isApplicationSubmitted}
          client={client}
          handleChange={handleChange}
          onBlur={onBlur}
          propertyDetails={propertyDetails}
          propertyId={idx}
          detailedLoanSizingErrors={detailedLoanSizingErrors}
        />
      </div>
    );
  };

  return (
    <div>
      {detailedLoanSizingData?.propertyDetails?.map(
        (propertyData: any, idx: number) => {
          return (
            <div key={idx}>
              <AccordionNewUI
                label={`${t("property")} ${idx + 1}`}
                isApplicationSubmitted={isApplicationSubmitted && !allowEditAfterSubmit}
                data={propertyData}
                renderUI={() => renderPropertyDetailsUI(propertyData, idx)}
                handleAddProperty={handleAddProperty}
                handleDeleteProperty={handleDeleteProperty}
                activePropertyId={idx}
                accordionConfig={{
                  showActionButtons: true,
                  buttons: [
                    {
                      label: "deleteProperty",
                      icon: TrashIcon,
                      action: "delete",
                    },
                    { label: "addProperty", icon: PlusIcon, action: "add" },
                  ],
                }}
                propertiesLength={detailedLoanSizingData?.propertyDetails?.length}
              />
            </div>
          );
        }
      )}
    </div>
  );
}
