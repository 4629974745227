import axios from "axios";
import { useEffect } from "react";

export const useClientConfig = (setDynamicConfig: any) => {
  const getClientConfig = async (clientName: string) => {
    try {
      const envs = ["dev", "stage", "qa", "perf", "uat"];
      const clientUrlParts: string[] = clientName.split(".");
      const isLocalHost: boolean = clientName.includes("localhost");

      const apiBaseUrl = isLocalHost
        ? "-dev"
        : envs.includes(clientUrlParts[0])
        ? `-${clientUrlParts[0]}`
        : "";

      const data = await axios.get(
        `https://api${apiBaseUrl}.toorakcapital.info/online-sizer/config/fetch/${clientName}`
      );
      if (!data?.data) return;
      setDynamicConfig({
        ...data?.data,
        supportEmail:
          data?.data.supportEmail ?? "tooraksupport@triconinfotech.com",
      });
      localStorage.setItem("client_config", JSON.stringify(data?.data));
    } catch (error) {
      console.error("Error fetching client config", error);
    }
  };

  useEffect(() => {
    const enteredUrl = window.location.href;
    const parsedUrl = new URL(enteredUrl);
    getClientConfig(parsedUrl.hostname.replace("www.",""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
