import getVersionedFields from "..";
import { isFalsyButNotZero, isLetters } from "../../../../utils/Validations";
import { predominantPropType, propertyTypeDropDownValues } from "../../v1/LoanFieldsData";

const propertyType = {
  id: "propertyType",
  label: "propertyType",
  required: true,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: true,
  data: [...predominantPropType],
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select the type of property (e.g., single-family, 2-4 units, condo).",
};

const asIsValue = {
  id: "asIsValue",
  label: "asIsValue",
  required: true,
  type: (loanType: string, dataView: string) => "string",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  isCurrency: true,
  fieldInfo: "Enter the current appraised or market value of the property in its present condition.",
};

const propertySqftFootage = {
  id: "propertySqftFootage",
  label: "propertySqftFootage",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !value.trim().length || value.includes(".")
        ? "validValue"
        : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Provide the total square footage of the property.",
};

const OriginalDateOfAcquisition = {
  id: "originalDateOfAcquisition",
  label: "originalDateOfAcquisition",
  required: true,
  type: (loanType: string, dataView: string) => "date",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Enter the date the property was first acquired by the current owner.",
};

const recentPropValuationDate = {
  id: "mostRecentPropertyValuationDate",
  label: "mostRecentPropertyValuationDate",
  required: true,
  type: (loanType: string, dataView: string) => "date",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Provide the date of the most recent valuation or appraisal of the property.",
};

const propertyPurchasedWithinLast2Years: any = {
  id: "propertyPurchasedWithinLast2Years",
  label: "propertyPurchasedWithinLast2Years",
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  data: [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ],
  required: true,
  minimumDataView: true,
  columnSplit: 6,
  isVisible:() => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "",
};

const noOfUnits = {
  id: "numberOfUnits",
  label: "numberOfUnits",
  required: true,
  type: (loanType: string, dataView: string) => "number",
  getFieldErrors: (value: any) =>
    isNaN(+value) || isLetters(value)
      ? "enterValidNumber"
      : !Number.isInteger(+value)
        ? "enterAnInteger"
        : !value.trim().length || value.includes(".")
          ? "validValue"
          : value < 0 ? "cannotBeNegative" : "",
  minimumDataView: true,
  columnSplit: 6,
  isVisible:(data: any) => ["2-4 Unit", "Townhome"].includes(data?.propertyType),
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  popUpMessage: "24UnitMessage",
  fieldInfo: "Enter the total number of individual units within the property.",
};

const PropertyWithDebt = {
  id: "propertyWithDebt",
  label: "propertyWithDebt",
  required: false,
  type: (loanType: string, dataView: string) => "dropDown",
  getFieldErrors: (value: any) =>
    !isFalsyButNotZero(value) ? "validValue" : "",
  minimumDataView: false,
  data: [
    { label: "yes", value: "Y" },
    { label: "no", value: "N" },
  ],
  columnSplit: 6,
  isVisible: () => true,
  isAutoCalculated: (loanType: string, dataView?: string) => false,
  fieldInfo: "Select whether the property was originally purchased using a loan or debt financing.",
};

export const propertyInformationFields: any = {
  propertyType,
  asIsValue,
  propertySqftFootage,
  OriginalDateOfAcquisition,
  recentPropValuationDate,
  PropertyWithDebt,
  noOfUnits
}

export const getPropertyInformationFields = (
  productType: string,
  client: string,
  section: string,
  sizingType?: string
) => {
  const finalConfigToBeReturned: any = [];
  const vFields: any = getVersionedFields(productType);
  let configToBeReturned: any = vFields?.[client]?.["inputFields"]?.[section];

  if (sizingType) {
    configToBeReturned = configToBeReturned?.[sizingType];
  };

  configToBeReturned?.map((field: string) => {
    if (propertyInformationFields[field]) {
      finalConfigToBeReturned.push(propertyInformationFields[field]);
    }
  });

  return finalConfigToBeReturned;
};