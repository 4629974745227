import { useState } from "react";

import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { WaiverWrapper } from "./WaiverWrapper";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "2rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "&:first-of-type": {
    marginTop: "0 !important",
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: "rotate(180deg)",
    },
  [`& .${accordionSummaryClasses.content}`]: {
    // marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  padding: "8px 0px",
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function WaiverHistory(props: any) {
  const {
    waiverHistory,
    t,
    downloadFile,
    isLoading,
  } = props;

  const [expanded, setExpanded] = useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        className="mt-0"
      >
        <Typography component="span">View Previous</Typography>
      </AccordionSummary>
      {waiverHistory?.length > 0 ? (
        <AccordionDetails>
          {waiverHistory?.map((waiver: any, index: number) => {
            return (
              <WaiverWrapper waiver={waiver} t={t} downloadFile={downloadFile} isLoading={isLoading}/>
            );
          })}
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
}
