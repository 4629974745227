import React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { ObjectType } from "../../../types/tableTypes";
import { t } from "i18next";

const styles = {
  datePicker: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
};

export default function DatePickerComponent({
  errorMsg,
  handleChange,
  field,
  sectionName,
  value,
  currentObjFieldError,
  isApplicationSubmitted,
  propertyId,
  unitId,
  loanDetails,
  dynamicConfig,
}: {
  errorMsg: any;
  handleChange: any;
  field: any;
  sectionName: string;
  value: any;
  currentObjFieldError: any;
  isApplicationSubmitted: boolean;
  propertyId: any;
  unitId: any;
  loanDetails: any;
  dynamicConfig: any;
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={(styles.datePicker, { width: "100%" })}
        value={value ? dayjs(value) : null}
        onChange={(newValue) =>
          handleChange(newValue, "date", field, sectionName, propertyId, unitId)
        }
        slotProps={{
          textField: {
            size: "small",
            error: Boolean(errorMsg?.length && currentObjFieldError),
            helperText: currentObjFieldError ? t(errorMsg) : "",
          },
        }}
        format="MM/DD/YYYY"
        disabled={
          dynamicConfig?.allowEditAfterSubmit &&
          !(
            field?.isAutoCalculated &&
            field?.isAutoCalculated({
              sizingType: loanDetails?.sizingType,
            })
          )
            ? false
            : isApplicationSubmitted ||
              field?.isAutoCalculated({ sizingType: loanDetails?.sizingType })
        }
      />
    </LocalizationProvider>
  );
}
