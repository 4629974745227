import GetFieldUI from "../../GetFieldUI";

export default function UnitInformation(props: any) {
  const {
    fieldConfig,
    detailedLoanSizingData,
    isApplicationSubmitted,
    client,
    handleChange,
    onBlur,
    propertyDetails,
    detailedLoanSizingErrors,
    propertyId,
    unitId,
  } = props;

  return (
    <div key={unitId} style={{ width: "100%" }}>
      <GetFieldUI
        formFields={fieldConfig?.["unitInformation"]?.fields}
        currentObjValue={detailedLoanSizingData ?? {}}
        handleChange={handleChange}
        onBlur={onBlur}
        propertyDetails={propertyDetails}
        sectionName={fieldConfig?.["unitInformation"]?.header}
        zipCodeSuggestions={[]}
        currentObjFieldErrors={
          detailedLoanSizingErrors?.propertyDetails?.[propertyId]?.[
            fieldConfig?.["unitInformation"]?.header
          ]?.[unitId] ?? {}
        }
        client={client}
        propertyId={propertyId}
        unitId={unitId}
        isApplicationSubmitted={isApplicationSubmitted}
      />
    </div>
  );
}
