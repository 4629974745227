import GetFieldUI from "../../GetFieldUI";

export default function LoanInformation(props: any) {
  const {
    fieldConfig,
    isApplicationSubmitted,
    detailedLoanSizingData,
    detailedLoanSizingErrors,
    client,
    handleChange,
    onBlur,
    loanDetails,
    setCondoEligibility
  } = props;

  return (
    <div>
      <h4>Loan Information</h4>
      <GetFieldUI
        formFields={fieldConfig?.["loanInformation"]?.fields}
        currentObjValue={
          detailedLoanSizingData?.[
            fieldConfig?.["loanInformation"]?.header
          ] ?? {}
        }
        handleChange={handleChange}
        onBlur={onBlur}
        loanDetails={loanDetails}
        sectionName={fieldConfig?.["loanInformation"]?.header}
        zipCodeSuggestions={[]}
        currentObjFieldErrors={
          detailedLoanSizingErrors?.[
            fieldConfig?.["loanInformation"]?.header
          ] ?? {}
        }
        client={client}
        isApplicationSubmitted={isApplicationSubmitted}
        setCondoEligibility={setCondoEligibility}
      />
    </div>
  );
}
