import { Box, Modal } from '@mui/material';
import KDMHeader from './KDMHeader';
import KDMSection from './KDMSection';
import { getKeyDealMetricsFieldMap } from './KDMField';
import { KDMExport } from './KDMExport';


type KDMBodyPropTypes = {
    header: string
    handleShowKDMBody: (status: boolean) => void
    icons?: any
    data?: any
    config?: any
}

const KDMModal = (props: KDMBodyPropTypes) => {
    const {
        header,
        handleShowKDMBody,
        icons,
        data,
        config,
    } = props

    const KDMBody =  ({isPrint}: any) => {
      return (
        <Box
                sx={{
                  height: `calc(100vh - 25%)`,
                  overflowY: "auto",
                  overflowX: "hidden",
                  padding:"20px"
                }}
              >
                {getKeyDealMetricsFieldMap(data?.loanType)?.map((ele: any)=> {
                  const { sectionLabel, sectionId, fields } = ele;
                      if (isPrint && (sectionLabel === "Property Information" || sectionLabel === "Adjusted Ratios")) {
                        return (
                          <div key={sectionId}>
                            <div style={{ pageBreakBefore: "always" }} /> {/* Add page break */}
                            <KDMSection
                              sectionData={ele}
                              loanData={data}
                              config={config}
                              icons={icons}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div key={sectionId}>
                            <KDMSection
                              sectionData={ele}
                              loanData={data}
                              config={config}
                              icons={icons}
                            />
                          </div>
                        );
                      }
                              })}
              </Box>
      )
    }

  return (
    <div>
      <Modal
        open={true}
        onClose={() => handleShowKDMBody(false)}
        aria-labelledby='term-sheet-modal'
        data-testid='term-sheet-modal'
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "90%",
            height: "95%",
            bgcolor: "background.paper",
            boxShadow: 24,
            overflow: "auto",
            outline: "none",
            background: "white",
            borderRadius: "8px"
          }}
        >
            <KDMHeader 
              label={header} 
              handleShowKDMBody={handleShowKDMBody} 
              icon={icons.CloseIcon} 
              exportIcon={icons.ExportIcon} 
              reactToPrintFn={config?.reactToPrintFn}
            />
            <KDMBody />
            
            <div style={{display: "none"}}>
              <KDMExport 
                header={header} 
                KDMBody={KDMBody} 
                ref={config?.componentRef} 
                logo={icons.ToorakLogo}
              />
            </div>

        </Box>
        </Modal>
    </div>
  )
}

export default KDMModal
