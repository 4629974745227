import { Grid } from "@mui/material";

import BuyDownOptionsTable from "./BuyDownOptionsTable";

import "./BuyDownOptionsWrapper.css";

export default function BuyDownOptions(props: any) {
  return (
    <Grid container className="buy-down-container">
      <Grid item xs={12} m={2} style={{ margin: "12px" }}>
        <span className="buy-down-header">{props.header}</span>
      </Grid>
      <Grid item xs={12} m={2} style={{ margin: "12px 12px 0px 12px" }}>
        <BuyDownOptionsTable
          tableHeader={props?.tableHeader}
          tableBody={props?.tableBody}
          handleInterestChange={props?.handleInterestChange}
          currentSelectedInterestRate={props?.currentSelectedInterestRate}
        />
      </Grid>
    </Grid>
  );
}
