import GetFieldUI from "../GetFieldUI";

import AccordionNewUI from "../../../../components/common/v2/AccordionNewUI";
import CustomChip from "../../../../components/common/CustomChip";

export default function AutoCalculatedResults(props: any) {
  const { fieldConfig, isApplicationSubmitted, data, client, t, handleChange, onBlur } = props;

  const { autoCalculatedLoanResults, autoCalculatedPropResults } = data;

  function renderUI() {
    return (
      <div>
        {Object.values(autoCalculatedLoanResults?.loanFeatures ?? {})
          ?.length ? (
          <div>
            <div className="div-start">
              <h4>Loan Features</h4>&ensp;
              <CustomChip
                size="small"
                label={t("autoCalculated")}
                variant="outlined"
                className="autocomplete-chipData"
              />
            </div>
            <GetFieldUI
              formFields={
                fieldConfig?.["autoCalculatedResults"]?.["loanFeatures"]?.fields
              }
              currentObjValue={autoCalculatedLoanResults?.loanFeatures ?? {}}
              sectionName={
                fieldConfig?.["autoCalculatedResults"]?.["loanFeatures"]?.header
              }
              client={client}
              isApplicationSubmitted={isApplicationSubmitted}
              handleChange={handleChange}
              onBlur={onBlur}
            />
          </div>
        ) : null}
        {Object.values(autoCalculatedLoanResults?.loanCharacterisation ?? {})
          ?.length ? (
          <div>
            <div className="div-start">
              <h4>Loan Characterization</h4>&ensp;
              <CustomChip
                size="small"
                label={t("autoCalculated")}
                variant="outlined"
                className="autocomplete-chipData"
              />
            </div>
            <GetFieldUI
              formFields={
                fieldConfig?.["autoCalculatedResults"]?.["loanCharacterisation"]
                  ?.fields
              }
              currentObjValue={
                autoCalculatedLoanResults?.loanCharacterisation ?? {}
              }
              sectionName={
                fieldConfig?.["autoCalculatedResults"]?.["loanCharacterisation"]
                  ?.header
              }
              client={client}
              isApplicationSubmitted={isApplicationSubmitted}
              handleChange={handleChange}
              onBlur={onBlur}
            />
          </div>
        ) : null}
        {autoCalculatedPropResults?.length
          ? autoCalculatedPropResults?.map((propResult: any, index: number) => {
              return (
                <div key={index}>
                  <div className="div-start">
                    <h4> Loan Features - Property #{index + 1}</h4>&ensp;
                    <CustomChip
                      size="small"
                      label={t("autoCalculated")}
                      variant="outlined"
                      className="autocomplete-chipData"
                    />
                  </div>

                  <GetFieldUI
                    formFields={
                      fieldConfig?.["autoCalculatedResults"]?.[
                        "propLoanFeatures"
                      ]?.fields
                    }
                    currentObjValue={propResult?.loanFeatures ?? {}}
                    sectionName={
                      fieldConfig?.["autoCalculatedResults"]?.[
                        "propLoanFeatures"
                      ]?.header
                    }
                    client={client}
                    isApplicationSubmitted={isApplicationSubmitted}
                    handleChange={handleChange}
                    onBlur={onBlur}
                  />
                </div>
              );
            })
          : null}
      </div>
    );
  }

  return (
    <AccordionNewUI
      label={"autoCalculatedResults"}
      isApplicationSubmitted={isApplicationSubmitted}
      renderUI={renderUI}
    />
  );
}
