import { useContext } from "react";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

import DropDown from "../../../components/common/v2/DropDown";
import TextFieldComponent from "../../../components/common/v2/TextField";
import AutoCompleteComponent from "../../../components/common/v2/AutoCompleteComponent";
import ButtonGroupComponent from "../../../components/common/v2/ButtonGroup";
import { SliderComponent } from "../../../components/common/v2/SliderComponent";
import DatePickerComponent from "../../../components/common/v2/DatePickerComponent";

import LabelInfo from "../../../assets/LabelInfoIcon.svg";
import ShareIcon from "../../../assets/ShareIcon.svg";

import { Clients } from "../../../types/enums";
import { LocationSuggestion } from "../../../types/fieldTypes";
import { RootContext } from "../../../context/RootContext";
import { prePayTypeToMonthsMap } from "../../../utils/v2/common";

import "./GetFieldUI.css";

const getAddressOptionLabel = (option: LocationSuggestion) => {
  let value;
  if (typeof option === "string") {
    value = option;
  } else if (option.street_line) {
    value = `${option.street_line} ${option.city} ${option.state} ${option.zipcode}`;
  } else {
    value = "";
  }
  return value;
};

const getPostalOptionLabel = (option: any) => {
  let value;
  if (typeof option === "string") {
    value = option;
  } else if (option.city) {
    value = `${option.city} ${option.state_abbreviation} ${option.zipCode}`;
  } else {
    value = "";
  }
  return value;
};

export default function GetFieldUI(props: any) {
  const {
    formFields,
    currentObjValue,
    handleChange,
    onBlur,
    loanDetails,
    sectionName,
    zipCodeSuggestions,
    addressOptions,
    // getAddressOptionLabel,
    // addressOnChange,
    // addressInputValue,,
    errorMsg,
    isApplicationSubmitted,
    label,
    field,
    productType,
    isExpandedView,
    currentObjFieldErrors,
    client,
    propertyId,
    unitId,
    addressOrPostalOnInputChange,
    setCondoEligibility,
  } = props;
  const { t } = useTranslation();
  const { dynamicConfig } = useContext(RootContext);

  const getTextLabel = (field: any) => {
    const { fieldLabel, label } = field;
    return (
      <div className="label-container">
        <div className="label-wrapper">
          <Tooltip
            title={t(fieldLabel || label)}
            arrow
            followCursor
            slots={{
              transition: Fade,
            }}
            slotProps={{
              transition: { timeout: 600 },
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <label className="label">{t(fieldLabel || label)}</label>
          </Tooltip>
          <div>
            {field?.required &&
            !field?.isAutoCalculated() &&
            field.id !== "inPlaceLeaseRentMonthly" ? (
              <sup className="label-required">*</sup>
            ) : null}
          </div>
        </div>
        {field?.fieldInfo ? (
          <Tooltip
            title={t(field?.fieldInfo ?? "")}
            slots={{
              transition: Fade,
            }}
            slotProps={{
              transition: { timeout: 600 },
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
            arrow
            placement="right-start"
          >
            <img src={LabelInfo} alt="info" />
          </Tooltip>
        ) : null}
      </div>
    );
  };

  return (
    <Grid container spacing={4}>
      {formFields
        ?.filter(
          (field: any) =>
            field?.isVisible?.(currentObjValue) &&
            (field?.isAutoCalculated() ? currentObjValue[field?.id] : true)
        )
        ?.map((field: any, index: number) => {
          const { label, type, data, id, fieldLabel } = field;
          switch (type(loanDetails?.productType, loanDetails?.sizingType)) {
            case "dropDown":
              return (
                <Grid item xs={3} key={index} style={{ marginBottom: "24px" }}>
                  <div>{getTextLabel(field)}</div>
                  <div className="mt-8">
                    <DropDown
                      label={label}
                      data={data}
                      value={currentObjValue[id]}
                      errorMsg={field?.getFieldErrors(currentObjValue[id])}
                      handleChange={handleChange}
                      onBlur={onBlur}
                      field={field}
                      sectionName={sectionName}
                      currentObjFieldError={currentObjFieldErrors?.[id] ?? null}
                      propertyId={propertyId}
                      unitId={unitId}
                      loanDetails={loanDetails}
                      isApplicationSubmitted={isApplicationSubmitted}
                      dynamicConfig={dynamicConfig}
                      currentObjValue={currentObjValue}
                    />
                  </div>
                  {field.checkEligibility ? (
                    <div
                      className="div-start align-center cursor-ptr"
                      onClick={() => setCondoEligibility(true)}
                      style={{ textDecoration: "underline" }}
                    >
                      <a style={{ fontSize: "10px" }}>{t(field.label)}</a>
                      &ensp;
                      <img src={ShareIcon} alt="share-icon.svg" />
                    </div>
                  ) : null}
                </Grid>
              );
            case "number":
            case "string":
              return (
                <Grid item xs={3} key={index} style={{ marginBottom: "24px" }}>
                  <div>
                    <div>{getTextLabel(field)}</div>
                  </div>
                  <div className="mt-8">
                    <TextFieldComponent
                      type={type(
                        loanDetails?.productType,
                        loanDetails?.sizingType
                      )}
                      field={field}
                      value={currentObjValue?.[id] ?? ""}
                      onChange={handleChange}
                      onBlur={onBlur}
                      isCurrency={field?.isCurrency}
                      isPercentage={field.isPercentage}
                      loanDetails={loanDetails}
                      isApplicationSubmitted={isApplicationSubmitted}
                      sectionName={sectionName}
                      errorMsg={field?.getFieldErrors(currentObjValue[id])}
                      currentObjFieldError={currentObjFieldErrors?.[id] ?? null}
                      propertyId={propertyId}
                      unitId={unitId}
                      dynamicConfig={dynamicConfig}
                    />
                  </div>
                </Grid>
              );
            case "autoComplete":
              return (
                <Grid item xs={3} key={index} style={{ marginBottom: "24px" }}>
                  <div>
                    <div>{getTextLabel(field)}</div>
                  </div>
                  <div className="mt-8">
                    <AutoCompleteComponent
                      addressOptions={addressOptions}
                      zipCodeOptions={zipCodeSuggestions}
                      selectedAddressValue={currentObjValue[id] ?? ""}
                      selectedZipCodeValue={currentObjValue[id] ?? ""}
                      getAddressOptionLabel={getAddressOptionLabel}
                      getPostalOptionLabel={getPostalOptionLabel}
                      addressOnChange={handleChange}
                      postalOnChange={handleChange}
                      addressInputValue={currentObjValue[id] ?? ""}
                      zipCodeInputValue={currentObjValue[id] ?? ""}
                      addressOrPostalOnInputChange={
                        addressOrPostalOnInputChange
                      }
                      isApplicationSubmitted={isApplicationSubmitted}
                      onBlur={onBlur}
                      label={label}
                      field={field}
                      isExpandedView={isExpandedView}
                      sectionName={sectionName}
                      propertyId={propertyId}
                      unitId={unitId}
                      loanDetails={loanDetails}
                      dynamicConfig={dynamicConfig}
                      currentObjFieldError={currentObjFieldErrors?.[id] ?? null}
                      errorMsg={field?.getFieldErrors(currentObjValue[id])}
                    />
                  </div>
                </Grid>
              );
            case "buttonGroup":
              return (
                <Grid item xs={3} key={index} style={{ marginBottom: "24px" }}>
                  <div>
                    <div>{getTextLabel(field)}</div>
                  </div>
                  <div className="mt-8">
                    <ButtonGroupComponent
                      buttons={field.data}
                      selectedButton={currentObjValue?.[id] ?? ""}
                      handleChange={handleChange}
                      className={
                        Clients.MMTC === client
                          ? "button-mmtc-grp-style"
                          : "button-grp-style"
                      }
                      buttonSize="large"
                      field={field}
                      errorMsg={
                        field?.getFieldErrors(currentObjValue[id]) ?? ""
                      }
                      isApplicationSubmitted={isApplicationSubmitted}
                      isExpandedView={false}
                      sectionName={sectionName}
                      currentObjFieldError={currentObjFieldErrors?.[id] ?? null}
                      client={client}
                      propertyId={propertyId}
                      unitId={unitId}
                      loanDetails={loanDetails}
                      dynamicConfig={dynamicConfig}
                    />
                  </div>
                </Grid>
              );
            case "slider":
              return (
                <Grid item xs={3} key={index} style={{ marginBottom: "24px" }}>
                  <div>
                    <div>{getTextLabel(field)}</div>
                  </div>
                  <div className="mt-8">
                    <SliderComponent
                      data={data}
                      field={field}
                      value={currentObjValue?.[id] ?? ""}
                      onChange={handleChange}
                      onBlur={onBlur}
                      loanDetails={loanDetails}
                      isApplicationSubmitted={isApplicationSubmitted}
                      sectionName={sectionName}
                      errorMsg={field?.getFieldErrors(currentObjValue[id])}
                      currentObjFieldError={currentObjFieldErrors?.[id] ?? null}
                      propertyId={propertyId}
                      unitId={unitId}
                      dynamicConfig={dynamicConfig}
                      mapping={prePayTypeToMonthsMap}
                      currentObjValue={currentObjValue}
                    />
                  </div>
                </Grid>
              );
            case "date":
              return (
                <Grid item xs={3} key={index} style={{ marginBottom: "24px" }}>
                  <div>
                    <div>{getTextLabel(field)}</div>
                  </div>
                  <div className="mt-8">
                    <DatePickerComponent
                      errorMsg={field?.getFieldErrors(currentObjValue[id])}
                      handleChange={handleChange}
                      field={field}
                      sectionName={sectionName}
                      value={currentObjValue?.[id] ?? ""}
                      currentObjFieldError={currentObjFieldErrors?.[id] ?? null}
                      isApplicationSubmitted={false}
                      propertyId={propertyId}
                      unitId={unitId}
                      loanDetails={loanDetails}
                      dynamicConfig={dynamicConfig}
                    />
                  </div>
                </Grid>
              );
          }
        })}
    </Grid>
  );
}
