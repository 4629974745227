import { detailedLoanBIV1, quickLoanBIV1 } from "./borrowerInformationVersions";
import { detailedLoanLCV1 } from "./loanCharacterizationVersions";
import { detailedLoanSizingLEV1, quickLoanSizingLEV1 } from "./loanEconomicsVersions";
import { detailedLFV1, detailedPropPLFV1 } from "./loanFeaturesVersions";
import { detailedLoanSizingLIV1, quickLoanSizingLIV1 } from "./loanInformationVersions";
import { quickLoanLOV1 } from "./loanOutputs";
import { detailedLoanPEV1, qucikLoanPEV1 } from "./propertyEconomicsVersions";
import { detailedLoanPIV1, qucikLoanPIV1 } from "./propertyInformationVersions";
import { detailedLoanPLV1, qucikLoanPLV1 } from "./propertyLocationVersions";
import { detailedLoanUIV1 } from "./unitInformationVersions";

/*

  mmtc: {
    inputFields: {
      multifamily: {
        borrowerInformation: [
          "foreignNational",
          "creditScore",
          "borrowerType",
          "inState",
        ],
        loanInformation: [],
        loanEconomics: [],
      },
      groundUp: {
        borrowerInformation: [],
        loanInformation: [],
        loanEconomics: [],
      },
      groundUpMF: { borrowerInformation: [] },
      rehab: {
        borrowerInformation: [],
        loanInformation: [],
        loanEconomics: [],
      },
    },
    outputFields: {},
  },*/
const dscrV1 = {
  id: "v1-dscr-09-12-2024",
  // bplsizer: {
  //   inputFields: {
  //     rehab: {
  //       borrowerInformation: {
  //         minFields: [
  //           "foreignNational",
  //           "creditScore",
  //           "borrowerType",
  //           "inState",
  //         ],
  //         maxFields: [
  //           "foreignNational",
  //           "creditScore",
  //           "borrowerType",
  //           "inState",
  //         ],
  //       },
  //       loanInformation: {
  //         minFields: [
  //           "foreignNational",
  //           "creditScore",
  //           "borrowerType",
  //           "inState",
  //         ],
  //         maxFields: [
  //           "foreignNational",
  //           "creditScore",
  //           "borrowerType",
  //           "inState",
  //         ],
  //       },
  //     },
  //   },
  //   outputFields: {},
  // },
  mmtc: {
    inputFields: {
      borrowerInformation: {
        quickLoanSizing: quickLoanBIV1,
        detailedLoanSizing: detailedLoanBIV1,
      },
      loanInformation: {
        quickLoanSizing: quickLoanSizingLIV1,
        detailedLoanSizing: detailedLoanSizingLIV1, 
      },
      loanEconomics: {
        quickLoanSizing: quickLoanSizingLEV1,
        detailedLoanSizing: detailedLoanSizingLEV1, 
      },
      propertyLocation: {
        quickLoanSizing: qucikLoanPLV1,
        detailedLoanSizing: detailedLoanPLV1, 
      },
      propertyInformation: {
        quickLoanSizing: qucikLoanPIV1,
        detailedLoanSizing: detailedLoanPIV1, 
      },
      unitInformation: {
        quickLoanSizing: [],
        detailedLoanSizing: detailedLoanUIV1
      },
      propertyEconomics: {
        quickLoanSizing: qucikLoanPEV1,
        detailedLoanSizing: detailedLoanPEV1, 
      },
      loanOutputs: {
        quickLoanSizing: quickLoanLOV1,
        detailedLoanSizing:[]
      },
      loanFeatures: {
        quickLoanSizing: [],
        detailedLoanSizing: detailedLFV1
      },
      loanCharacterisation: {
        quickLoanSizing: [],
        detailedLoanSizing: detailedLoanLCV1
      },
      propLoanFeatures: {
        quickLoanSizing: [],
        detailedLoanSizing: detailedPropPLFV1
      }
    },
    outputFields: {
      tabs: []
    },
  },
};

export default dscrV1;
