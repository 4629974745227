export const BIV1 = [
  "propertiesCompletedWithinLast36Months",
  "foreignNational",
  "ImmigrationStatus",
  "originalCreditScore",
  "InState",
];

export const quickLoanBIV1 = ["citizenshipStatus", "creditScoreActual"];

export const detailedLoanBIV1 = [
  "borrowerType",
  "creditScoreActual",
  "citizenshipStatus",
  "FirstTimeHomeBuyer"
];
