import React from "react";
import { useTranslation } from "react-i18next";

import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Clients } from "../../../types/enums";

const styles: any = {
  [Clients.MMTC]: {
    default: {
      border: "1px solid #DBE2FF !important;",
      backgroundColor: "#f8f9fd !important;",
      color: "black !important;",
      fontSize: "14px !important;",
      width: "100% !important;",
    },
    active: {
      backgroundColor: "#40b4b4 !important;",
      border: "1px solid #40b4b4 !important;",
      color: "white !important;",
      fontSize: "14px !important;",
      width: "100% !important;",
    },
  },
};
export default function ButtonGroupComponent({
  buttons,
  selectedButton,
  handleChange,
  className,
  buttonSize,
  field,
  errorMsg,
  isApplicationSubmitted,
  isExpandedView,
  sectionName,
  currentObjFieldError,
  client,
  propertyId,
  unitId,
  loanDetails,
  dynamicConfig,
}: {
  buttons: any;
  selectedButton: string;
  handleChange: any;
  className: string;
  buttonSize: string;
  field: any;
  errorMsg: string;
  isApplicationSubmitted: boolean;
  isExpandedView: boolean;
  sectionName: string;
  currentObjFieldError: any;
  client: string;
  propertyId: any;
  unitId: any;
  loanDetails: any;
  dynamicConfig: any;
}) {
  const { t } = useTranslation();

  let selectedBtn = selectedButton;

  if (field?.label === "decliningMarkets" && selectedButton === undefined) {
    selectedBtn = "No";
  }

  return (
    <FormControl variant="outlined" sx={{ width: "100%" }}>
      <ButtonGroup size="large">
        {buttons?.map((btn: any, idx: number) => {
          return (
            <Button
              key={idx}
              size="medium"
              style={{ width: "100%" }}
              sx={
                btn.value === selectedBtn
                  ? styles?.[client]?.active
                  : styles?.[client]?.default
              }
              color={currentObjFieldError && !!errorMsg ? "error" : "primary"}
              variant={btn.label === selectedBtn ? "contained" : "outlined"}
              disabled={
                dynamicConfig?.allowEditAfterSubmit &&
                !(
                  field?.isAutoCalculated &&
                  field?.isAutoCalculated({
                    sizingType: loanDetails?.sizingType,
                  })
                )
                  ? false
                  : isApplicationSubmitted ||
                    field?.isAutoCalculated({
                      sizingType: loanDetails?.sizingType,
                    })
              }
              onClick={() =>
                handleChange(
                  btn.value,
                  "buttonGroup",
                  field,
                  sectionName,
                  propertyId,
                  unitId
                )
              }
              data-testid={btn.label}
            >
              {t(btn.label)}
            </Button>
          );
        })}
      </ButtonGroup>
      {currentObjFieldError ? (
        <FormHelperText className="break-word">{t(errorMsg)}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
